<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round" />
        <path
            d="M14.0076 14.7362L8.29362 16.9339C7.52541 17.2309 6.76909 16.4746 7.06608 15.7064L9.26377 9.99238C9.39444 9.65579 9.65579 9.39444 9.99238 9.26377L15.7064 7.06608C16.4746 6.76909 17.2309 7.52541 16.9339 8.29362L14.7362 14.0076C14.6095 14.3442 14.3442 14.6056 14.0076 14.7362ZM13.2671 12C13.2671 11.6639 13.1336 11.3416 12.896 11.104C12.6584 10.8664 12.3361 10.7329 12 10.7329C11.6639 10.7329 11.3416 10.8664 11.104 11.104C10.8664 11.3416 10.7329 11.6639 10.7329 12C10.7329 12.3361 10.8664 12.6584 11.104 12.896C11.3416 13.1336 11.6639 13.2671 12 13.2671C12.3361 13.2671 12.6584 13.1336 12.896 12.896C13.1336 12.6584 13.2671 12.3361 13.2671 12Z"
            fill="currentColor" />
    </svg>
</template>
