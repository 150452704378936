<script setup lang="ts">
import UserCard from '@/Components/User/UserCard.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import UserSkeletonGrid from '@/Components/User/UserSkeletonGrid.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import { computed, type PropType } from 'vue'
import type { User } from '@/Types/Models/User'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { useHasPagination } from '@/Composables/useHasPagination.ts'

const props = defineProps({
    number: {
        type: Number,
        default: 3
    },
    contain: {
        type: Boolean,
        default: false
    },
    horizontal: {
        type: Boolean,
        default: false
    },
    tagWidth: {
        type: String,
        default: 'w-full md:w-1/2 lg:w-1/3'
    }
})

const containerClasses = computed(() => {
    return {
        'flex-col -mx-2.5': props.horizontal,
        'flex-wrap -mx-2.5': true
    }
})

const userClasses = computed(() => {
    return {
        'p-2.5': true,
        [props.tagWidth]: !props.horizontal
    }
})
</script>

<template>
    <section>
        <div
            :class="[contain ? 'container' : '']">
            <div
                :class="[containerClasses]"
                class="mt-0 flex items-stretch">
                <div
                    v-for="n in number"
                    :key="n"
                    :class="userClasses">
                    <div class="flex items-center gap-4 rounded-xl p-4 text-slate-900 bg-primary/10 md:px-5 md:py-4">
                        <div class="flex w-full flex-col pt-2">
                            <!-- Thumbnail Section -->
                            <div class="flex w-full items-center gap-2">
                                <div class="flex aspect-square w-8 shrink-0 overflow-hidden rounded-lg">
                                    <div
                                        class="relative h-full w-full bg-primary/10 dark:bg-slate-800"
                                        data-placeholder="icon" />
                                </div>

                                <div class="flex w-full items-center">
                                    <div class="flex w-full flex-col lg:w-auto lg:grow">
                                        <div class="flex h-full flex-col">
                                            <div
                                                class="h-4 w-32 rounded -mt-0.5 bg-primary/10 dark:bg-slate-800 md:h-4"
                                                data-placeholder="name" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-1 flex w-full flex-col pb-2 pl-1 lg:w-auto lg:grow">
                                <div class="flex h-full flex-col">
                                    <div
                                        class="flex items-center gap-2.5 mt-2.5"
                                        data-placeholder="meta">
                                        <div class="flex gap-1">
                                            <div class="h-2 w-2 rounded bg-primary/10 dark:bg-slate-800" />
                                            <div class="h-2 w-16 rounded bg-primary/10 dark:bg-slate-800" />
                                            <div class="ml-3 h-2 w-2 rounded bg-primary/10 dark:bg-slate-800" />
                                            <div class="h-2 w-16 rounded bg-primary/10 dark:bg-slate-800" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
