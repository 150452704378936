<script setup>
import { computed, useSlots } from 'vue'

defineProps({
    fontSize: {
        type: String,
        default: 'text-xl md:text-2xl'
    },
    as: {
        type: String,
        default: 'h1'
    }
})

const slot = useSlots()

const hasButton = computed(() => {
    return slot.button !== undefined
})
</script>

<template>
    <component
        :is="as"
        :type="as === 'button' ? 'button' : null"
        :class="fontSize"
        class="break-words font-semibold letter-spacing-tight font-heading">
        <slot />
    </component>
</template>
