<script setup>
import * as UserPermission from '@/Enums/UserPermission.js'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import LikeButton from '@/Components/Global/LikeButton.vue'
import CommentForm from '@/Components/Comment/CommentForm.vue'
import VerifiedBadge from '@/Components/User/VerifiedBadge.vue'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import CommentDropdown from '@/Components/Comment/CommentDropdown.vue'
import { Link } from '@inertiajs/vue3'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions.js'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser.js'
import { useLinkifyString } from '@/Composables/useLinkifyString.ts'
import { useFormatUserTags } from '@/Composables/useFormatUserTags.ts'
import { useDateFormat, useTimeAgo, useWindowSize } from '@vueuse/core'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'

const emit = defineEmits(['created', 'deleted'])

const props = defineProps({
    commentable: Object,
    comment: Object,
    enableReplies: {
        type: Boolean,
        default: true
    },
    actions: {
        type: Boolean,
        default: true
    }
})

const parentLimit = ref(6)
const showForm = ref(false)
const showReplies = ref(true)

const commentKey = ref((Math.random() + 1).toString(36).substring(7))

const timeAgo = useTimeAgo(new Date(props.comment.created_at))
const createdDate = useDateFormat(props.comment.created_at, 'YYYY-MM-DD hh:mma', { locales: usePageProps().app.locale })
const parentCount = props.comment.parent_count
const hasOneParent = parentCount === 1
const { width } = useWindowSize()
const hasParent = props.comment.parent_id !== null
const isDirectChild = props.comment.is_direct_child
const hasChildren = props.comment.children && props.comment.children.length > 0 && props.enableReplies
const children = props.comment.children
const clicked = ref(false)

function toggleCommentLimit () {
    if (width.value < 769) {
        parentLimit.value = 3
    }

    if (width.value < 450) {
        parentLimit.value = 2
    }
}

watch(width, () => {
    toggleCommentLimit()
})

const formData = {
    commentable_id: props.comment.commentable_id,
    commentable_type: props.comment.commentable_type
}

function handleDeletion () {
    emit('deleted')
}

function formatContent (content) {
    let formattedContent = useLinkifyString(content)
    formattedContent = useFormatUserTags(formattedContent, props.comment.taggedUsers)

    return formattedContent
}

onMounted(() => toggleCommentLimit())
</script>

<template>
    <div
        :key="commentKey"
        :class="[
            hasParent ? 'relative border-primary' : '',
            hasOneParent && enableReplies ? 'ml-8 md:ml-12' : (hasParent && (parentCount < parentLimit) && enableReplies ? 'ml-8' : 'comment-child')
        ]"
        class="relative">
        <div
            v-if="hasChildren && enableReplies"
            :class="hasParent ? 'left-4' : 'left-4 md:left-4'"
            class="absolute top-0 h-full w-px bg-primary/10 dark:bg-slate-700">
            <a
                v-if="parentCount < parentLimit"
                :href="`#comment` + comment.id"
                class="absolute top-full left-1/2 h-2 w-2 -translate-x-1/2 rounded-full bg-primary/10 dark:bg-slate-700">
                <span class="sr-only">Go to comment: {{ comment.id }}</span>
            </a>
        </div>

        <div
            :id="`comment${comment.id}`"
            class="relative flex scroll-mt-20 items-start group single-comment">
            <component
                :is="comment.is_deleted ? 'div' : Link"
                :class="[hasParent && enableReplies ? 'w-8' : 'w-8 md:w-8',
                         parentCount > 0 && parentCount < parentLimit && enableReplies ? 'has-indicator' : '',
                         isDirectChild ? '' : 'smaller'
                ]"
                :href="comment.is_deleted ? null : useRoute('user.show', comment.user)"
                class="relative flex items-center avatar-container">
                <UserAvatar
                    :user="comment.is_deleted ? null : comment.user"
                    class="w-full rounded-full ring-1 ring-slate-900/10"
                    size="small" />
            </component>

            <div class="ml-4 flex w-full flex-1 flex-col">
                <div class="flex w-full items-center gap-2">
                    <div class="flex items-center gap-2">
                        <component
                            :is="comment.is_deleted ? 'span' : Link"
                            :href="comment.is_deleted ? null : useRoute('user.show', comment.user)"
                            class="font-semibold">
                            {{ comment.is_deleted ? 'User' : comment.user.name }}
                        </component>
                        <VerifiedBadge
                            v-if="!comment.is_deleted"
                            :user="comment.user"
                            class="w-5" />
                    </div>
                    <span class="text-sm text-slate-900/75 dark:text-white/75">&bull;</span>
                    <a
                        v-tooltip="`${createdDate}`"
                        :href="comment.link"
                        class="mt-px text-xs text-slate-900/75 dark:text-white/75"
                        v-html="timeAgo" />
                </div>

                <div
                    v-if="hasParent"
                    class="mt-2 border-l pl-2">
                    <a
                        :href="comment.parent_link"
                        class="flex-initial text-sm text-slate-900/75 border-secondary/50 dark:border-white/50 dark:text-white/75">
                        <em>Reply to {{ comment.parent_deleted ? 'Deleted comment' : comment.parent_user_name }}</em>
                    </a>
                </div>

                <div v-if="comment.is_deleted">
                    <em class="my-2 flex text-sm text-slate-900/75 dark:text-white/75">Comment Deleted</em>
                </div>
                <div
                    v-else
                    :class="[{'line-clamp-4': !clicked}, hasParent ? 'my-2' : 'my-1']"
                    class="break-all text-sm"
                    @click="clicked = true"
                    v-html="formatContent(comment.content)" />

                <div
                    v-if="!comment.is_deleted && actions"
                    class="mt-2 flex items-center space-x-4">
                    <LikeButton
                        small
                        :can="usePermissions(UserPermission.LIKE_COMMENT)"
                        :count="comment.likers_count"
                        :is-me="useIsAuthedUser(comment.user)"
                        :liked="comment.is_liked"
                        :url="useRoute('comment.like.toggle', {comment: comment})" />

                    <AuthModalTrigger v-if="!useIsAuthed()">
                        <template #trigger>
                            <button class="border-b text-sm text-slate-900/75 border-secondary">
                                Login to reply
                            </button>
                        </template>
                    </AuthModalTrigger>

                    <button
                        v-if="useIsAuthed() && enableReplies"
                        class="border-b text-sm text-slate-900/75 border-secondary"
                        @click="showForm = !showForm">
                        {{ showForm ? 'Cancel' : 'Reply' }}
                    </button>
                </div>

                <div
                    v-if="hasChildren && !showReplies && enableReplies"
                    class="mt-4">
                    <button
                        class="flex items-center text-sm text-slate-900/75"
                        @click="showReplies = !showReplies">
                        ({{ comment.reply_count }}) {{ comment.reply_count === 1 ? 'Reply' : 'Replies' }}
                        <ChevronUpIcon
                            v-if="showReplies"
                            class="ml-1 w-4" />
                        <ChevronDownIcon
                            v-else
                            class="ml-1 w-4" />
                    </button>
                </div>
            </div>

            <CommentDropdown
                v-if="!comment.is_deleted && actions"
                :commentable="commentable"
                :comment="comment"
                @deleted="handleDeletion" />
        </div>

        <div
            v-if="showForm && !comment.is_deleted && enableReplies"
            :class="hasParent ? 'pl-8' : 'pl-12'"
            class="mt-4">
            <CommentForm
                v-if="usePermissions(UserPermission.CREATE_COMMENT)"
                :form-data="formData"
                :parent="comment"
                @created="emit('created')" />
        </div>

        <div
            v-if="hasChildren && showReplies && enableReplies"
            class="flex flex-col py-8 space-y-8">
            <CommentSingle
                v-for="child in children"
                :key="child.id"
                :actions="actions"
                :form-data="formData"
                :commentable="commentable"
                :comment="child"
                @deleted="emit('deleted')" />
        </div>
    </div>
</template>
