<script setup lang="ts">
import GameCard from '@/Components/Game/GameCard.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import GameSkeletonGrid from '@/Components/Game/GameSkeletonGrid.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
    number: {
        type: Number,
        default: 6
    },
    horizontal: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    gameWidth: {
        type: String,
        default: 'w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4'
    }
})

const containerClasses = computed(() => {
    return 'md:no-container'
})

const gameClasses = computed(() => {
    return props.gameWidth
})
</script>

<template>
    <div
        :class="[containerClasses]">
        <div
            :class="horizontal ? '-mx-2.5' : '-mx-2'"
            class="mt-0 flex flex-wrap items-stretch">
            <template
                v-for="n in number"
                :key="n">
                <div
                    v-if="!horizontal"
                    :class="[gameClasses, small ? '' : 'p-1 md:p-2']">
                    <div class="rounded-xl p-1 pb-4 bg-primary/5">
                        <article
                            class="flex flex-col text-slate-900 group">
                            <div
                                class="relative w-full overflow-hidden rounded-xl bg-primary/5 aspect-[3/4] dark:bg-slate-800"
                                data-placeholder="game-thumbnail" />

                            <div class="flex h-full grow flex-col px-2 mt-0.5">
                                <div
                                    class="h-5 w-full rounded mt-2.5 bg-primary/5 dark:bg-slate-800"
                                    data-placeholder="game-title" />
                                <div class="flex w-full justify-between mt-1.5">
                                    <div
                                        class="h-4 w-4/12 rounded bg-primary/5 dark:bg-slate-800"
                                        data-placeholder="game-date" />
                                    <div class="flex gap-2">
                                        <div
                                            class="rounded-full size-4 bg-primary/5 dark:bg-slate-800"
                                            data-placeholder="game-platforms" />
                                        <div
                                            class="rounded-full size-4 bg-primary/5 dark:bg-slate-800"
                                            data-placeholder="game-platforms" />
                                        <div
                                            class="rounded-full size-4 bg-primary/5 dark:bg-slate-800"
                                            data-placeholder="game-platforms" />
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div
                    v-else
                    class="w-full">
                    <div class="rounded-xl p-2.5 bg-primary/5">
                        <article
                            class="flex items-center text-slate-900 group">
                            <div
                                class="relative w-20 overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] bg-primary/5 aspect-[3/4] dark:bg-slate-800"
                                data-placeholder="game-thumbnail" />

                            <div class="flex h-full grow flex-col px-2 mt-0.5">
                                <div
                                    class="h-5 w-6/12 rounded mt-2.5 bg-primary/5 dark:bg-slate-800"
                                    data-placeholder="game-title" />
                                <div class="flex w-full justify-between mt-1.5">
                                    <div
                                        class="h-2 w-4/12 rounded bg-primary/5 dark:bg-slate-800"
                                        data-placeholder="game-date" />
                                    <div class="flex gap-2">
                                        <div
                                            class="rounded-full size-4 bg-primary/5 dark:bg-slate-800"
                                            data-placeholder="game-platforms" />
                                        <div
                                            class="rounded-full size-4 bg-primary/5 dark:bg-slate-800"
                                            data-placeholder="game-platforms" />
                                        <div
                                            class="rounded-full size-4 bg-primary/5 dark:bg-slate-800"
                                            data-placeholder="game-platforms" />
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
