import ArticlesSection from '@/Pages/Users/HomeSections/ArticlesSection.vue'
import PostsSection from '@/Pages/Users/HomeSections/PostsSection.vue'
import GamesSection from '@/Pages/Users/HomeSections/GamesSection.vue'
import ConsolesSection from '@/Pages/Users/HomeSections/ConsolesSection.vue'
import FollowingUsersSection from '@/Pages/Users/HomeSections/FollowingUsersSection.vue'

export function useProfileSectionsMap () {
    return {
        ArticlesSection,
        PostsSection,
        GamesSection,
        ConsolesSection,
        FollowingUsersSection
    }
}
