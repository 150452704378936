<template>
    <svg
        viewBox="0 0 306 306"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M271.634 81.93C260.861 73.312 248.549 67.309 235.16 64.078C227.465 62.231 219.77 61.462 211.921 61.154C206.073 61 200.225 61 194.376 61C166.828 61 139.126 61 111.578 61C105.73 61 99.882 61 94.033 61.154C86.184 61.462 78.489 62.231 70.794 64.078C57.405 67.31 45.093 73.312 34.32 81.93C12.62 99.321 0 125.638 0 153.34C0 181.196 12.62 207.359 34.32 224.75C45.093 233.368 57.405 239.371 70.794 242.602C78.489 244.449 86.184 245.218 94.033 245.526C99.881 245.68 105.729 245.68 111.578 245.68C139.126 245.68 166.828 245.68 194.376 245.68C200.224 245.68 206.072 245.68 211.921 245.526C219.77 245.218 227.465 244.449 235.16 242.602C248.549 239.37 260.861 233.368 271.634 224.75C293.334 207.359 305.954 181.042 305.954 153.34C305.954 125.484 293.334 99.321 271.634 81.93ZM231.312 178.118C227.157 180.888 222.694 182.581 217.769 183.351C212.844 184.121 208.073 183.967 203.148 183.967C169.752 183.967 136.201 183.967 102.805 183.967C97.88 183.967 93.109 184.121 88.184 183.351C83.259 182.581 78.796 180.889 74.641 178.118C66.484 172.424 61.559 163.19 61.559 153.34C61.559 143.336 66.484 134.102 74.641 128.562C78.796 125.792 83.259 124.099 88.184 123.329C93.109 122.559 97.88 122.713 102.805 122.713C136.201 122.713 169.752 122.713 203.148 122.713C208.073 122.713 212.844 122.559 217.769 123.329C222.694 124.099 227.157 125.791 231.312 128.562C239.469 134.256 244.394 143.49 244.394 153.34C244.393 163.344 239.469 172.578 231.312 178.118Z"
            fill="currentColor" />
    </svg>
</template>
