<script setup>
import useEmitter from '@/Composables/useEmitter.js'
import { onMounted } from 'vue'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    },
    loginTitle: {
        type: [String, null],
        default: null
    },
    registerTitle: {
        type: [String, null],
        default: null
    },
    defaultForm: {
        type: String,
        default: 'login'
    }
})

onMounted(() => {
    if (props.show) {
        openAuthModal()
    }
})

function openAuthModal () {
    useAnalyticsEvent('Open auth modal: ' + props.defaultForm)

    useEmitter.emit('openAuthModal', {
        loginTitle: props.loginTitle,
        registerTitle: props.registerTitle,
        defaultForm: props.defaultForm
    })
}
</script>

<template>
    <div @click="openAuthModal()">
        <slot name="trigger" />
    </div>
</template>
