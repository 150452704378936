<script setup lang="ts">
import UserAvatar from '@/Components/User/UserAvatar.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import { ref } from 'vue'
import { Mentionable } from 'vue-mention'
import { useRoute } from '@/Composables/useRoute.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest'

defineProps({
    placeholder: String,
    error: String,
    textareaClass: String
})

const model = defineModel({
    type: String
})

const users = ref([])
const loading = ref(false)

async function searchUsers (search: string) {
    return await useMakeRequest(useRoute('api.users.search', { q: search }), 'GET', {}).then(response => {
        return response.map((user: any) => ({
            ...user,
            value: user.username
        }))
    })
}

async function loadItems (searchText = null) {
    loading.value = true
    if (searchText && searchText.length < 3) {
        loading.value = false
        users.value = []
        return
    }

    users.value = await searchUsers(searchText)
    loading.value = false
}

const emit = defineEmits(['enter', 'blur', 'focus'])
</script>

<template>
    <Mentionable
        :keys="['@']"
        :items="users"
        offset="1"
        filtering-disabled
        insert-space
        @open="loadItems()"
        @search="loadItems($event)">
        <InputTextarea
            v-model="model"
            :error="error"
            autoheight
            :input-classes="textareaClass"
            :placeholder="placeholder"
            @blur="emit('blur')"
            @focus="emit('focus')"
            @enter="emit('enter')" />

        <template #no-result>
            <div class="px-4 text-sm italic opacity-75 py-1.5">
                Keep typing to search for users...
            </div>
        </template>

        <template
            #item-@="{ item }"
            class="bg-red-200">
            <div
                :key="item.username"
                class="flex items-center gap-2">
                <UserAvatar
                    :user="item"
                    class="h-8 w-8 rounded-full" />
                <div class="flex flex-col">
                    <div class="text-sm">
                        {{ item.name }}
                    </div>
                    <div class="-mt-1 text-xs opacity-50">
                        @{{ item.username }}
                    </div>
                </div>
            </div>
        </template>
    </Mentionable>
</template>
