<script setup lang="ts">
import TagLink from '@/Components/Tag/TagLink.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import TagSkeletonGrid from '@/Components/Tag/TagSkeletonGrid.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import type { Tag } from '@/Types/Models/Tag'
import type { Component, PropType } from 'vue'

defineProps({
    tags: Array as PropType<Tag[]>,
    title: String,
    description: String,
    icon: [Object as PropType<Component>, Function as PropType<Component>],
    small: {
        type: Boolean,
        default: false
    },
    limit: {
        type: Number,
        default: 12
    }
})

function limitTags (tags: Tag[], limit: number): Tag[] {
    return tags.slice(0, limit)
}
</script>

<template>
    <div class="relative -mx-1 flex w-full flex-wrap items-center">
        <template v-if="tags && tags.length > 0">
            <div
                v-for="tag in limitTags(tags, limit)"
                :key="tag.slug"
                :class="small ? 'p-1' : 'p-1'"
                class="flex">
                <TagLink
                    :small="small"
                    :tag="tag" />
            </div>
        </template>
        <template v-else>
            <TagSkeletonGrid class="flex flex-wrap" />
        </template>
    </div>
</template>
