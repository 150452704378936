<script setup lang="ts">
defineProps({
    count: {
        type: Number,
        default: 6
    },
    small: {
        type: Boolean,
        default: false
    }
})

function randomInt (min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

</script>

<template>
    <div class="-mx-1 flex">
        <div
            v-for="i in count"
            :key="i"
            :class="small ? 'p-1' : 'p-1'">
            <div
                :class="small ? 'h-[30px]' : 'h-[35px]'"
                :style="`width:${randomInt(50, 150)}px;`"
                class="animate-pulse rounded-lg bg-primary/10 dark:bg-slate-800 dark:hover:bg-slate-950" />
        </div>
    </div>
</template>
