<script setup>
import Button from '@/Components/UI/Button.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import InputCheckbox from '@/Components/Input/InputCheckbox.vue'
import InputRadioSelect from '@/Components/Input/InputRadioSelect.vue'
import { useForm } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'

const props = defineProps({
    user: Object,
    locales: Object,
    settings: Object
})

const form = useForm({
    settings: props.settings,
    flash: true
})

function submit () {
    form.patch(useRoute('user.settings.update', props.user), {
        preserveScroll: true,
        onSuccess: () => {
            const darkMode = form.settings.dark_mode
            useAnalyticsEvent('Dark Mode Changed', {
                set: darkMode
            })

            if (darkMode === 'auto') {
                localStorage.removeItem('darkMode')
                if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    document.documentElement.classList.add('dark')
                } else {
                    document.documentElement.classList.remove('dark')
                }
            } else {
                localStorage.darkMode = darkMode
                if (darkMode === 'on') {
                    document.documentElement.classList.add('dark')
                } else {
                    document.documentElement.classList.remove('dark')
                }
            }
        }
    })
}

const localeOptions = computed(() => {
    return Object.keys(props.locales).map(key => {
        return {
            key,
            title: `${props.locales[key]} (${key})`
        }
    })
})

const currentLocale = localeOptions.value.find(function (locale) {
    return locale.key === form.settings.locale
})

const localeValue = ref(currentLocale)

function reset () {
    form.reset()
    localeValue.value = currentLocale
}

watch(localeValue, (locale) => {
    if (locale) {
        form.settings.locale = locale.key
    }
})
</script>

<template>
    <div>
        <form @submit.prevent="submit">
            <div class="-mx-2 flex flex-col gap-2 md:-mx-3">
                <div class="rounded-lg px-2 py-2 hover:bg-primary/5 md:px-3">
                    <InputCheckbox
                        v-model="form.settings.public"
                        :error="form.errors['settings.public']"
                        description="Allow your profile to accessible and searchable (published articles will not be affected)"
                        label="Public profile"
                        text-first />
                </div>

                <div class="rounded-lg px-2 py-2 hover:bg-primary/5 md:px-3">
                    <InputCheckbox
                        v-model="form.settings.reduce_motion"
                        :error="form.errors['settings.reduce_motion']"
                        description="Minimise and disable transitions and movements"
                        label="Reduce motion"
                        text-first />
                </div>

                <div class="rounded-lg px-2 py-2 hover:bg-primary/5 md:px-3">
                    <InputCheckbox
                        v-model="form.settings.email_notifications"
                        :error="form.errors['settings.email_notifications']"
                        description="Allow notifications to be sent to your email"
                        label="Email notifications"
                        text-first />
                </div>

                <div class="rounded-lg px-2 py-2 hover:bg-primary/5 md:px-3">
                    <InputCheckbox
                        v-model="form.settings.allow_community_tweeting"
                        :error="form.errors['settings.allow_community_tweeting']"
                        :description="`Allow your published articles to be posted on the
                    <a href='${usePageProps().config.social_media.twitter.url}' class='ml-1 text-primary hover:underline' rel='nofollow' target='_blank'>@${usePageProps().config.social_media.twitter.username}</a> Twitter/X account`"
                        label="Community tweeting"
                        text-first />
                </div>

                <div class="rounded-lg px-2 py-2 hover:bg-primary/5 md:px-3">
                    <InputRadioSelect
                        v-model="form.settings.dark_mode"
                        :options="[{value: 'on', label: 'On'}, {value: 'off', label: 'Off'}, {value: 'auto', label: 'Auto'}]"
                        :error="form.errors['settings.dark_mode']"
                        description="Force enable or set to auto to follow system settings"
                        label="Dark mode"
                        text-first />
                </div>

                <div class="rounded-lg px-2 pt-2 pb-3 hover:bg-primary/5 md:px-3">
                    <InputSelect
                        v-model="localeValue"
                        :options="localeOptions"
                        label="Locale" />
                </div>
            </div>
            <div class="mt-4 flex w-full justify-end gap-4">
                <Button
                    :disabled="!form.isDirty"
                    class="px-8"
                    type="button"
                    variant="white"
                    @click="reset">
                    {{ form.processing ? 'Resetting' : 'Reset' }}
                </Button>
                <Button
                    :disabled="!form.isDirty"
                    class="px-8"
                    type="submit">
                    {{ form.processing ? 'Saving' : 'Save' }}
                </Button>
            </div>
        </form>
    </div>
</template>
