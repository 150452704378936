<script lang="ts" setup>
import { cn } from '@/utils.ts'
import { type PropType, useAttrs } from 'vue'
import { CheckIcon } from '@heroicons/vue/20/solid'

type Step = {
    name: string
    description: string
    checked: boolean
}

defineProps({
    steps: {
        type: Array as PropType<Step[]>,
        required: true
    }
})

const attrs = useAttrs()

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <ul
        :class="cn([
            attrs.class
        ])">
        <li
            v-for="(step, i) in steps"
            :key="i"
            :class="{
                'bg-primary/5': step.checked,
                'rounded-t-lg': i === 0 || (!steps[i - 1].checked),
                'rounded-b-lg': i === steps.length - 1 || (!steps[i + 1].checked),
            }"
            class="relative flex p-4 text-zinc-900 group">
            <div
                class="flex aspect-square items-center justify-center rounded-full border text-white size-5 mt-[3px]"
                :class="{
                    'bg-indigo-500 border-indigo-500': step.checked,
                    'border-zinc-200': !step.checked,
                }">
                <CheckIcon
                    v-if="step.checked"
                    class="w-3" />
            </div>
            <div class="ml-3 flex flex-col">
                <div class="block text-sm font-medium">
                    {{ step.name }}
                </div>
                <div
                    class="block text-sm text-zinc-900/75 -mt-0.5">
                    {{ step.description }}
                </div>
            </div>
        </li>
    </ul>
</template>
