<script setup lang="ts">
import Status from '@/Enums/Status.ts'
import ArticleThumbnail from '@/Components/Article/ArticleThumbnail.vue'
import ArticleManageActions from '@/Components/Article/ArticleManageActions.vue'
import { Link } from '@inertiajs/vue3'
import { computed, type PropType } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { usePlural } from '@/Composables/usePlural.js'
import { useDateFormat, useTimeAgo } from '@vueuse/core'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { ChartBarSquareIcon } from '@heroicons/vue/24/outline'
import { useFormatNumber } from '@/Composables/useFormatNumber.ts'
import { useArticleAccessInfo } from '@/Composables/useArticleAccessInfo.ts'
import { ChatBubbleOvalLeftIcon, EyeIcon, HeartIcon } from '@heroicons/vue/24/solid'

interface Column {
    key: string;
    title: string;
    class: string;
}

const props = defineProps({
    article: Object as PropType<Article>,
    index: Number,
    columns: Array as PropType<Column[]>
})

const colours = {
    published: 'bg-primary-lighter text-primary border-current',
    approved: 'bg-green-100 text-green-800 border-current',
    rejected: 'bg-red-100 text-red-800 border-current',
    'in review': 'bg-orange-100 text-orange-800 border-current',
    submitted: 'bg-gray-200 text-slate-900 border-current',
    drafts: 'bg-blue-100 text-blue-800 border-current',
    trashed: 'bg-gray-100 text-gray-800 border-current'
}

const baseRoute = 'dashboard.articles'
const editRoute = 'articles.edit'

const urlMap = {
    published: props.article.permalink,
    approved: useRoute(baseRoute, { status: Status.APPROVED }),
    rejected: useRoute(editRoute, props.article),
    'in review': null,
    submitted: useRoute(baseRoute, { status: Status.SUBMITTED }),
    drafts: useRoute(editRoute, props.article),
    trashed: useRoute(baseRoute, { status: Status.TRASHED })
}

const isPublished = computed(() => props.article.status.name === Status.PUBLISHED)
const isDraft = computed(() => props.article.status.name === Status.DRAFTS)

const stats = [
    {
        label: 'View',
        value: props.article.views,
        component: EyeIcon
    },
    {
        label: 'Like',
        value: props.article.likes_count,
        component: HeartIcon
    },
    {
        label: 'Comment',
        value: props.article.comments_count,
        component: ChatBubbleOvalLeftIcon
    }
]

const dateFormat = 'YYYY-MM-DD hh:mma'

function getClass (key: string) {
    return props.columns.find(col => col.key === key).class
}
</script>

<template>
    <div class="relative flex w-full flex-col items-center pt-7 pb-4 group md:flex-row md:pt-0 md:pb-0">
        <div :class="getClass('article')">
            <div class="flex items-center gap-4">
                <div class="w-32 shrink-0">
                    <ArticleThumbnail
                        :article="article"
                        :actions="false" />
                </div>
                <div class="flex w-full flex-col">
                    <div class="flex pt-2 md:pt-0">
                        <component
                            :is="urlMap[article.status.name] ? Link : 'h2'"
                            :href="urlMap[article.status.name]"
                            :class="urlMap[article.status.name] ? 'hover:text-primary dark:hover:text-primary-light' : 'cursor-not-allowed'"
                            class="text-base/5 font-heading line-clamp-2 text-pretty md:text-lg/6">
                            {{ article.title ?? '[Untitled]' }}
                        </component>
                    </div>
                    <div class="mt-1 flex flex-col text-xs text-slate-900/75 gap-0.5 dark:text-white/75">
                        <div v-if="isDraft">
                            Updated <span
                                v-tooltip="useDateFormat(article.updated_at, dateFormat, { locales: usePageProps().app.locale }).value">
                                {{ useTimeAgo(article.updated_at).value }}
                            </span>
                        </div>
                        <div
                            v-if="!isDraft"
                            class="capitalize">
                            {{ article.status.name.toLowerCase() }}
                            <span
                                v-tooltip="useDateFormat(article.status.date, dateFormat, { locales: usePageProps().app.locale }).value">
                                {{ useTimeAgo(article.status.date).value }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            :class="getClass('status')"
            class="absolute top-2 left-0.5 md:static">
            <Link
                :class="colours[article.status.name]"
                :href="useRoute(baseRoute, {status: article.status.name})"
                class="rounded px-2 text-xs font-semibold uppercase tracking-wide py-0.5">
                {{ article.status.name }}
            </Link>
        </div>
        <div
            :class="getClass('visibility')"
            class="cursor-default text-sm text-slate-950/50">
            <div
                v-if="!isPublished"
                class="hidden md:flex">
                &mdash;
            </div>
            <div
                v-else
                class="flex pt-0 pb-2 md:py-2">
                <div
                    v-tooltip="article.accessType ? useArticleAccessInfo(article.accessType).tooltip : null"
                    class="flex items-center gap-1">
                    <component
                        :is="useArticleAccessInfo(article.accessType).icon"
                        class="w-4" />
                    <div>
                        {{ article.accessType ? useArticleAccessInfo(article.accessType).title : '&mdash;' }}
                    </div>
                </div>
            </div>
        </div>
        <div :class="getClass('stats')">
            <div class="flex items-center justify-between">
                <div class="flex gap-8 md:hidden md:gap-7 lg:flex">
                    <div
                        v-for="stat in stats"
                        :key="stat.label"
                        v-tooltip="`${stat.value} ${usePlural(stat.label, stat.value) }`"
                        class="flex cursor-default flex-col gap-0 text-center items-center">
                        <span>
                            <component
                                :is="stat.component"
                                class="w-4 text-slate-950/40 md:text-slate-950/25" />
                        </span>
                        <span
                            class="text-sm text-slate-950/50 md:text-xs"
                            v-html="isPublished ? useFormatNumber(stat.value) : '&mdash;'" />
                    </div>
                </div>
                <Link
                    v-if="isPublished"
                    v-tooltip="`See all stats`"
                    class="flex items-center gap-2 text-sm transition-all text-slate-950/50 hover:text-slate-950"
                    :href="useRoute('dashboard.articles.analytics', article)">
                    <ChartBarSquareIcon class="w-6" />
                    <span class="md:hidden">See more</span>
                </Link>
            </div>
            <!--                <VTooltip>-->
            <!--                    <div>-->
            <!--                        <Link :href="useRoute('dashboard.articles.analytics', article)">-->
            <!--                            <ChartBarSquareIcon class="w-6" />-->
            <!--                        </Link>-->
            <!--                    </div>-->

            <!--                    <template #popper>-->
            <!--                        <div class="flex gap-8">-->
            <!--                            <div-->
            <!--                                v-for="stat in stats"-->
            <!--                                :key="stat.label"-->
            <!--                                v-tooltip="`${stat.value} ${usePlural(stat.label, stat.value) }`"-->
            <!--                                class="flex cursor-default flex-col gap-1 text-center text-white">-->
            <!--                                <span>-->
            <!--                                    <component-->
            <!--                                        :is="stat.component"-->
            <!--                                        class="w-5" />-->
            <!--                                </span>-->
            <!--                                <span-->
            <!--                                    class="text-xs"-->
            <!--                                    v-html="isPublished ? useFormatNumber(stat.value) : '&mdash;'" />-->
            <!--                            </div>-->
            <!--                        </div>-->
            <!--                    </template>-->
            <!--                </VTooltip>-->
            <!--                <div-->
            <!--                    v-for="stat in stats"-->
            <!--                    :key="stat.label"-->
            <!--                    v-tooltip="`${stat.value} ${usePlural(stat.label, stat.value) }`"-->
            <!--                    class="flex cursor-default flex-col text-center hover:text-primary dark:hover:text-white"-->
            <!--                    :class="[isPublished ? 'text-primary/50 dark:text-white/50' : 'text-primary dark:text-white opacity-50 pointer-events-none']">-->
            <!--                    <span>-->
            <!--                        <component-->
            <!--                            :is="stat.component"-->
            <!--                            class="w-5" />-->
            <!--                    </span>-->
            <!--                    <span-->
            <!--                        class="text-lg font-heading"-->
            <!--                        v-html="isPublished ? useFormatNumber(stat.value) : '&mdash;'" />-->
            <!--                </div>-->
        </div>

        <div
            :class="getClass('actions')"
            class="absolute -top-1 -right-2 md:static">
            <ArticleManageActions :article="article" />
        </div>
    </div>
</template>
