<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { useSlots } from 'vue'
import { FolderOpenIcon } from '@heroicons/vue/24/outline'

defineProps({
    icon: {
        type: [Object, String, Function, Boolean],
        default: FolderOpenIcon
    },
    iconWidth: Number,
    iconHeight: Number,
    title: String,
    description: String,
    socialLinks: {
        type: Boolean,
        default: false
    }
})

const slot = useSlots()
</script>

<template>
    <div
        class="mt-2 flex w-full flex-col gap-4 md:flex-row">
        <div
            :class="socialLinks ? 'md:w-1/2 lg:w-2/3' : ''"
            class="flex w-full flex-col items-center text-center">
            <component
                :is="icon"
                v-if="icon && (typeof icon === 'object' || typeof icon === 'function')"
                class="mx-auto mb-2 w-12 text-zinc-900 dark:text-white" />
            <img
                v-if="icon && typeof icon === 'string'"
                v-lazy="icon"
                :width="iconWidth"
                :height="iconHeight"
                alt="Icon image for section"
                class="mx-auto mb-2 w-24">
            <SectionTitle
                class="text-zinc-900 dark:text-white"
                v-text="title" />
            <SectionDescription
                v-if="!slot.description"
                v-html="description" />
            <slot name="description" />
            <div
                v-if="slot.button"
                class="mt-4">
                <slot name="button" />
            </div>
        </div>
    </div>
</template>
