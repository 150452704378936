<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import TagList from '@/Components/Tag/TagList.vue'
import Breadcrumbs from '@/Components/UI/Breadcrumbs.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import TagFollowButton from '@/Components/Tag/TagFollowButton.vue'
import TagSkeletonGrid from '@/Components/Tag/TagSkeletonGrid.vue'
import { Deferred } from '@inertiajs/vue3'
import type { Tag } from '@/Types/Models/Tag'
import { computed, ref, type PropType } from 'vue'
import type { PageHeader } from '@/Types/PageHeader'
import { useHeader } from '@/Composables/useHeader.ts'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import {
    TagIcon as TagIconSolid,
    ArrowTrendingUpIcon,
    SparklesIcon,
    FireIcon,
    BookmarkIcon,
    UserGroupIcon,
    RssIcon,
    CalendarIcon
} from '@heroicons/vue/24/solid'

// Define the component props
const props = defineProps({
    header: Object as PropType<PageHeader>,
    hasRelatedTags: Boolean,
    truncateDescription: {
        type: Boolean,
        default: true
    },
    icon: {
        type: [Function, null],
        default: null
    },
    relatedTags: [Array, null] as PropType<Tag[]>
})

// Breakpoints configuration
const breakpoints = useBreakpoints(breakpointsTailwind)
const smAndLarger = breakpoints.greaterOrEqual('sm')

// Map of icons
const iconMap = {
    ArrowTrendingUpIcon,
    FireIcon,
    BookmarkIcon,
    UserGroupIcon,
    CalendarIcon,
    RssIcon,
    TagIconSolid,
    GamePadFilled: Icon.GamePadFilled,
    SparklesIcon,
    GamePad: Icon.GamePad
}

// Determine the icon component based on the header icon
const iconComponent = computed(() => {
    if (props.icon) {
        return props.icon
    }

    if (props.header?.icon && typeof props.header.icon === 'string' && props.header.icon.startsWith('component:')) {
        const componentName = props.header.icon.replace('component:', '')
        return iconMap[componentName]
    }
    return null
})

// Background color and gradient setup
const backgroundColour = useHeader('background', props.header)
const gradient = computed(() => backgroundColour && backgroundColour === 'gradient')

// Track whether the description is expanded
const descriptionLength = ref(120)
const descriptionClicked = ref(false)
const description = computed(() => {
    return (descriptionClicked.value || !props.truncateDescription)
        ? props.header.description
        : truncate(props.header.description, descriptionLength.value)
})

function truncate (text: string, length: number): string {
    if (text.length > length && props.truncateDescription) {
        return `${text.substring(0, length)}...`
    }
    return text
}
</script>

<template>
    <div class="container p-5 md:max-w-[unset] md:p-4">
        <div
            v-if="header?.title"
            :class="[
                useHeader('text', header),
                gradient ? 'bg-gradient-app bg-gradient-to-t' : '',
                hasRelatedTags ? 'md:pb-12' : ''
            ]"
            :style="{ backgroundColor: `${useHeader('background', header)}` }"
            class="relative mb-8 rounded-xl py-8 shadow-sm md:py-18">
            <div class="container relative mx-auto">
                <div class="flex flex-col items-start gap-4 sm:flex-row sm:items-center sm:justify-between sm:gap-0">
                    <div>
                        <Breadcrumbs
                            v-if="header?.breadcrumbs"
                            class="mb-4"
                            :breadcrumbs="header?.breadcrumbs" />
                        <SectionTitle
                            font-size="text-2xl xs:text-2xl md:text-4xl"
                            class="mx-auto flex items-center tracking-tight">
                            <div
                                v-if="useHeader('icon', header) || iconComponent"
                                :class="[
                                    useHeader('iconBackground', header),
                                    iconComponent ? 'p-1' : 'p-0'
                                ]"
                                :style="{ color: `${useHeader('iconText', header)}` }"
                                class="mr-4 flex aspect-square w-9 items-center justify-center overflow-hidden rounded-lg shadow-sm">
                                <img
                                    v-if="useHeader('icon', header)"
                                    class="h-full w-full object-cover"
                                    :alt="`Icon for page`"
                                    :src="useHeader('icon', header)"
                                    width="36">
                                <component
                                    :is="iconComponent"
                                    v-if="iconComponent"
                                    class="w-6" />
                            </div>
                            <div class="break-words hyphens-auto">
                                {{ header?.title }}
                            </div>
                        </SectionTitle>
                        <p
                            v-if="header?.description"
                            class="mt-2 block text-sm text-inherit prose xs:hidden">
                            {{ description }}
                            <button
                                v-if="truncateDescription && header.description.length > descriptionLength"
                                class="inline-flex font-semibold hover:underline"
                                @click="descriptionClicked = !descriptionClicked">
                                {{ descriptionClicked ? "Read less" : "Read more" }}
                            </button>
                        </p>
                    </div>
                    <div
                        v-if="header.tag"
                        class="shrink-0">
                        <TagFollowButton
                            :padding="!smAndLarger ? 'px-2 py-1' : 'px-4 py-2'"
                            :tag="header.tag" />
                    </div>
                    <slot name="button" />
                </div>

                <p
                    v-if="header?.description"
                    class="mt-px ml-14 hidden max-w-2xl text-sm text-inherit prose text-pretty xs:block">
                    {{ description }}
                    <button
                        v-if="truncateDescription && header.description.length > descriptionLength"
                        class="inline-flex font-semibold hover:underline"
                        @click="descriptionClicked = !descriptionClicked">
                        {{ descriptionClicked ? "Read less" : "Read more" }}
                    </button>
                </p>

                <div
                    v-if="hasRelatedTags"
                    class="mt-2">
                    <Deferred data="relatedTags">
                        <template #fallback>
                            <TagSkeletonGrid small />
                        </template>

                        <TagList
                            :small="true"
                            :center="false"
                            :tags="relatedTags" />
                    </Deferred>
                </div>
            </div>
        </div>
    </div>
</template>
