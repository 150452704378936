import { useContrast } from '@/Composables/useContrast.ts'

interface IconProps {
    dark?: string;
default?: string;
}

interface HeaderProps {
    colour?: string;
    icon?: string | IconProps | null;
}

export function useHeader (type: 'background' | 'text' | 'iconBackground' | 'iconText' | 'icon', props?: HeaderProps): string | null {
    const defaultColour = 'var(--colors-primary)'
    const colour = props?.colour ?? defaultColour

    const background = colour
    const text = useContrast(colour, 'text-black/75', 'text-white')
    const iconBackground = useContrast(colour, 'bg-black/75', 'bg-white')
    const iconText = colour

    let icon: string | null = null
    if (props?.icon !== null && typeof props?.icon !== 'undefined') {
        if (typeof props.icon === 'string') {
            if (!props.icon.startsWith('component:')) {
                icon = props.icon
            }
        } else {
            icon = useContrast(colour, props.icon?.dark ?? '', props.icon?.default ?? '')
        }
    }

    const headerData = {
        background,
        text,
        iconBackground,
        iconText,
        icon
    }

    return headerData[type]
}
