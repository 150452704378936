<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Button from '@/Components/UI/Button.vue'
import LikeButton from '@/Components/Global/LikeButton.vue'
import ShareModal from '@/Components/Global/ShareModal.vue'
import ArticleBookmarkButton from '@/Components/Article/ArticleBookmarkButton.vue'
import { onMounted, type PropType, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { usePermissions } from '@/Composables/usePermissions'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser.js'
import { useCountArticleLikes } from '@/Composables/useCountArticleLikes.ts'

const props = defineProps({
    article: Object as PropType<Article>,
    light: {
        type: Boolean,
        default: false
    },
    commentsEnabled: {
        type: Boolean,
        default: true
    },
    initialLikesCount: [Number, null],
    initialCommentsCount: [Number, null]
})

function scrollToComments () {
    const commentSection = document.querySelector('#comments')
    commentSection.scrollIntoView({ behavior: 'smooth' })
}

const likesCount = ref<Number>(0)

function getLikesCount () {
    useCountArticleLikes(props.article).then(function (result) {
        likesCount.value = result
    })
}

onMounted(() => {
    if (props.initialLikesCount === null || props.initialLikesCount === undefined) {
        getLikesCount()
    } else {
        likesCount.value = props.initialLikesCount
    }
})
</script>

<template>
    <div class="flex items-center">
        <div class="flex items-center gap-2">
            <LikeButton
                :key="likesCount"
                :light="light"
                :can="usePermissions(UserPermission.LIKE_ARTICLE)"
                :count="likesCount"
                small
                :is-me="useIsAuthedUser(article.user)"
                :liked="article.liked"
                :url="useRoute('articles.like.toggle', {article: article})"
                like-tooltip="Like article"
                unlike-tooltip="Unlike article" />

            <ArticleBookmarkButton
                :article="article"
                small
                :bookmarked="article.bookmarked" />

            <ShareModal
                small
                :title="article.title"
                :excerpt="article.excerpt"
                :url="article.permalink" />
        </div>
    </div>
</template>
