<script setup>
import Button from '@/Components/UI/Button.vue'
import Novus from '@/Components/Global/Novus.vue'
import { computed, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { FireIcon, InformationCircleIcon, MagnifyingGlassIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    status: Number
})

const title = computed(() => {
    return {
        503: 'Under maintenance',
        500: 'Something went wrong',
        404: 'Oops!',
        403: 'Oops!'
    }[props.status]
})

const message = computed(() => {
    return {
        503: 'We\'re running some routine maintenance. We\'ll be right back',
        500: 'Try refreshing, or contact us if the issue persists.',
        404: 'We couldn\'t find the page you\'re looking for',
        403: 'We couldn\'t find the page you\'re looking for'
    }[props.status]
})

const emotion = ref('confused2')
const bounceClass = ref(null)

function swapEmotion () {
    emotion.value = emotion.value === 'confused2' ? 'confused1' : 'confused2'
    bounceClass.value = 'scale-[105%]'
    setTimeout(() => {
        bounceClass.value = null
    }, 200)
}
</script>

<template>
    <div class="mx-auto w-full max-w-6xl px-6 py-24 lg:px-8">
        <div class="flex items-center">
            <div class="flex w-1/2 justify-end pr-12">
                <Novus
                    :emotion
                    class="w-80 transition-all"
                    :class="bounceClass"
                    @click="swapEmotion" />
            </div>
            <div class="w-1/2">
                <p class="text-base font-semibold leading-8 text-primary dark:text-white">
                    {{ status }}
                </p>
                <h1 class="text-4xl font-semibold font-heading sm:text-7xl md:mt-2">
                    {{ title }}
                </h1>
                <p class="mt-1 max-w-xs text-base text-slate-600 text-pretty dark:text-slate-400 sm:text-xl md:mt-6">
                    {{ message }}
                </p>
            </div>
        </div>
    </div>
</template>
