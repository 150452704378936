<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import PostSingle from '@/Components/Post/PostSingle.vue'
import Breadcrumbs from '@/Components/UI/Breadcrumbs.vue'
import { router } from '@inertiajs/vue3'
import { useDateFormat } from '@vueuse/core'
import { computed, type PropType } from 'vue'
import type { Post } from '@/Types/Models/Post'
import { useRoute } from '@/Composables/useRoute.ts'
import { ChevronLeftIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    post: Object as PropType<Post>,
    comments: Array as PropType<Comment[]>
})

function reload () {
    router.reload()
}

const breadcrumbTitle = computed(() => {
    return useDateFormat(props.post.created_at, 'MMMM D, YYYY')
})

function deleted () {
    router.get(useRoute('user.show.posts', props.post.user))
}
</script>

<template>
    <div class="mx-auto my-8 w-full max-w-4xl px-4 md:px-24">
        <Breadcrumbs
            :breadcrumbs="[
                { title: 'Home', url: useRoute('home') },
                { title: post.user.name, url: useRoute('user.show', { user: post.user.username }) },
                { title: 'Posts', url: useRoute('user.show.posts', { user: post.user.username }) },
                { title: breadcrumbTitle }
            ]" />

        <PostSingle
            class="mt-4"
            :post="post"
            :show-pinned="false"
            :show-comments="true"
            :comments="comments"
            @deleted="deleted"
            @reload="reload" />
    </div>
</template>
