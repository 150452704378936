<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import ArticleCta from '@/Components/Article/ArticleCta.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import ArticleCard from '@/Components/Article/ArticleCard.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import ArticleCardHorizontal from '@/Components/Article/ArticleCardHorizontal.vue'
// @ts-ignore
import { debounce } from 'lodash'
import { useScroll } from '@vueuse/core'
import { computed, type PropType, ref } from 'vue'
import type { Article } from '@/Types/Models/Article'
import { usePageProps } from '@/Composables/usePageProps.ts'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { useHasPagination } from '@/Composables/useHasPagination.ts'

const props = defineProps({
    articles: [Object as PropType<Article[]>, Object as PropType<PaginatedItems<Article>>],
    icon: Object,
    pagination: {
        type: Boolean,
        default: true
    },
    paginationOptions: {
        type: Object,
        default: () => ({
            preserveScroll: false,
            only: null
        })
    },
    contain: {
        type: Boolean,
        default: false
    },
    skeletonCount: {
        type: Number,
        default: 4
    },
    horizontal: {
        type: Boolean,
        default: false
    },
    includeWritingCta: {
        type: Boolean,
        default: false
    },
    writingCtaText: {
        type: String,
        default: 'Write your own article'
    },
    articleWidth: {
        type: String,
        default: 'md:w-1/2 lg:w-1/3 xl:w-1/4'
    }
    // slider: {
    //     type: Boolean,
    //     default: false
    // } TODO: WORK IN PROGRESS...
})

const hasPagination = computed(() => props.articles && Object.hasOwn(props.articles, 'links'))
const allArticles = computed(() => {
    const data = hasPagination.value ? props.articles.data : props.articles
    return data ?? []
})

const containerClasses = computed(() => {
    return {
        'flex-col -mx-2.5': props.horizontal,
        'flex-wrap -mx-2.5': true
        // 'overflow-x-auto -mx-4 snap-x max-w-full': props.slider
    }
})
const articleClasses = computed(() => {
    return {
        'p-2.5': true,
        'mb-6': !props.horizontal,
        // 'min-w-72 md:shrink-0 snap-start': props.slider,
        [props.articleWidth]: !props.horizontal
    }
})

const articleContainer = ref<HTMLElement | null>(null)
const articleCard = ref<HTMLElement | null>(null)
const { x, arrivedState } = useScroll(articleContainer, { behavior: 'smooth' })

function next () {
    const articleCardWidth = articleCard.value[0]?.offsetWidth
    x.value += articleCardWidth
}

function prev () {
    const articleCardWidth = articleCard.value[0]?.offsetWidth
    x.value -= articleCardWidth
}
</script>

<template>
    <section>
        <ArticleSkeletonGrid
            v-if="!articles && skeletonCount > 0"
            :horizontal="horizontal"
            :contain="contain"
            :article-width="articleWidth"
            :number="skeletonCount" />
        <div v-else>
            <div
                v-if="!horizontal"
                :class="[contain ? 'container' : '', 'relative']">
                <!--                <Button-->
                <!--                    v-if="slider"-->
                <!--                    class="absolute top-28 right-2 z-50 shadow-md md:right-0 md:translate-x-1/2"-->
                <!--                    variant="primary"-->
                <!--                    rounded-->
                <!--                    icon-only-->
                <!--                    :disabled="arrivedState.right"-->
                <!--                    :icon="ChevronRightIcon"-->
                <!--                    @click="next()" />-->
                <!--                <Button-->
                <!--                    v-if="slider"-->
                <!--                    class="absolute top-28 left-2 z-50 shadow-md md:left-0 md:-translate-x-1/2"-->
                <!--                    variant="primary"-->
                <!--                    rounded-->
                <!--                    icon-only-->
                <!--                    :disabled="arrivedState.left"-->
                <!--                    :icon="ChevronLeftIcon"-->
                <!--                    @click="prev()" />-->
                <div
                    v-if="allArticles.length"
                    ref="articleContainer"
                    :class="[containerClasses]"
                    class="mt-0 flex items-stretch">
                    <div
                        v-for="article in allArticles"
                        :key="article.uuid"
                        ref="articleCard"
                        class="flex items-stretch"
                        :class="articleClasses">
                        <ArticleCard
                            :article="article"
                        />
                    </div>

                    <div
                        v-if="includeWritingCta"
                        class="hidden md:flex"
                        :class="articleClasses">
                        <ArticleCta
                            :text="writingCtaText" />
                    </div>
                </div>
                <slot
                    v-if="allArticles.length === 0"
                    name="empty" />

                <Pagination
                    v-if="pagination && useHasPagination(articles)"
                    :preserve-scroll="paginationOptions.preserveScroll"
                    :only="paginationOptions.only"
                    :data="articles" />
            </div>

            <div
                v-if="horizontal"
                :class="{'container': contain}">
                <div
                    v-if="allArticles.length"
                    :class="[containerClasses, 'article-link-scroller']"
                    class="mt-0 flex items-stretch">
                    <div
                        v-for="article in allArticles"
                        :key="article.uuid"
                        :class="articleClasses"
                        class="p-2.5">
                        <ArticleCardHorizontal
                            :article="article" />
                    </div>
                </div>
                <slot
                    v-if="allArticles.length === 0"
                    name="empty" />

                <Pagination
                    v-if="pagination && useHasPagination(articles)"
                    :preserve-scroll="paginationOptions.preserveScroll"
                    :only="paginationOptions.only"
                    :data="articles" />
            </div>
        </div>
    </section>
</template>
