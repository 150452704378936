<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import StepList from '@/Components/UI/StepList.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import { computed, ref } from 'vue'
import { router } from '@inertiajs/vue3'
import { CheckIcon } from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useUpdateSetting } from '@/Composables/useUpdateSetting'

const props = defineProps({
    form: Object
})

const updateSetting = useUpdateSetting()
const showHelper = ref(!usePageProps().auth.settings.article_helper_closed)

function closeHelper (): void {
    showHelper.value = false
    updateSetting('article_helper_closed', true, () => {
        router.reload({
            // @ts-ignore
            preserveScroll: true,
            only: ['auth']
        })
    })
}

const steps = computed(() => [
    {
        name: 'Title',
        description: 'Add a catchy title to grab the reader\'s attention.',
        checked: props.form.title.length > 10
    },
    {
        name: 'Featured Image',
        description: 'Add a featured image to make your article stand out.',
        checked: props.form.featured_image !== null
    },
    {
        name: 'Content',
        description: 'Keep the content concise, direct, and relevant. ',
        checked: props.form.content.length > 10
    },
    {
        name: 'Tags',
        description: 'Add tags to your article to make it easier to discover.',
        checked: props.form.tags.length > 0
    },
    {
        name: 'Game',
        description: 'Assign a game to your article for extra context.',
        checked: props.form.metadata.game_identifier !== null
    }
])
</script>

<template>
    <Card
        v-if="showHelper"
        class="relative p-6 pb-2 md:p-6 md:pb-2">
        <button
            class="absolute top-4 right-4 opacity-30 transition-all hover:opacity-100 xs:top-5 xs:right-6"
            @click="closeHelper">
            <XMarkIcon class="w-5" />
        </button>
        <SectionTitle
            font-size="text-xl"
            class="-mt-2">
            <div class="flex items-center">
                Keys to a good article
            </div>
        </SectionTitle>
        <StepList
            :steps="steps"
            class="-mx-4 mt-3.5" />
    </Card>
</template>
