<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useUploadFile } from '@/Composables/useUploadFile.js'
import { ArrowUturnLeftIcon } from '@heroicons/vue/24/outline'
import { Cropper, CircleStencil, RectangleStencil } from 'vue-advanced-cropper'

const props = defineProps({
    removable: {
        type: Boolean,
        default: false
    },
    selectedFile: {
        type: Object || null,
        default: null
    },
    displayedImage: {
        type: String || null,
        default: null
    },
    allowCropping: {
        type: Boolean,
        default: true
    },
    croppingStyle: {
        type: String,
        default: 'rectangle'
    },
    allowedTypes: {
        type: Array,
        default: () => ['image/png', 'image/jpg', 'image/gif', 'image/jpeg']
    }
})

const emit = defineEmits(['removed', 'uploaded', 'selected', 'uploading'])

const previews = ref([])
const fileUrls = ref([])
const fileInput = ref(null)
const allUploaded = ref(false)
const fileHolder = {
    file: []
}

const errors = ref({})
const loading = ref([])

function addFile (e, files = null) {
    // Start uploading process
    emit('uploading')

    // If there aren't any existing files, get from the file input
    if (!files) {
        files = fileInput.value.files
    }

    // Strip to one if multiple are somehow added
    files = [files[0]]

    // Run for each file
    for (let i = 0; i < files.length; i++) {
        const file = files[i]

        if (file) {
            // Check is valid type
            if (!props.allowedTypes.includes(file?.type)) {
                useToast('Filetype is not valid', 'fail')
            }

            // If it is valid
            if (props.allowedTypes.includes(file.type)) {
                // Add or replace the fileholder, ready for upload
                fileHolder.file = [file]

                // Generate the preview URL
                const url = window.URL.createObjectURL(file)

                // Add to loading array
                loading.value.push(url)

                // Add to or replace the previews
                previews.value = [url]
            }
        }
    }

    // Clear the file input
    fileInput.value.type = 'text'
    fileInput.value.type = 'file'

    // Emit the array of preview urls
    emit('selected', previews.value)

    // Start the upload
    uploadToServer()
}

function uploadToServer () {
    // Clear everything
    errors.value = null
    fileUrls.value = []
    fileInput.value.type = 'text'
    fileInput.value.type = 'file'

    if (fileHolder.file.length > 0 && props.allowCropping) {
        openCropModal()
    }

    // fileHolder.file contains each file
    // Loop through each file
    for (let i = 0; i < fileHolder.file.length; i++) {
        // Set a temporary file
        const tempFile = loading.value[i]

        // Send file to server
        useUploadFile(useRoute('api.uploads.process'), {
            user: usePageProps().auth.user.id,
            file: fileHolder.file[i]
        }).then(results => {
            if (results.errors) {
                // Display errors if something went wrong
                errors.value = results.errors
            } else {
                // Replace the final file URLs
                fileUrls.value = [results.file]

                // Determine if "All uploaded" by comparing the length of the final URLs to the fileholder
                allUploaded.value = fileUrls.value.length === fileHolder.file.length

                // Check where the file is in the loading array
                const loadingIndex = loading.value.indexOf(tempFile)
                loading.value.splice(loadingIndex, 1)
            }
        })
    }

    // Start all over again
    allUploaded.value = false
}

watch(allUploaded, async (uploaded) => {
    // if all files have been uploaded completely, emit the URLs
    if (uploaded === true) {
        emit('uploaded', fileUrls.value)
    }
})

// Start selecting a file
function openFileBrowser () {
    fileInput.value.click()
}

// Remove a file from the list
function remove (index) {
    if (index !== -1) {
        croppedImage.value = null
        // Remove from the previews and the fileholder
        previews.value.splice(index, 1)
        fileHolder.file.splice(index, 1)

        // Delete from the actual server
        // NO LONGER NEED TO DO THIS AS FILES ARE STORED IN TMP
        // useMakeRequest(useRoute('api.uploads.delete'), 'DELETE', {
        //     file: fileUrls.value[index]
        // })

        // Reset all file URLs
        fileUrls.value = []

        // Clear the file input
        if (fileInput.value) {
            fileInput.value.type = 'text'
            fileInput.value.type = 'file'
        }

        // Emit which file has been removed
        emit('removed', index)
    }
}

const cropModalOpen = ref(false)

function openCropModal () {
    cropModalOpen.value = true
}

const croppedImage = ref(null)

function saveCrop () {
    croppedImage.value.toBlob((blob) => {
        const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' })
        addFile(null, [file])
        cropModalOpen.value = false
    }, 'image/jpeg')
}

function skipCropping () {
    cropModalOpen.value = false
}

function change ({
    coordinates,
    canvas
}) {
    croppedImage.value = canvas
}

// Clear everything and start fresh
function clear () {
    previews.value = []
    fileUrls.value = []
    if (fileInput.value) {
        fileInput.value.type = 'text'
        fileInput.value.type = 'file'
    }

    emit('removed', 1)
}

onMounted(() => {
    // Check if files have already been added and add them to previews
    if (props.selectedFile) {
        addFile(props.selectedFile.event, props.selectedFile.file)
    } else {
        previews.value = []
    }

    // Clear all input when this is emitted globally
    useEmitter.on('clearFileInputs', clear)
})
</script>

<template>
    <div>
        <Modal
            v-if="allowCropping"
            :show="cropModalOpen"
            @close="cropModalOpen = false">
            <template #content>
                <div class="w-full">
                    <Cropper
                        v-if="croppingStyle === 'circle'"
                        :src="displayedImage"
                        :stencil-component="CircleStencil"
                        @change="change" />
                    <Cropper
                        v-if="croppingStyle === 'rectangle'"
                        :src="displayedImage"
                        :canvas="{
                            maxHeight: 1200,
                        }"
                        :stencil-component="RectangleStencil"
                        @change="change" />
                </div>
            </template>
            <template #footer>
                <div class="flex w-full flex-row items-center justify-end gap-4">
                    <div class="flex w-full text-center xs:w-4/12">
                        <Button
                            variant="white"
                            @click="skipCropping">
                            Skip cropping
                        </Button>
                    </div>
                    <div class="flex w-full xs:justify-end">
                        <Button
                            type="button"
                            @click="saveCrop">
                            Save crop
                        </Button>
                    </div>
                </div>
            </template>
        </Modal>

        <div v-if="errors">
            {{ errors.file }}
        </div>

        <input
            ref="fileInput"
            :accept="allowedTypes.join(',')"
            class="hidden"
            type="file"
            @change="addFile">

        <button
            class="flex w-full items-center"
            type="button"
            @click="openFileBrowser">
            <slot name="trigger" />
        </button>

        <slot name="deleteTrigger" />

        <button
            v-if="removable"
            v-tooltip="'Reset'"
            class="absolute top-4 right-4 flex aspect-square w-8 items-center justify-center rounded-full bg-black/50 text-white transition-colors hover:bg-black"
            type="button"
            @click="remove(1)">
            <ArrowUturnLeftIcon class="h-4 w-4 text-white" />
        </button>
    </div>
</template>
