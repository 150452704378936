<script setup>
import Button from '@/Components/UI/Button.vue'
import Separator from '@/Components/UI/Separator.vue'
import InputText from '@/Components/Input/InputText.vue'
import SocialAuth from '@/Components/Auth/SocialAuth.vue'
import InputCheckbox from '@/Components/Input/InputCheckbox.vue'
import { Link, useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { ArrowRightIcon } from '@heroicons/vue/24/solid'
import { useCurrentUrl } from '@/Composables/useCurrentUrl.js'

const form = useForm({
    email: null,
    password: null,
    remember: true
})

// const emit = defineEmits(['success'])

function submit () {
    form.post(useRoute('login', { redirect: useCurrentUrl() }), {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            // emit('success')
            // router.reload()
            window.location.reload()
        }
    })
}
</script>

<template>
    <div class="w-full">
        <form @submit.prevent="submit">
            <div class="flex flex-col gap-4">
                <InputText
                    v-model="form.email"
                    :error="form.errors.email"
                    autocomplete="username"
                    label="Username or email"
                    @input="form.clearErrors('email')" />
                <InputText
                    v-model="form.password"
                    :error="form.errors.password"
                    autocomplete="current-password"
                    input-type="password"
                    label="Password"
                    @input="form.clearErrors('password')" />
            </div>

            <div class="mt-4 flex items-center justify-between">
                <div>
                    <InputCheckbox
                        v-model="form.remember"
                        label="Remember me" />
                </div>
                <div>
                    <Link
                        :href="useRoute('password.request')"
                        class="text-sm text-slate-900/75 hover:underline dark:text-white/75">
                        Forgot password
                    </Link>
                </div>
            </div>

            <div class="mt-4 flex w-full">
                <Button
                    :disabled="form.processing"
                    class="w-full"
                    type="submit"
                    :icon="ArrowRightIcon"
                    variant="primary">
                    Log in
                </Button>
            </div>
        </form>
        <Separator>
            or
        </Separator>
        <SocialAuth />
    </div>
</template>
