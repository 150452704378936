<script setup lang="ts">
import TagCard from '@/Components/Tag/TagCard.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import TagCardSkeletonGrid from '@/Components/Tag/TagCardSkeletonGrid.vue'
import { computed, type PropType } from 'vue'
import type { Tag } from '@/Types/Models/Tag'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { useHasPagination } from '@/Composables/useHasPagination.ts'

const props = defineProps({
    tags: [Object as PropType<Tag[]>, Object as PropType<PaginatedItems<Tag>>],
    pagination: {
        type: Boolean,
        default: true
    },
    paginationOptions: {
        type: Object,
        default: () => ({
            preserveScroll: false,
            only: null
        })
    },
    contain: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    skeletonCount: {
        type: Number,
        default: 4
    },
    horizontal: {
        type: Boolean,
        default: false
    },
    tagWidth: {
        type: String,
        default: 'w-full md:w-1/2 lg:w-1/3'
    }
})

const hasPagination = computed(() => props.tags && Object.hasOwn(props.tags, 'links'))
const allTags = computed(() => {
    const data = hasPagination.value ? props.tags.data : props.tags
    return data ?? []
})

const containerClasses = computed(() => {
    return {
        'flex-col -mx-2.5': props.horizontal,
        'flex-wrap -mx-2.5': true
    }
})

const tagClasses = computed(() => {
    return {
        'p-2.5': true,
        'mb-0': !props.horizontal,
        [props.tagWidth]: !props.horizontal
    }
})
</script>

<template>
    <section>
        <TagCardSkeletonGrid
            v-if="!tags && skeletonCount > 0"
            :horizontal="horizontal"
            :contain="contain"
            :number="skeletonCount" />
        <div v-else>
            <div
                :class="[contain ? 'container' : '']">
                <div
                    v-if="allTags.length"
                    :class="[containerClasses]"
                    class="mt-0 flex items-stretch">
                    <div
                        v-for="tag in allTags"
                        :key="tag.slug"
                        :class="tagClasses">
                        <TagCard
                            :small="small"
                            :tag
                        />
                    </div>
                </div>
                <slot
                    v-if="allTags.length === 0"
                    name="empty" />

                <Pagination
                    v-if="pagination && useHasPagination(tags)"
                    :preserve-scroll="paginationOptions.preserveScroll"
                    :only="paginationOptions.only"
                    :data="tags" />
            </div>
        </div>
    </section>
</template>
