<script setup>
import VerifiedBadge from '@/Components/User/VerifiedBadge.vue'
import { useMemoize } from '@vueuse/core'

const props = defineProps({
    user: Object || null,
    showVerified: {
        type: Boolean,
        default: false
    },
    roundedClass: {
        type: String,
        default: 'rounded-full'
    },
    size: {
        type: String,
        default: 'medium'
    },
    type: {
        type: String,
        default: 'webp'
    },
    imageSize: {
        type: Number,
        default: 32
    }
})

function getTimestamp () {
    const now = new Date()
    const minutes = now.getMinutes()
    const roundedMinutes = minutes - (minutes % 2)

    now.setMinutes(roundedMinutes, 0, 0)
    return now.getTime()
}

const getImage = useMemoize(() => {
    const { avatars } = props.user
    const key = `${props.size}_${props.type}`

    if (!avatars[key]) {
        return avatars.original
    }

    const avatar = `${avatars[key]}?v=${getTimestamp()}`

    return avatar || avatars.original
}, [props.user, props.size, props.type])
</script>

<template>
    <div class="relative">
        <VerifiedBadge
            v-if="showVerified"
            :user="user"
            class="absolute top-0 right-0 w-8" />
        <div
            class="aspect-square overflow-hidden bg-pink-light"
            :class="roundedClass">
            <img
                v-if="user"
                :alt="`Avatar for user: ${user.name}`"
                :height="imageSize"
                itemprop="image"
                :src="getImage()"
                :width="imageSize"
                class="h-full w-full object-cover object-center"
                loading="lazy">
        </div>
    </div>
</template>
