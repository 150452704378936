<script setup lang="ts">
import patternImage from '~/app/patterns/logo.svg'
import type { PropType } from 'vue'
import type { ImageUrls } from '@/Types/ImageUrls'

defineProps({
    screenshots: Array as PropType<ImageUrls[]>
})

const emit = defineEmits(['screenshotClick'])
</script>

<template>
    <div
        v-if="screenshots && screenshots.length"
        class="mt-2">
        <ul class="relative mt-1 flex w-full gap-4 p-1 pb-2 lg:overflow-x-auto">
            <li
                v-for="(screenshot, index) in screenshots.slice(0, 3)"
                :key="screenshot.uuid"
                :style="`background-image:url('${patternImage}')`"
                class="w-4/12 cursor-pointer overflow-hidden rounded-lg bg-center ring-1 ring-white/50 transition-all aspect-[16/10] group hover:ring-white lg:w-3/12 bg-[length:30px]"
                @click="emit('screenshotClick', index)">
                <img
                    v-lazy="screenshot.small_webp"
                    :alt="`Screenshot #${index + 1}`"
                    class="h-full w-full object-cover object-center">
            </li>
        </ul>
    </div>
</template>
