<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import StepList from '@/Components/UI/StepList.vue'
import NumberStat from '@/Components/UI/NumberStat.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import ArticleCta from '@/Components/Article/ArticleCta.vue'
import ArticleCard from '@/Components/Article/ArticleCard.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import type { User } from '@/Types/Models/User'
import { type PropType, reactive, ref } from 'vue'
import { Deferred, router } from '@inertiajs/vue3'
import { CheckIcon } from '@heroicons/vue/20/solid'
import type { Article } from '@/Types/Models/Article'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useUpdateSetting } from '@/Composables/useUpdateSetting'

defineProps({
    user: Object as PropType<User>,
    steps: Array as PropType<Array<{ name: string, description: string, checked: boolean }>>,
    topArticles: Array as PropType<Array<Article>>,
    articlesCount: Number,
    commentsCount: Number,
    viewsCount: Number,
    followersCount: Number
})

const updateSetting = useUpdateSetting()
const displayGetStarted = ref(!usePageProps().auth.settings.dashboard_getting_started_closed)

function closeGetStarted () {
    displayGetStarted.value = false
    updateSetting('dashboard_getting_started_closed', true)
}

defineOptions({ layout: DashboardLayout })
</script>
<template>
    <div class="pb-12">
        <DashboardTitle
            :title="`Hi ${user.name}`"
            description="Welcome to your dashboard." />

        <div class="flex flex-col-reverse items-start md:flex-row">
            <div class="flex w-full flex-col">
                <div
                    class="flex w-full flex-wrap items-start overflow-hidden text-slate-900 dark:text-white md:flex-nowrap">
                    <Deferred :data="['articlesCount', 'commentsCount', 'followersCount', 'viewsCount']">
                        <template #fallback>
                            <NumberStat
                                :number="0"
                                title="Published articles" />
                            <NumberStat
                                :number="0"
                                title="Total comments" />
                            <NumberStat
                                :number="0"
                                title="Total followers" />
                            <NumberStat
                                :number="0"
                                title="Article views" />
                        </template>

                        <NumberStat
                            :number="articlesCount"
                            title="Published articles" />
                        <NumberStat
                            :number="commentsCount"
                            title="Total comments" />
                        <NumberStat
                            :number="followersCount"
                            title="Total followers" />
                        <NumberStat
                            :number="viewsCount"
                            title="Article views" />
                    </Deferred>
                </div>

                <div class="mt-12">
                    <SectionTitle>
                        Top articles
                    </SectionTitle>
                    <SectionDescription>
                        Your most popular articles based on views, likes, and engagement.
                    </SectionDescription>
                    <div class="flex w-full flex-wrap">
                        <div
                            class="-mx-4 flex w-full flex-wrap">
                            <Deferred data="topArticles">
                                <template #fallback>
                                    <div class="w-full">
                                        <ArticleSkeletonGrid
                                            :article-width="displayGetStarted ? 'sm:w-1/2 lg:w-1/3' : 'sm:w-1/2 lg:w-1/4'"
                                            :number="3" />
                                    </div>
                                </template>

                                <div
                                    v-for="article in topArticles"
                                    :key="article.uuid"
                                    :class="displayGetStarted ? 'sm:w-1/2 lg:w-1/3' : 'sm:w-1/2 lg:w-1/4'"
                                    class="flex w-full p-4">
                                    <ArticleCard
                                        :article="article"
                                        class="w-full" />
                                </div>
                                <div
                                    :class="displayGetStarted ? 'sm:w-1/2 lg:w-1/3' : 'sm:w-1/2 lg:w-1/4'"
                                    class="flex w-full p-4">
                                    <ArticleCta
                                        :text="(topArticles && topArticles.length) > 0 ? 'Get started on a new article' : 'Create your first article today'" />
                                </div>
                            </Deferred>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="displayGetStarted"
                class="w-full shrink-0 md:w-1/3">
                <Card class="relative p-6 pb-2 md:p-6 md:pb-2 overflow-hidden">
                    <button
                        class="absolute top-4 right-4 hidden opacity-30 transition-all hover:opacity-100 xs:top-5 xs:right-6 sm:flex"
                        @click="closeGetStarted">
                        <XMarkIcon class="w-5" />
                    </button>
                    <SectionTitle>
                        Getting started
                    </SectionTitle>
                    <div>
                        <p>
                            Welcome to {{ usePageProps().app.name }}
                        </p>

                        <Deferred data="steps">
                            <template #fallback>
                                <div class="mt-4 -mx-4">
                                    <div class="flex flex-col skeleton-animation">
                                        <div class="w-full h-36 bg-primary/5 rounded-lg" />
                                    </div>
                                </div>
                            </template>
                            <StepList
                                :steps="steps"
                                class="-mx-4 mt-4" />

                            <div
                                v-if="steps.filter(step => step.checked).length === steps.length"
                                class="absolute inset-0 bg-white/50 rounded-xl flex flex-col px-8 items-center justify-center backdrop-blur-md">
                                <SectionTitle>
                                    You're all set!
                                </SectionTitle>
                                <SectionDescription class="text-center">
                                    You've completed all the steps. <br>You're a {{ usePageProps().app.name }} pro!
                                </SectionDescription>
                                <Button
                                    class="mt-4"
                                    small
                                    icon-first
                                    :icon="XMarkIcon"
                                    variant="white"
                                    @click="closeGetStarted">
                                    Close
                                </Button>
                            </div>
                        </Deferred>
                    </div>
                </Card>
            </div>
        </div>
    </div>
</template>
