<script lang="ts" setup>
import Card from '@/Components/UI/Card.vue'
import InputText from '@/Components/Input/InputText.vue'
import InputCheckbox from '@/Components/Input/InputCheckbox.vue'
import { computed, type PropType, ref } from 'vue'
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline'

type Option = {
    id: number
    name: string
}

const props = defineProps({
    options: Array as PropType<Option[]>
})

const searchValue = ref('')

const displayOptions = computed(() => {
    if (!searchValue.value) {
        return props.options
    }

    return props.options.filter(option => option.name.toLowerCase().includes(searchValue.value.toLowerCase()))
})

const selected = defineModel('selected', {
    type: Array as PropType<number[]>,
    default: () => []
})
</script>

<template>
    <Card
        border
        class="flex flex-col gap-3 overflow-hidden">
        <div class="flex flex-col">
            <div class="-mx-4 -mt-4 md:-mx-5">
                <InputText
                    v-model="searchValue"
                    placeholder="Search platforms..."
                    inline
                    input-classes="w-full border-b"
                    clearable>
                    <template #icon>
                        <MagnifyingGlassIcon class="w-4" />
                    </template>
                </InputText>
            </div>
            <ul class="-mx-1 -mb-4 flex max-h-72 flex-col gap-2 overflow-auto py-2">
                <li
                    v-for="option in displayOptions"
                    :key="option.id">
                    <InputCheckbox
                        v-model="selected"
                        size="size-5"
                        :label="option.name"
                        :value="option.id"
                        class="w-full" />
                </li>
            </ul>
        </div>
    </Card>
</template>
