<script lang="ts" setup>
import Logo from '@/Components/Logo'
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import GamePad from '@/Components/Icon/GamePad.vue'
import Compass from '@/Components/Icon/Compass.vue'
import CategoryIconMap from '@/Configs/CategoryIconMap.ts'
import SearchPalette from '@/Components/Global/SearchPalette.vue'
import UserDropdown from '@/Components/Navigation/UserDropdown.vue'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import NotificationsLink from '@/Components/Navigation/NotificationsLink.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import NavigationMobileNav from '@/Components/Navigation/NavigationMobileNav.vue'
import NavigationNavBarItem from '@/Components/Navigation/NavigationNavBarItem.vue'
import { Link } from '@inertiajs/vue3'
import type { NavItem } from '@/Types/NavItem'
import { useRoute } from '@/Composables/useRoute.ts'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import type { Category } from '@/Types/Models/Category'
import { usePageProps } from '@/Composables/usePageProps'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'
import {
    CalendarIcon,
    UserCircleIcon
} from '@heroicons/vue/24/solid'
import {
    FireIcon, DocumentTextIcon, ArrowUpRightIcon, MagnifyingGlassIcon, Bars3BottomLeftIcon, XMarkIcon
} from '@heroicons/vue/24/outline'

const navOpen = ref<boolean>(false)
const scrolled = ref<boolean>(false)

const scrollPosition = ref<number>(0)

const breakpoints = useBreakpoints(breakpointsTailwind)
const mobileOnly = breakpoints.smallerOrEqual('md')

const categories = usePageProps().app.categories.map((category: Category) => {
    return {
        title: category.title,
        url: useRoute('articles.categorised', category),
        icon: CategoryIconMap[category.title]
    }
})

function primaryNav (includeMobile = false): NavItem[] {
    const items: NavItem[] = [
        {
            title: 'Discover',
            url: null,
            icon: Compass,
            children: [
                {
                    title: 'Latest',
                    url: useRoute('articles.index'),
                    icon: FireIcon
                },
                {
                    title: 'On this day',
                    url: useRoute('articles.on-this-day'),
                    icon: CalendarIcon
                },
                ...categories
            ]
        },
        // {
        //     title: 'Trending',
        //     url: useRoute('articles.trending'),
        //     icon: ArrowTrendingUpIcon,
        //     if: usePageProps().totalTrendingArticleCount > 12
        // },
        {
            title: 'Games',
            url: useRoute('games.index'),
            icon: GamePad
        }
    ]

    if (includeMobile) {
        items.unshift({
            title: 'Home',
            url: useRoute('home'),
            icon: null
        })
    }

    return items
}

function canShowItem (item: NavItem): boolean {
    let canShow = (item.if !== undefined ? item.if : true)

    if (item.permission !== undefined && canShow) {
        canShow = item.permission === true
    }

    return canShow
}

const searchOpen = ref<boolean>(false)

function closeSearch () {
    searchOpen.value = false
}

function openSearch () {
    searchOpen.value = true

    useAnalyticsEvent('Open search palette')
}

const scrollListener = () => {
    scrollPosition.value = window.scrollY
    scrolled.value = scrollPosition.value > 0
}

onMounted(() => {
    window.addEventListener('scroll', scrollListener)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', scrollListener)
})

// const { isVisible } = useNavbarScroll()
</script>

<template>
    <nav
        id="siteNav"
        :class="[
            scrolled ? 'shadow-sm shadow-black/10 md:border-transparent' : 'shadow-none dark:md:border-white/10 md:border-primary/10',
        ]"
        class="sticky top-0 z-50 flex h-14 w-full border-b border-transparent bg-white text-base transition-all dark:bg-slate-900 dark:text-white md:h-14 md:justify-center">
        <NavigationMobileNav
            v-if="mobileOnly"
            :menu="primaryNav(true)"
            :open="navOpen"
            @close="navOpen = false" />

        <button
            v-if="mobileOnly"
            :class="navOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'"
            class="absolute left-0 top-14 z-0 h-[calc(100dvh-3.5rem)] w-full bg-black/50 transition-all duration-500"
            @click="navOpen = false">
            <span class="sr-only">Close nav</span>
        </button>
        <div class="mx-auto flex w-full flex-wrap items-center px-5 md:justify-between">
            <div class="mr-2 flex w-1/12 md:hidden">
                <button
                    class="rounded-full text-slate-900/75 ring-0 ring-primary/30 xxs:-mt-0.5 focus:text-primary focus:ring-2 dark:text-white/75"
                    @click="navOpen = !navOpen">
                    <span class="sr-only">Toggle mobile nav</span>
                    <Bars3BottomLeftIcon
                        v-if="!navOpen"
                        class="w-6" />
                    <XMarkIcon
                        v-else
                        class="w-6" />
                </button>
            </div>
            <div
                class="flex h-full w-5/12 items-center">
                <Link
                    :href="useRoute('home')"
                    prefetch>
                    <span class="sr-only">Link to home</span>
                    <Logo.FullColour
                        class="xxs:flex hidden w-44 sm:w-40 lg:w-48"
                    />
                    <Logo.MarkColour
                        class="flex xxs:hidden w-8"
                    />
                </Link>
                <div class="ml-4 hidden h-full items-center justify-center md:flex">
                    <ul class="flex h-full flex-row items-center gap-1">
                        <template v-for="(item, i) in primaryNav()">
                            <li
                                v-if="(typeof item.permission === 'undefined' || item.permission === true) && canShowItem(item)"
                                :key="i">
                                <NavigationNavBarItem :item="item" />
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
            <ul class="ml-auto flex w-1/3 items-center justify-end gap-2 md:gap-2.5 md:w-6/12">
                <li
                    v-if="useIsAuthed()"
                    class="hidden md:flex">
                    <ArticleCreateButton
                        text="New article"
                        :text-desktop-only="true"
                        variant="primary" />
                </li>
                <li>
                    <Button
                        variant="white"
                        :icon="MagnifyingGlassIcon"
                        icon-only="mobile"
                        icon-first
                        @click="openSearch">
                        <span class="hidden pr-12 md:flex">
                            Search
                        </span>
                    </Button>
                </li>
                <li v-if="useIsAuthed()">
                    <NotificationsLink />
                </li>
                <li v-if="!useIsAuthed() && mobileOnly">
                    <AuthModalTrigger>
                        <template #trigger>
                            <Button
                                icon-only
                                :icon="UserCircleIcon"
                                variant="white" />
                        </template>
                    </AuthModalTrigger>
                </li>

                <li v-if="!useIsAuthed() && !mobileOnly">
                    <AuthModalTrigger>
                        <template #trigger>
                            <Button
                                class="flex"
                                variant="white"
                                :icon="ArrowUpRightIcon">
                                Log in
                            </Button>
                        </template>
                    </AuthModalTrigger>
                </li>
                <li v-if="!useIsAuthed() && !mobileOnly">
                    <AuthModalTrigger default-form="register">
                        <template #trigger>
                            <Button
                                class="flex"
                                :icon="UserCircleIcon">
                                Sign up
                            </Button>
                        </template>
                    </AuthModalTrigger>
                </li>
                <li v-if="useIsAuthed()">
                    <UserDropdown />
                </li>
            </ul>
        </div>
    </nav>
    <SearchPalette
        :show="searchOpen"
        @open="openSearch"
        @close="closeSearch" />
</template>
