<script setup>
import 'tinymce/skins/ui/oxide/skin.js'
import 'tinymce/models/dom/model.min.js'
import 'tinymce/skins/ui/oxide/content.js'
import 'tinymce/icons/default/icons.min.js'
import 'tinymce/themes/silver/theme.min.js'
import 'tinymce/skins/content/default/content.js'
import Editor from '@tinymce/tinymce-vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'

const imageHandler = (blobInfo, progress) => new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = false
    xhr.open('POST', useRoute('api.uploads.process'))

    xhr.upload.onprogress = (e) => {
        progress(e.loaded / e.total * 100)
    }

    xhr.onload = () => {
        if (xhr.status === 403) {
            reject({
                message: 'HTTP Error: ' + xhr.status,
                remove: true
            })
            return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
            reject('HTTP Error: ' + xhr.status)
            return
        }

        const json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
            reject('Invalid JSON: ' + xhr.responseText)
            return
        }

        resolve(json.location)
    }

    xhr.onerror = () => {
        reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status)
    }

    const formData = new FormData()

    formData.append('model', 'App\\Models\\Article')
    formData.append('model_id', usePageProps().article.uuid)
    formData.append('image_size', 'full_webp')
    formData.append('public', 'true')
    formData.append('key', 'location')
    formData.append('directory', 'public/articles')
    formData.append('image', blobInfo.blob(), blobInfo.filename())

    xhr.send(formData)
})
</script>

<template>
    <Editor
        :init="{
            promote: false,
            branding: false,
            theme: 'modern',
            skin: 'Novogamer',
            license_key: 'gpl',
            placeholder: 'Get started...',
            menubar: false,
            lists_indent_on_tab: true,
            toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify table | bullist numlist | emoticons accordion link image media',
            images_upload_handler: imageHandler,
            plugins: 'lists link image quickbars table autolink code autoresize emoticons help wordcount media fullscreen accordion',
            help_tabs: ['shortcuts'],
            link_default_target: '_blank',
            image_caption: true,
            image_advtab: true,
            quickbars_insert_toolbar: false,
            link_quicklink: true,
            toolbar_mode: 'floating',
            min_height: 500,
            resize: false,
            body_class: 'article-content',
            content_css: ['/css/article-content.css', 'https://fonts.bunny.net/css?family=poppins:600,600i,700,700i', 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap'],
            content_css_cors: true,
            relative_urls: false,
            remove_script_host: false
        }"
    />
</template>
