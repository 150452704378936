<script setup lang="ts">
import TwitterX from '@/Components/Icon/TwitterX.vue'
import Instagram from '@/Components/Icon/Instagram.vue'
import FacebookF from '@/Components/Icon/FacebookF.vue'
import { ArrowRightIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'

const links = [
    {
        platform: 'Twitter/X',
        followers: 'Growing community',
        url: usePageProps().config.social_media.twitter.url,
        icon: TwitterX,
        colors: {
            background: 'bg-black/10 group-hover:bg-black/20',
            icon: 'text-black'
        }
    },
    {
        platform: 'Instagram',
        followers: 'Active daily',
        url: usePageProps().config.social_media.instagram.url,
        icon: Instagram,
        colors: {
            background: 'bg-pink-500/10 group-hover:bg-pink-500/20',
            icon: 'text-pink-500'
        }
    },
    {
        platform: 'Facebook',
        followers: 'Trending now',
        url: usePageProps().config.social_media.facebook.url,
        icon: FacebookF,
        colors: {
            background: 'bg-blue-500/10 group-hover:bg-blue-500/20',
            icon: 'text-blue-500'
        }
    }
]
</script>

<template>
    <div>
        <div class="flex flex-col gap-5">
            <div
                v-for="link in links"
                :key="link.platform">
                <a
                    :href="link.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="group block">
                    <div class="flex items-center justify-between transition-all duration-300">
                        <div class="flex items-center gap-3">
                            <div
                                :class="`p-2.5
                                    rounded-xl
                                    ${link.colors.background}
                                    transition-colors
                                    duration-300`">
                                <component
                                    :is="link.icon"
                                    :class="`w-5 h-5 ${link.colors.icon}`" />
                            </div>
                            <p class="font-medium text-gray-900">
                                {{ link.platform }}
                            </p>
                        </div>
                        <ArrowRightIcon class="w-4 h-4 text-gray-400 group-hover:text-gray-600 transform group-hover:translate-x-1 transition-all duration-300" />
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
