<template>
    <svg
        viewBox="0 0 288 288"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M31.06 173.96C31.06 184.94 33.47 193.37 36.62 198.47C40.75 205.15 46.91 207.98 53.19 207.98C61.29 207.98 68.7 205.97 82.98 186.22C94.42 170.39 107.9 148.17 116.97 134.24L132.33 110.64C143 94.25 155.35 76.03 169.51 63.68C181.07 53.6 193.54 48 206.09 48C227.16 48 247.23 60.21 262.59 83.11C279.4 108.19 287.56 139.78 287.56 172.38C287.56 191.76 283.74 206 277.24 217.25C270.96 228.13 258.72 239 238.13 239V207.98C255.76 207.98 260.16 191.78 260.16 173.24C260.16 146.82 254 117.5 240.43 96.55C230.8 81.69 218.32 72.61 204.59 72.61C189.74 72.61 177.79 83.81 164.36 103.78C157.22 114.39 149.89 127.32 141.66 141.91L132.6 157.96C114.4 190.23 109.79 197.58 100.69 209.71C84.74 230.95 71.12 239 53.19 239C31.92 239 18.47 229.79 10.14 215.91C3.34 204.6 0 189.76 0 172.85L31.06 173.96Z"
            fill="currentColor" />
        <path
            d="M24.49 85.3C38.73 63.35 59.28 48 82.85 48C96.5 48 110.07 52.04 124.24 63.61C139.74 76.26 156.26 97.09 176.87 131.42L184.26 143.74C202.1 173.46 212.25 188.75 218.19 195.96C225.83 205.22 231.18 207.98 238.13 207.98C255.76 207.98 260.16 191.78 260.16 173.24L287.56 172.38C287.56 191.76 283.74 206 277.24 217.25C270.96 228.13 258.72 239 238.13 239C225.33 239 213.99 236.22 201.45 224.39C191.81 215.31 180.54 199.18 171.87 184.68L146.08 141.6C133.14 119.98 121.27 103.86 114.4 96.56C107.01 88.71 97.51 79.23 82.35 79.23C70.08 79.23 59.66 87.84 50.94 101.01L24.49 85.3Z"
            fill="currentColor" />
        <path
            d="M82.35 79.23C70.08 79.23 59.66 87.84 50.94 101.01C38.61 119.62 31.06 147.34 31.06 173.96C31.06 184.94 33.47 193.37 36.62 198.47L10.14 215.91C3.34 204.6 0 189.76 0 172.85C0 142.1 8.44 110.05 24.49 85.3C38.73 63.35 59.28 48 82.85 48L82.35 79.23Z"
            fill="currentColor" />
    </svg>
</template>
