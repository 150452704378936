<script setup>
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import InputText from '@/Components/Input/InputText.vue'
import ShareLinks from '@/Components/Global/ShareLinks.vue'
import { ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import { ShareIcon } from '@heroicons/vue/24/outline'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'

const props = defineProps({
    url: String,
    title: String,
    excerpt: String,
    small: Boolean,
    iconOnly: {
        type: [Boolean, String],
        default: false
    }
})

const shareModalOpen = ref(false)
const shareUrl = props.url

function handleClick () {
    useAnalyticsEvent('Click share button')

    if (navigator.share) {
        navigator.share({
            title: usePageProps().app.name + ' | ' + props.title,
            text: props.excerpt
                ? props.excerpt
                : usePageProps().app.name + ' | ' + props.title,
            url: props.url
        })
    } else {
        shareModalOpen.value = true
    }
}

const {
    copy,
    copied
} = useClipboard()
</script>

<template>
    <div>
        <Button
            variant="white"
            :icon="ShareIcon"
            icon-first
            :small
            :icon-only
            @click="handleClick">
            <span
                :class="{
                    'hidden': iconOnly === true,
                    'hidden md:flex': iconOnly === 'mobile'
                }">
                Share
            </span>
        </Button>

        <Modal
            :footer="false"
            :show="shareModalOpen"
            max-width="md"
            @close="shareModalOpen = false">
            <template #title>
                Share
            </template>

            <template
                #content>
                <ShareLinks
                    :title="title"
                    :url="url"
                    class="justify-between" />
                <div class="mt-8 flex w-full pb-8 space-x-4">
                    <InputText
                        :model-value="shareUrl.replaceAll('https://', '')"
                        class="w-full"
                        disabled />
                    <Button
                        padding="px-5 py-1"
                        variant="white"
                        @click="copy(shareUrl)">
                        <span v-if="!copied">Copy</span>
                        <span v-else>Copied!</span>
                    </Button>
                </div>

                <div class="mx-auto mb-8 flex max-w-xs items-center justify-center">
                    <VueQrcode
                        :value="shareUrl + '?utm_source=website&utm_medium=qr-code'"
                        :options="{ errorCorrectionLevel: 'L', width: 200, height: 200 }" />
                </div>
            </template>
        </Modal>
    </div>
</template>
