<script lang="ts" setup>
import draggable from 'vuedraggable'
import Card from '@/Components/UI/Card.vue'
import InputText from '@/Components/Input/InputText.vue'
import GameConsoleCard from '@/Components/Game/GameConsoleCard.vue'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import type { Platform } from '@/Types/Models/Platform'
import { useMakeRequest } from '@/Composables/useMakeRequest'

const props = defineProps({
    modelValue: {
        type: Array as () => number[],
        default: () => []
    }
})

const availableConsoles = ref<Platform[]>([])
const selectedConsoles = ref<Number[]>(props.modelValue)
const consoleSearch = ref<string>('')
const inputFocused = ref<boolean>(false)
const consoleSearchInput = ref(null)

function selectConsole (selectedConsole) {
    selectedConsoles.value.push(selectedConsole.id)
    inputFocused.value = false
}

watch(() => consoleSearch.value, (newValue) => {
    if (newValue.length < 1) {
        availableConsoles.value = []
        return
    }

    useMakeRequest(useRoute('api.game.platforms.search', { q: newValue }), 'GET')
        .then(response => {
            availableConsoles.value = response
        })
})

onMounted(() => {
    selectedConsoles.value = props.modelValue
})

const emit = defineEmits(['update:modelValue'])

watch(() => selectedConsoles.value, (newValue) => {
    emit('update:modelValue', newValue)
})
</script>

<template>
    <div class="relative flex w-full flex-col">
        <div>
            <draggable
                v-model="selectedConsoles"
                group="tags"
                class="-mx-2 flex flex-wrap"
                ghost-class="opacity-50"
                handle=".handle"
                item-key="id">
                <template #item="{element}">
                    <li
                        class="flex w-1/2 items-center justify-between p-2 handle">
                        <div class="relative w-full rounded-xl p-2 bg-primary/5">
                            <GameConsoleCard
                                :key="element"
                                :load="true"
                                :linkify="false"
                                :small="true"
                                :platform="element" />
                            <div class="absolute top-2 right-2">
                                <button
                                    type="button"
                                    @click="selectedConsoles.splice(selectedConsoles.indexOf(element), 1)">
                                    <XMarkIcon class="w-4" />
                                </button>
                            </div>
                        </div>
                    </li>
                </template>
            </draggable>
        </div>
        <form
            class="w-full"
            :class="selectedConsoles && selectedConsoles.length > 0 ? 'mt-4' : ''">
            <InputText
                ref="consoleSearchInput"
                v-model="consoleSearch"
                input-id="console-search"
                clearable
                label="Search for a console..."
                @focus="inputFocused = true"
            />
        </form>
        <Card
            v-if="availableConsoles.filter(console => !selectedConsoles.includes(console.id)).length > 0 && inputFocused"
            class="absolute top-full z-40 mt-2 w-full md:px-2 md:py-2">
            <ul class="flex max-h-64 flex-col overflow-scroll">
                <li>
                    <button
                        v-for="console in availableConsoles.filter(console => !selectedConsoles.includes(console.id))"
                        :key="console.id"
                        type="button"
                        class="my-1 flex w-full items-center rounded-lg p-2 hover:bg-primary/5"
                        @click="selectConsole(console)">
                        <GameConsoleCard
                            :small="true"
                            :linkify="false"
                            :platform="console" />
                    </button>
                </li>
            </ul>
        </Card>
    </div>
</template>
