<script setup lang="ts">
import { useAttrs } from 'vue'
import { cn } from '@/utils.ts'
import { twMerge } from 'tailwind-merge'

defineProps({
    border: {
        type: Boolean,
        default: false
    }
})

const attrs = useAttrs()

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div
        :class="cn(
            `rounded-xl shadow-sm bg-white border-zinc-200 p-4 card md:py-4 md:px-5 ${border ? 'border' : ''}`,
            attrs.class
        )">
        <slot />
    </div>
</template>
