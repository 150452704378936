<script setup>
import Vue3TagsInput from 'vue3-tags-input'
import { onMounted, ref } from 'vue'
import { TagIcon } from '@heroicons/vue/24/solid'

defineProps({
    modelValue: Array,
    displayIcon: {
        type: Boolean,
        default: true
    },
    label: String,
    error: String
})

const emits = defineEmits(['update:modelValue'])

function onChange (tags) {
    emits('update:modelValue', tags)
}

const id = ref('')

onMounted(() => {
    id.value = 'input' + (Math.random() + 1).toString(36).substring(7)
})
</script>

<template>
    <div>
        <div class="relative flex items-start text-slate-900/75 group dark:text-white/75">
            <TagIcon
                v-if="displayIcon"
                class="w-5 mt-2.5 group-hover:text-primary" />
            <Vue3TagsInput
                class="tags-input"
                :tags="modelValue"
                placeholder="Tags..."
                @on-tags-changed="onChange" />
        </div>
        <div v-if="error">
            {{ error }}
        </div>
    </div>
</template>
