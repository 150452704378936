<template>
    <svg
        viewBox="0 0 274 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M149.685 7.1807L126.552 97.8743C126.552 97.8743 108.865 29.7974 105.988 20.1407C103.109 10.4689 97.1879 6.2313 88.7891 6.2313C80.3879 6.2313 74.4556 10.4685 71.5786 20.1407C68.7132 29.7966 51.0213 97.8743 51.0213 97.8743L27.8733 7.1807H0C0 7.1807 26.7708 103.944 30.4093 115.154C33.24 123.901 39.9454 131.052 49.8852 131.052C61.2506 131.052 66.565 122.766 69.0266 115.154C71.4613 107.586 88.7894 43.6758 88.7894 43.6758C88.7894 43.6758 106.117 107.588 108.547 115.154C111.006 122.766 116.321 131.052 127.683 131.052C137.631 131.052 144.324 123.901 147.173 115.154C150.803 103.948 177.555 7.1807 177.555 7.1807H149.685ZM245.254 130.117H271.642V44.7643H245.254V130.117ZM242.697 14.9212C242.697 23.1477 249.637 29.8254 258.158 29.8254C267.025 29.8254 273.97 23.2853 273.97 14.9212C273.97 6.55445 267.025 0 258.158 0C249.637 0 242.697 6.68674 242.697 14.9212ZM191.635 130.117H218.015V44.7643H191.635V130.117ZM189.07 14.9212C189.07 23.1477 195.997 29.8254 204.523 29.8254C213.385 29.8254 220.343 23.2853 220.343 14.9212C220.343 6.55445 213.385 0 204.523 0C195.997 0 189.07 6.68674 189.07 14.9212Z"
            fill="currentColor" />
    </svg>
</template>
