<script setup>
import Button from '@/Components/UI/Button.vue'
import AuthLayout from '@/Layouts/AuthLayout.vue'
import InputText from '@/Components/Input/InputText.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import MinimalAuthLayout from '@/Layouts/MinimalAuthLayout.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { onMounted, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { useUrlSearchParams } from '@vueuse/core'
import { useRoute } from '@/Composables/useRoute.ts'
import { ArrowLeftIcon } from '@heroicons/vue/20/solid/index.js'

const form = useForm({
    email: null
})

const submitted = ref(false)

function submit () {
    submitted.value = false
    form.post(useRoute('password.email'), {
        preserveScroll: true,
        onSuccess: () => {
            submitted.value = true
        }
    })
    if (form.email !== null) {
        submitted.value = true
    }
}

onMounted(() => {
    const params = useUrlSearchParams()
    if (typeof params.email !== 'undefined') {
        form.email = params.email
    }
})

defineOptions({ layout: AuthLayout })
</script>

<template>
    <div>
        <SectionTitle v-if="!submitted">
            Forgot password?
        </SectionTitle>
        <SectionDescription
            v-if="!submitted"
            class="mt-2 mb-4 text-sm text-gray-600">
            No worries, we got you covered. Just enter your email address below and we'll send you a link to reset your password.
        </SectionDescription>

        <SectionTitle v-if="submitted">
            Check your inbox
        </SectionTitle>
        <SectionDescription
            v-if="submitted"
            class="mt-2 mb-4 text-sm text-gray-600">
            An email has been sent to <strong>{{ form.email }}</strong> with instructions to reset your password. Don't
            forget to check your spam/junk.
        </SectionDescription>

        <form
            v-if="!submitted"
            @submit.prevent="submit">
            <InputText
                v-model="form.email"
                :error="form.errors.email"
                placeholder="Email address"
                type="email"
            />

            <div class="mt-4">
                <Button
                    type="submit"
                    :disabled="form.processing"
                    class="w-full"
                    variant="primary">
                    Send reset link
                </Button>
            </div>
        </form>
        <div class="mt-4">
            <Button
                :href="useRoute('login')"
                :icon="ArrowLeftIcon"
                icon-first
                variant="text">
                Back to login
            </Button>
        </div>
    </div>
</template>
