<script setup lang="ts">
import MapImage from '~/app/decoration/map.svg'
import { usePageProps } from '@/Composables/usePageProps.ts'

const features = [
    {
        title: 'Dashboard',
        description: 'Check your latest stats, manage your articles, read your notifications, and more from your powerful custom dashboard.'
    },
    {
        title: 'Interact til your heart\'s content',
        description: 'Like, comment, bookmark, and share your favourite articles to give them the love they deserve (and to improve your recommendations!).'
    },
    {
        title: 'Subscriptions',
        description: 'Subscribe to your favourite authors, tags, and games to get the latest articles delivered straight to your notifications.'
    }
]
</script>

<template>
    <div>
        <div class="absolute inset-0 z-0 overflow-hidden bg-grid" />

        <div class="container relative">
            <div class="relative mx-auto mb-4 flex w-full max-w-7xl flex-col items-center lg:flex-row lg:gap-12">
                <div class="absolute top-9 -left-1/4 z-0 w-full opacity-5 lg:static lg:w-1/2 lg:opacity-100">
                    <img
                        data-aos="zoom-in"
                        data-aos-delay="200"
                        class="drop-shadow"
                        alt="(Decorative) map image"
                        :src="MapImage"
                        fetchpriority="high">
                </div>
                <div class="relative w-full lg:w-1/2">
                    <div
                        class="flex flex-col gap-2"
                        data-aos="fade-left"
                        data-aos-delay="200">
                        <h1 class="text-3xl tracking-tighter font-heading sm:text-5xl">
                            Key features
                        </h1>
                        <p class="text-slate-500 max-w-[600px] dark:text-slate-400 md:text-xl">
                            Explore what {{ usePageProps().app.name }} has to offer.
                        </p>
                    </div>
                    <ul class="mt-6 flex flex-col gap-6">
                        <li
                            v-for="(feature, index) in features"
                            :key="index"
                            data-aos="fade-left"
                            :data-aos-delay="200 + ((index + 1) * 50)">
                            <div class="flex flex-col gap-1">
                                <h2
                                    class="text-xl font-bold font-heading"
                                    v-html="feature.title" />
                                <p
                                    class="text-slate-500 text-pretty dark:text-slate-400"
                                    v-html="feature.description" />
                            </div>
                        </li>
                        <li
                            class="text-right text-2xl font-semibold font-heading"
                            data-aos-delay="500"
                            data-aos="fade-up">
                            ...and more!
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
