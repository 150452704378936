<script setup>

import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import MinimalAuthLayout from '@/Layouts/MinimalAuthLayout.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

const form = useForm({
    email: null,
    password: null,
    remember: false
})

const emit = defineEmits(['confirmed'])

function submit () {
    form.post(useRoute('password.confirm'), {
        onFinish: () => {
            form.reset()
            emit('confirmed')
        }
    })
}

defineOptions({ layout: MinimalAuthLayout })
</script>

<template>
    <div>
        <SectionTitle>
            Confirm Password
        </SectionTitle>
        <SectionDescription>
            This is a secure area of the application. Please confirm your password before continuing.
        </SectionDescription>
        <form
            class="mt-4"
            @submit.prevent="submit">
            <InputText
                v-model="form.password"
                input-type="password"
                :error="form.errors.password"
                placeholder="Password" />
            <div class="mt-4">
                <Button
                    :disabled="form.processing"
                    class="w-full"
                    variant="primary">
                    Confirm
                </Button>
            </div>
        </form>
    </div>
</template>
