<script setup lang="ts">
import PostList from '@/Components/Post/PostList.vue'
import PostForm from '@/Components/Post/PostForm.vue'
import ProfileLayout from '@/Layouts/ProfileLayout.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import PostListSkeleton from '@/Components/Post/PostListSkeleton.vue'
import type { User } from '@/Types/Models/User'
import type { Post } from '@/Types/Models/Post'
import type { ImageUrls } from '@/Types/ImageUrls'
import { Deferred, router } from '@inertiajs/vue3'
import { type Prop, type PropType, ref } from 'vue'
import type { PaginatedItems, PaginationMeta } from '@/Types/PaginatedItems'

defineProps({
    user: Object as Prop<User>,
    posts: [Object as PropType<PaginatedItems<Post>>, Object as PropType<Post[]>],
    media: Array as PropType<ImageUrls[]>,
    paginated: Boolean
})

const emit = defineEmits(['reload'])
const postsKey = ref(0)

function reload () {
    router.reload({
        only: ['posts'],
        onSuccess: () => {
            postsKey.value++
            emit('reload')
        }
    })
}

defineOptions({ layout: ProfileLayout })
</script>

<template>
    <div>
        <div class="flex gap-8">
            <div class="w-full">
                <SectionTitle
                    font-size="text-xl"
                    class="mb-2">
                    Posts
                </SectionTitle>
                <PostForm
                    @reload="reload"
                />

                <Deferred data="posts">
                    <template #fallback>
                        <PostListSkeleton
                            class="mt-4"
                            :number="3" />
                    </template>

                    <div class="mt-4">
                        <PostList
                            :key="postsKey"
                            :include-form="false"
                            :paginated="paginated"
                            :user="user"
                            :posts="posts"
                            @reload="reload" />
                    </div>
                </Deferred>
            </div>
        </div>
    </div>
</template>
