<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import ErrorText from '@/Components/UI/ErrorText.vue'
import UserCard from '@/Components/User/UserCard.vue'
import InputTags from '@/Components/Input/InputTags.vue'
import InputText from '@/Components/Input/InputText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import GameSelector from '@/Components/Game/GameSelector.vue'
import InputBasicSelect from '@/Components/Input/InputBasicSelect.vue'
import CategorySelector from '@/Components/Category/CategorySelector.vue'
import ArticleTitleInput from '@/Components/Article/ArticleTitleInput.vue'
import ArticleRejections from '@/Components/Article/ArticleRejections.vue'
import ArticleFormHelper from '@/Components/Article/ArticleFormHelper.vue'
import InputWYSIWYGTinyMCE from '@/Components/Input/InputWYSIWYGTinyMCE.vue'
import ArticleFormOptions from '@/Components/Article/ArticleFormOptions.vue'
import ArticleFormRatings from '@/Components/Article/ArticleFormRatings.vue'
import ArticleSubtitleInput from '@/Components/Article/ArticleSubtitleInput.vue'
import ArticleThumbnailInput from '@/Components/Article/ArticleThumbnailInput.vue'
import { onMounted, ref, watch } from 'vue'
import { router, useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import type { Article } from '@/Types/Models/Article'
import type { Category } from '@/Types/Models/Category'
import { ArticleAccess } from '@/Enums/ArticleAccess.ts'
import type { ArticleRating } from '@/Types/ArticleRating'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { NotificationType } from '@/Enums/NotificationType.ts'
import { useMagicKeys, useTimeoutPoll, useUrlSearchParams } from '@vueuse/core'

interface RejectionReason {
    date: string;
    reason: string;
}

interface Props {
    rejectionReasons: RejectionReason[] | null;
    autosave: boolean;
    article: Article | null;
    ratings: ArticleRating<any>[];
    tag: string | null;
    categories: Category[];
}

const props = defineProps<Props>()

const form = useForm({
    uuid: props.article?.uuid || null,
    title: props.article?.title || null,
    content: props.article?.content || null,
    category: props.article?.category || null,
    // @ts-ignore
    featured_image: props.article?.featured_image?.original || null,
    tags: props.article?.tags?.length ? props.article.tags.map(tag => tag.name) : (props.tag ? [props.tag] : []),
    publish_at: props.article?.publish_at || null,
    featured_image_changed: false,
    metadata: {
        subtitle: props.article?.metadata?.subtitle || '',
        conclusion: props.article?.metadata?.conclusion || '',
        game_identifier: props.article?.metadata?.game_identifier || null,
        allow_comments: props.article?.metadata?.allow_comments || false,
        public: props.article?.metadata?.public || false,
        has_spoiler: props.article?.metadata?.has_spoiler || false,
        accessible_by: props.article?.metadata?.accessible_by || ArticleAccess.ALL,
        password: props.article?.metadata?.password || '',
        ratings_order: props.article?.metadata?.ratings_order || []
    }
})

const params = useUrlSearchParams('history')
const autosaved = ref(false)
const manuallySaved = ref(false)
const submitting = ref(false)
const schedulePublish = ref(form.publish_at !== null)
const ratingsKey = ref('ratings')

const addFeaturedImage = (file: File) => {
    form.featured_image = file
    form.featured_image_changed = true
    saveDraft()
}

const removeFeaturedImage = () => {
    form.featured_image = null
    form.featured_image_changed = true
}

function saveDraft (auto = false): void {
    if (form.metadata.public) {
        form.metadata.accessible_by = ArticleAccess.ALL
        form.metadata.password = null
    }
    if (form.metadata.accessible_by !== ArticleAccess.PASSWORD) {
        form.metadata.password = null
    }

    form.submit('patch', useRoute('articles.update', { article: props.article }), {
        preserveScroll: true,
        onSuccess: () => {
            form.featured_image_changed = false
            if (auto) autosaved.value = true
            else manuallySaved.value = true

            setTimeout(() => {
                manuallySaved.value = false
                autosaved.value = false
            }, 1000)
        }
    })
}

function submit (): void {
    submitting.value = true

    form.submit('post', useRoute('articles.submit'), {
        preserveScroll: true,
        onSuccess: () => {
            form.featured_image_changed = false
            submitting.value = false
        },
        onError: () => {
            useToast('Failed to submit article', NotificationType.FAIL)
            submitting.value = false
        }
    })
}

function reloadRatings (): void {
    router.reload({
        only: ['ratings'],
        onSuccess: () => {
            ratingsKey.value = (ratingsKey.value + 1) + form.metadata.ratings_order.join('-')
            saveDraft()
        }
    })
}

// Autosave poll
const { resume } = useTimeoutPoll(() => {
    saveDraft(true)
}, 30000)

useMagicKeys({
    passive: false,
    onEventFired (e: KeyboardEvent) {
        if ((e.ctrlKey || e.metaKey) && e.key === 's' && e.type === 'keydown') {
            e.preventDefault()
            saveDraft(true)
        }
    }
})

const selectedCategory = ref<Number>(props.article?.category?.id || null)
watch(selectedCategory, (value) => {
    form.category = props.categories.find(category => category.id === value)
})

onMounted(() => {
    params.game = null
    if (props.autosave) resume()
})
</script>

<template>
    <form
        class="flex flex-col pb-12"
        @submit.prevent="submit">
        <div class="mx-auto w-full max-w-7xl px-0 md:px-5">
            <div class="relative flex flex-col items-stretch gap-4 md:flex-row md:gap-8">
                <div class="w-full md:w-8/12">
                    <div class="relative z-30 mb-2 flex flex-col md:mb-4">
                        <InputBasicSelect
                            v-model="selectedCategory"
                            :error="form.errors.category"
                            :options="categories.map(category => ({ value: category.id, label: category.title }))"
                            label="Category"
                            placeholder="Category" />
                    </div>
                    <InputText
                        v-model="form.title"
                        :error="form.errors.title"
                        label="Title" />
                    <!--                    <ArticleTitleInput-->
                    <!--                        v-model="form.title"-->
                    <!--                        :error="form.errors.title"-->
                    <!--                        label="Title..." />-->

                    <InputLabel class="mt-4">
                        Featured Image
                    </InputLabel>
                    <ArticleThumbnailInput
                        :default-thumbnail="form.featured_image"
                        :error="form.errors.featured_image"
                        @removed="removeFeaturedImage"
                        @selected="addFeaturedImage" />

                    <!--                    <ArticleSubtitleInput-->
                    <!--                        v-model="form.metadata.subtitle"-->
                    <!--                        :error="form.errors['metadata.subtitle']"-->
                    <!--                        class="mt-4 md:mt-8"-->
                    <!--                        label="Subtitle (optional)" />-->

                    <div class="mt-4 md:mt-8">
                        <InputWYSIWYGTinyMCE
                            v-model="form.content"
                            :error="form.errors.content" />

                        <ErrorText
                            v-if="form.errors.content"
                            class="mt-2"
                            :error="form.errors.content" />

                        <InputTags
                            v-model="form.tags"
                            class="mt-8"
                            label="Tags (optional)" />

                        <ArticleFormRatings
                            :key="ratingsKey"
                            v-model:order="form.metadata.ratings_order"
                            v-model:error="form.errors['metadata.ratings_order']"
                            class="mt-8"
                            :ratings="ratings"
                            :article="article"
                            @update="reloadRatings" />
                    </div>
                </div>
                <div class="relative flex w-full flex-col md:w-4/12">
                    <aside class="sticky top-0 mt-6 pt-0.5">
                        <div class="flex flex-col gap-4">
                            <ArticleFormHelper :form />
                            <ArticleRejections
                                :rejection-reasons="rejectionReasons" />
                            <ArticleFormOptions :form />
                            <div :class="form.metadata.game_identifier ? 'pb-2' : ''">
                                <GameSelector
                                    v-model="form.metadata.game_identifier"
                                    :small="true"
                                    :white="true" />
                            </div>

                            <div class="flex gap-4">
                                <Button
                                    :disabled="form.processing"
                                    type="button"
                                    variant="white"
                                    class="w-full"
                                    padding="px-4 py-2"
                                    @click="saveDraft">
                                    {{ manuallySaved ? "Saved" : "Save draft" }}
                                </Button>
                                <Button
                                    :disabled="form.processing"
                                    type="button"
                                    class="w-full"
                                    padding="px-4 py-2"
                                    @click="submit">
                                    <span v-if="schedulePublish">
                                        {{ submitting ? "Scheduling..." : "Schedule" }}
                                    </span>
                                    <span v-else>
                                        {{ submitting ? "Submitting..." : "Submit" }}
                                    </span>
                                </Button>
                            </div>

                            <div class="-mt-3 flex justify-end pr-1 text-xs">
                                <a
                                    v-tooltip="`Learn more about the submission process`"
                                    :href="useRoute('submission-process')"
                                    class="hover:underline"
                                    target="_blank">
                                    learn more
                                </a>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </form>
</template>
