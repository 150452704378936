import { NewspaperIcon, StarIcon, MapIcon, LightBulbIcon } from '@heroicons/vue/24/outline'

const CategoryIconMap = {
    News: NewspaperIcon,
    Reviews: StarIcon,
    Guides: MapIcon,
    Editorials: LightBulbIcon
}

export default CategoryIconMap
