<script lang="ts" setup>
import Pill from '@/Components/UI/Pill.vue'
import Icon from '@/Components/Icon/index.js'
import Header from '@/Components/UI/Header.vue'
import Button from '@/Components/UI/Button.vue'
import GameGrid from '@/Components/Game/GameGrid.vue'
import InputText from '@/Components/Input/InputText.vue'
import GameFilter from '@/Components/Game/GameFilter.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import ModalBackdrop from '@/Components/UI/ModalBackdrop.vue'
import SectionSubtitle from '@/Components/UI/SectionSubtitle.vue'
import InputBasicSelect from '@/Components/Input/InputBasicSelect.vue'
import { watchDebounced } from '@vueuse/core'
import type { Game } from '@/Types/Models/Game'
import type { Genre } from '@/Types/Models/Genre'
import type { PageHeader } from '@/Types/PageHeader'
import { useRoute } from '@/Composables/useRoute.ts'
import { Link, router, useForm } from '@inertiajs/vue3'
import type { Platform } from '@/Types/Models/Platform'
import { computed, type PropType, ref, watch } from 'vue'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { FunnelIcon, AdjustmentsHorizontalIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    games: Object as PropType<PaginatedItems<Game>>,
    genres: Array as PropType<Genre[]>,
    platforms: Array as PropType<Platform[]>,
    initialSelectedGenres: Array as PropType<number[]>,
    initialSelectedPlatforms: Array as PropType<number[]>,
    selectedOrder: String as PropType<string>,
    perPage: Number as PropType<number>,
    search: String as PropType<string>,
    header: Object as Object as PropType<PageHeader>
})

const loading = ref<boolean>(false)
const allGames = ref<PaginatedItems<Game>>(props.games)
const genreSearch = ref<string>('')
const platformSearch = ref<string>('')
const displayFilter = ref<boolean>(false)
const selectedGenres = ref<number[]>(props.initialSelectedGenres)
const selectedPlatforms = ref<number[]>(props.initialSelectedPlatforms)

const orderOptions = [
    { value: 'name:desc', label: 'Name (A-Z)' },
    { value: 'name:asc', label: 'Name (Z-A)' },
    { value: 'release_date:desc', label: 'Release Date (New to old)' },
    { value: 'release_date:asc', label: 'Release Date (Old to new)' },
    { value: 'popularity_score:desc', label: 'Popularity (High to low)' },
    { value: 'popularity_score:asc', label: 'Popularity (Low to high)' }
]

function toggleFilter () {
    displayFilter.value = !displayFilter.value
    document.body.style.overflow = displayFilter.value ? 'hidden' : ''
}

function removeGenre (genre: number) {
    form.genres = form.genres.filter(g => g !== genre)
    selectedGenres.value = selectedGenres.value.filter(g => g !== genre)
}

function removePlatform (platform: number) {
    form.platforms = form.platforms.filter(p => p !== platform)
    selectedPlatforms.value = selectedPlatforms.value.filter(p => p !== platform)
}

function getGenreName (id: any) {
    return props.genres.find(genre => genre.id === parseInt(id))?.name
}

function getPlatformName (id: any) {
    return props.platforms.find(platform => platform.id === parseInt(id))?.name
}

const lazyload = () => {
    router.reload({
        only: ['games'],
        onSuccess: () => {
            loading.value = false
            allGames.value = props.games
        }
    })
}

const isFiltered = computed(() => {
    return (form.genres && form.genres.length) || (form.platforms && form.platforms.length)
})

router.on('navigate', () => {
    loading.value = true
    allGames.value = null
    // setTimeout(() => {
    lazyload()
    // }, 500)
})

const searchValue = ref<string>(props.search)

function submitSearch () {
    console.log('dmkejnk')
    const data = {
        genres: form.genres.join(','),
        platforms: form.platforms.join(','),
        order: form.order,
        q: searchValue.value
    }

    router.get(useRoute('games.index', data), {}, {
        preserveScroll: true
    })
}

const form = useForm({
    genres: props.initialSelectedGenres ?? [],
    platforms: props.initialSelectedPlatforms ?? [],
    order: orderOptions.find(option => option.value === (props.selectedOrder ?? 'popularity_score:desc')).value
})

const filterData = ref({})

watch(form, () => {
    filterData.value = {
        genres: form.genres.join(','),
        platforms: form.platforms.join(','),
        order: form.order
    }

    selectedGenres.value = form.genres
    selectedPlatforms.value = form.platforms
    genreSearch.value = null
    platformSearch.value = null
})

watchDebounced(filterData, () => {
    const data = {
        ...filterData.value,
        q: searchValue.value
    }

    router.get(useRoute('games.index', data), {}, {
        preserveScroll: true
    })
}, { debounce: 500, maxWait: 1000 })
</script>

<template>
    <div>
        <Header :header="header" />
        <!--                <InputText-->
        <!--                    v-model="search"-->
        <!--                    placeholder="Search for games..."-->
        <!--                    class="w-full" />-->

        <ModalBackdrop
            class="z-10"
            :show="displayFilter"
            @click="toggleFilter" />

        <div class="container relative mx-auto mb-12 flex w-full flex-wrap">
            <div class="flex w-full flex-col gap-2">
                <form
                    class="flex w-full gap-2 md:hidden"
                    @submit.prevent="submitSearch">
                    <InputText
                        v-model="searchValue"
                        name="q"
                        clearable
                        placeholder="Search for games..."
                        class="w-full"
                        @clear="submitSearch" />
                    <Button
                        type="submit"
                        variant="white"
                        :icon="MagnifyingGlassIcon" />
                </form>
                <div class="flex gap-2 md:hidden">
                    <div class="relative">
                        <div
                            v-if="isFiltered"
                            class="absolute z-10 rounded-full size-2 bg-primary -top-0.5 -right-0.5" />
                        <Button
                            variant="white"
                            icon-only
                            :icon="AdjustmentsHorizontalIcon"
                            @click="toggleFilter" />
                    </div>
                    <InputBasicSelect
                        v-model="form.order"
                        anchor="right"
                        :clearable="false"
                        :options="orderOptions"
                        class="w-full" />
                </div>
            </div>

            <div class="flex w-full flex-col gap-8 md:flex-row">
                <div
                    :class="displayFilter ? 'translate-x-0' : '-translate-x-full'"
                    class="w-11/12 p-6 pt-2 md:translate-x-0 fixed transition-all top-14 left-0 bg-white z-40 h-[calc(100vh-3.5rem)] overflow-scroll md:p-0 md:bg-transparent md:overflow-visible md:static md:z-auto md:h-auto shrink-0 md:w-2/5 lg:w-1/5 flex md:gap-6 md:pt-3 flex-col">
                    <div class="flex items-center justify-between md:hidden">
                        <SectionSubtitle>
                            Filter games
                        </SectionSubtitle>
                        <button
                            type="button"
                            @click="toggleFilter">
                            <XMarkIcon
                                class="w-6 opacity-50 hover:opacity-100"
                                aria-hidden="true" />
                        </button>
                    </div>

                    <div class="-mt-2 flex w-full flex-col gap-4 md:mt-0">
                        <div class="mb-1 flex w-full flex-col gap-2">
                            <form
                                class="hidden w-full gap-2 md:flex"
                                @submit.prevent="submitSearch">
                                <InputText
                                    v-model="searchValue"
                                    name="q"
                                    clearable
                                    placeholder="Search for games..."
                                    class="w-full"
                                    @cleared="submitSearch" />
                                <Button
                                    type="submit"
                                    variant="white"
                                    :icon="MagnifyingGlassIcon" />
                            </form>

                            <div class="hidden w-full md:flex">
                                <InputBasicSelect
                                    v-model="form.order"
                                    anchor="right"
                                    :clearable="false"
                                    :options="orderOptions"
                                    class="w-full" />
                            </div>
                        </div>
                        <GameFilter
                            v-model:selected="form.genres"
                            :options="props.genres " />

                        <GameFilter
                            v-model:selected="form.platforms"
                            :options="props.platforms" />
                    </div>
                </div>
                <div
                    class="w-full"
                    :class="[isFiltered ? 'mt-4 md:mt-3' : 'mt-4 md:mt-0']">
                    <ul class="mb-4 flex flex-wrap gap-3">
                        <template v-for="genre in selectedGenres">
                            <li
                                v-if="genre"
                                :key="genre">
                                <Pill
                                    v-if="genre"
                                    :removable="true"
                                    @remove="removeGenre(genre)">
                                    {{ getGenreName(genre) }}
                                </Pill>
                            </li>
                        </template>

                        <template v-for="platform in selectedPlatforms">
                            <li
                                v-if="platform"
                                :key="platform">
                                <Pill
                                    :removable="true"
                                    @remove="removePlatform(platform)">
                                    {{ getPlatformName(platform) }}
                                </Pill>
                            </li>
                        </template>
                    </ul>
                    <GameGrid
                        :key="'games-' + allGames?.data?.length + '-' + games?.meta?.current_page"
                        :skeleton-count="perPage"
                        class="-mt-4 md:-mt-3"
                        :games="allGames">
                        <template #empty>
                            <EmptyState
                                class="md:px-6 md:py-12"
                                title="No games could be found"
                                :icon="Icon.GamePad">
                                <template #description>
                                    <div class="mt-2 mb-0 prose prose-sm">
                                        <p>
                                            Try changing your filters or search query.
                                            <br>
                                            Believe this is a mistake?
                                            <Link
                                                :href="useRoute('contact')"
                                                class="hover:underline">
                                                Let us know
                                            </Link>
                                        </p>
                                    </div>
                                </template>
                            </EmptyState>
                        </template>
                    </GameGrid>
                </div>
            </div>
        </div>
    </div>
</template>
