<script setup lang="ts">
import GameGrid from '@/Components/Game/GameGrid.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import { type PropType } from 'vue'
import type { Game } from '@/Types/Models/Game'
import { useIsBreakpoint } from '@/Composables/useIsBreakpoint.ts'

defineProps({
    title: String,
    games: Array as PropType<Game[]>
})

const isMobile = useIsBreakpoint('md')
</script>

<template>
    <div>
        <SectionTitle
            v-if="title"
            class="!font-body font-semibold">
            {{ title }}
        </SectionTitle>

        <GameGrid
            class="mt-2 md:-mx-2"
            :small="!isMobile"
            game-width="w-1/2 sm:w-1/3"
            :games="games" />
    </div>
</template>
