import { useRoute } from '@/Composables/useRoute.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import type { Article } from '@/Types/Models/Article'

interface ResponseData {
    count: number;
}

export async function useCountArticleLikes (article: Article): Promise<number> {
    const response: ResponseData = await useMakeRequest(
        useRoute('api.article.likes.count', { article }),
        'GET'
    )

    return response.count
}
