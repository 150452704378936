<script lang="ts" setup>
import FsLightbox from '@/Plugins/fslightbox/v3'
import Novus from '@/Components/Global/Novus.vue'
import ArticleScreenshot from '~/screenshots/article.png'
import ProfileScreenshot from '~/screenshots/profile.png'
import ScreenshotImage from '~/app/decoration/screenshot.svg'
import DashboardScreenshot from '~/screenshots/dashboard.png'
import SingleGameScreenshot from '~/screenshots/single-game.png'
import ArticleScreenshotThumb from '~/screenshots/article-thumb.png'
import ProfileScreenshotThumb from '~/screenshots/profile-thumb.png'
import ArticleEditorScreenshot from '~/screenshots/article-editor.png'
import DashboardScreenshotThumb from '~/screenshots/dashboard-thumb.png'
import SingleGameScreenshotThumb from '~/screenshots/single-game-thumb.png'
import ArticleEditorScreenshotThumb from '~/screenshots/article-editor-thumb.png'
import { defineComponent, ref } from 'vue'
import { usePageProps } from '@/Composables/usePageProps.ts'

const showScreenshotLightbox = ref<boolean>(false)
const screenshotLightboxSelected = ref<number>(0)

function openLightboxOnSlide (number: number) {
    number = number + 1
    screenshotLightboxSelected.value = number
    showScreenshotLightbox.value = !showScreenshotLightbox.value
}

const ScreenshotCaption = defineComponent({
    props: {
        message: String
    },
    template: '<div class="text-white">{{ message }}</div>'
})

const screenshots = [
    {
        src: DashboardScreenshot,
        thumb: DashboardScreenshotThumb,
        caption: {
            component: ScreenshotCaption,
            props: {
                message: 'Your personal dashboard'
            }
        }
    },
    {
        src: ArticleScreenshot,
        thumb: ArticleScreenshotThumb,
        caption: {
            component: ScreenshotCaption,
            props: {
                message: 'An example article page'
            }
        }
    },
    {
        src: ProfileScreenshot,
        thumb: ProfileScreenshotThumb,
        caption: {
            component: ScreenshotCaption,
            props: {
                message: 'Your cusomisable profile page'
            }
        }
    },
    {
        src: ArticleEditorScreenshot,
        thumb: ArticleEditorScreenshotThumb,
        caption: {
            component: ScreenshotCaption,
            props: {
                message: 'The powerful article editor'
            }
        }
    },
    {
        src: SingleGameScreenshot,
        thumb: SingleGameScreenshotThumb,
        caption: {
            component: ScreenshotCaption,
            props: {
                message: 'A single game page'
            }
        }
    }
]
</script>

<template>
    <div class="w-full overflow-hidden">
        <FsLightbox
            v-if="screenshots && screenshots.length"
            :slide="screenshotLightboxSelected"
            :captions="screenshots.map(screenshot => screenshot.caption)"
            :sources="screenshots.map(screenshot => screenshot.src)"
            :toggler="showScreenshotLightbox" />

        <div
            class="pointer-events-none absolute right-0 -bottom-28 hidden aspect-square overflow-hidden opacity-20 w-128 xl:block">
            <img
                width="192"
                height="176"
                alt="Decorative screenshot"
                :src="ScreenshotImage"
                class="-right-4 w-full rotate-6">
        </div>

        <div
            class="pointer-events-none absolute bottom-0 left-0 z-0 aspect-square w-64 overflow-hidden opacity-25 lg:opacity-100">
            <div
                data-aos="slide-up-right-diagonal"
                data-aos-delay="500"
                class="flex w-48 items-end justify-end size-full">
                <Novus
                    emotion="love1"
                    class="w-full -translate-x-10 translate-y-12 rotate-[35deg]" />
            </div>
        </div>

        <div class="container relative px-4 md:px-6">
            <div class="flex flex-col items-center gap-6 lg:flex-row lg:gap-12 xl:gap-24">
                <div
                    class="flex w-full flex-col justify-center gap-2 lg:w-5/12"
                    data-aos="fade-right"
                    data-aos-delay="200">
                    <h2 class="text-3xl tracking-tighter font-heading sm:text-5xl">
                        Screenshots
                    </h2>
                    <p class="text-slate-500 max-w-[600px] text-pretty dark:text-slate-400 md:text-xl">
                        Learn more about the features and capabilities of {{ usePageProps().app.name }} by seeing it in action.
                        <!--                        Get a glimpse of the immersive gaming experiences and engaging community interactions on-->
                        <!--                        {{ usePageProps().app.name }}.-->
                    </p>
                </div>
                <div class="flex w-full flex-row flex-wrap overflow-hidden rounded-2xl p-3 bg-primary-lighter lg:w-1/2">
                    <div
                        v-for="(screenshot, i) in screenshots"
                        :key="i"
                        :class="{
                            'w-1/3': i > 1,
                            'rounded-tl-xl': i === 0,
                            'rounded-tr-xl': i === 1,
                            'rounded-bl-xl': i === 2,
                            'rounded-br-xl': i === 4,
                            'pb-4 w-1/2 ': i === 0 || i === 1,
                            'pr-4': i === 2 || i === 3,
                            'pr-2': i === 0,
                            'pl-2': i === 1,
                        }"
                        data-aos="fade-up"
                        :data-aos-delay="75 * (i + 1)"
                        class="cursor-pointer overflow-hidden border-transparent group"
                        @click="openLightboxOnSlide(i)">
                        <img
                            :src="screenshot.thumb"
                            :alt="screenshot.caption.props.message"
                            width="550"
                            height="310"
                            class="mx-auto h-full w-full object-cover object-center opacity-100 grayscale transition-all aspect-article-thumb group-hover:grayscale-0"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
