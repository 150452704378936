<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import TabLinks from '@/Components/UI/TabLinks.vue'
import UserGrid from '@/Components/User/UserGrid.vue'
import GameGrid from '@/Components/Game/GameGrid.vue'
import Breadcrumbs from '@/Components/UI/Breadcrumbs.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import GameSkeletonGrid from '@/Components/Game/GameSkeletonGrid.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import { Deferred, router } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { computed, onMounted, type PropType, ref } from 'vue'
import { ArrowRightIcon } from '@heroicons/vue/20/solid/index.js'
import { useFormatNumber } from '@/Composables/useFormatNumber.ts'
import { MagnifyingGlassIcon, MicrophoneIcon } from '@heroicons/vue/24/outline'
import { MicrophoneIcon as MicrophoneIconSolid } from '@heroicons/vue/24/solid'

const props = defineProps({
    count: {
        type: Object as PropType<{
            articles: number,
            games: number,
            users: number,
            all: number
        }>,
        default: () => ({
            articles: null,
            games: null,
            users: null,
            all: null
        })
    },
    articles: Object,
    games: Object,
    users: Object,
    query: String,
    type: String,
    order: String,
    direction: String
})

const form = ref({
    query: props.query
})

const options = computed(() => [
    {
        value: 'all',
        label: 'All',
        link: useRoute('search.index', {
            query: form.value.query,
            type: 'all'
        }),
        count: useFormatNumber(props.count.all)
    },
    {
        value: 'articles',
        label: 'Articles',
        link: useRoute('search.index', {
            query: form.value.query,
            type: 'articles'
        }),
        count: useFormatNumber(props.count.articles)
    },
    {
        value: 'users',
        label: 'Users',
        link: useRoute('search.index', {
            query: form.value.query,
            type: 'users'
        }),
        count: useFormatNumber(props.count.users)
    },
    {
        value: 'games',
        label: 'Games',
        link: useRoute('search.index', {
            query: form.value.query,
            type: 'games'
        }),
        count: useFormatNumber(props.count.games)
    }
])

const searchInput = ref(null)

function submitSearch () {
    router.get(useRoute('search.index', {
        query: form.value.query,
        type: props.type
    }))
}

function blurInput () {
    searchInput.value.blur()
}

const recognition = ref(null)
const talking = ref(false)

function toggleVoiceSearch () {
    if (recognition.value) {
        if (talking.value) {
            recognition.value.stop()
            talking.value = false
        } else {
            talking.value = true
            recognition.value.start()
        }
    }
}

const isSpeechEnabled = ref(false)
async function checkMicrophonePermission () {
    try {
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' })
        isSpeechEnabled.value = permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'

        permissionStatus.onchange = () => {
            isSpeechEnabled.value = permissionStatus.state === 'granted' || permissionStatus.state === 'prompt'
        }
    } catch (error) {
        console.error('Permission API error:', error)
    }
}

onMounted(() => {
    if ('webkitSpeechRecognition' in window) {
        // eslint-disable-next-line new-cap,no-undef
        recognition.value = new webkitSpeechRecognition()
        recognition.value.continuous = false
        recognition.value.interimResults = false
        recognition.value.lang = 'en-US'

        recognition.value.onresult = (event) => {
            form.value.query = event.results[0][0].transcript
            talking.value = false
            submitSearch()
        }

        recognition.value.onerror = (event) => {
            console.error(event.error)
        }

        checkMicrophonePermission()
    } else {
        alert('Speech recognition not supported in this browser.')
    }
})
</script>

<template>
    <div>
        <div class="container p-5 md:max-w-[unset] md:p-4">
            <div
                class="relative mb-2 rounded-xl py-8 text-white shadow-sm bg-primary md:py-18">
                <div class="container relative mx-auto">
                    <div class="flex flex-col items-start gap-2 xs:flex-row xs:items-center xs:justify-between xs:gap-0">
                        <div class="w-full">
                            <Breadcrumbs
                                class="mb-4"
                                :breadcrumbs="[
                                    { title: 'Home', url: useRoute('home') },
                                    { title: 'Search' }
                                ]" />
                            <div class="w-full">
                                <form
                                    class="relative w-full max-w-md"
                                    @submit.prevent="submitSearch">
                                    <input
                                        ref="searchInput"
                                        v-model="form.query"
                                        autofocus
                                        class="w-full border-b border-white/50 bg-transparent selection:bg-white py-2 pr-12 text-2xl font-bold selection:text-zinc-900 transition-colors placeholder-white/75 font-heading focus:border-white focus:outline-0 focus:ring-0 dark:border-white"
                                        placeholder="Search..."
                                        type="text"
                                        @keyup.esc="blurInput">
                                    <div class="absolute top-2 right-2 flex items-center gap-2">
                                        <button
                                            v-if="isSpeechEnabled"
                                            v-tooltip="'Start voice search'"
                                            type="button"
                                            :class="[talking ? 'text-red-400 animate-pulse' : 'text-white']"
                                            @click="toggleVoiceSearch">
                                            <component
                                                :is="talking ? MicrophoneIconSolid : MicrophoneIcon"
                                                class="w-7" />
                                        </button>
                                        <button
                                            class="cursor-pointer"
                                            type="submit">
                                            <span class="sr-only">Submit search</span>
                                            <MagnifyingGlassIcon class="w-8" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            v-if="query"
            class="md:container">
            <TabLinks
                mobile-friendly
                preserve-scroll
                prefetch
                :current="type"
                :tabs="options" />
        </div>

        <div
            v-if="!query"
            class="container mx-auto my-12 text-center">
            Search for articles, users, or games
        </div>
        <div
            v-if="query"
            class="container mx-auto my-8">
            <div class="flex flex-col gap-16">
                <div
                    v-if="['all', 'articles'].includes(type) && count.articles > 0"
                    class="flex flex-col">
                    <div class="flex items-center justify-between">
                        <SectionTitle>
                            Articles
                        </SectionTitle>
                        <Button
                            v-if="count.articles > articles?.data.length && type === 'all'"
                            :href="options.find(option => option.value === 'articles').link"
                            variant="text"
                            prefetch
                            :icon="ArrowRightIcon">
                            See more
                        </Button>
                    </div>
                    <Deferred
                        data="articles">
                        <template #fallback>
                            <ArticleSkeletonGrid
                                :contain="false"
                                :number="4" />
                        </template>

                        <ArticleGrid
                            :pagination="type === 'articles'"
                            :pagination-options="{
                                preserveScroll: true,
                                only: ['articles']
                            }"
                            :articles="articles" />
                    </Deferred>
                </div>

                <div
                    v-if="['all', 'users'].includes(type) && count.users > 0"
                    class="flex flex-col">
                    <div class="flex items-center justify-between">
                        <SectionTitle>
                            Users
                        </SectionTitle>
                        <Button
                            v-if="count.users > users?.data.length && type === 'all'"
                            :href="options.find(option => option.value === 'users').link"
                            variant="text"
                            prefetch
                            :icon="ArrowRightIcon">
                            See more
                        </Button>
                    </div>
                    <Deferred
                        data="users">
                        <template #fallback>
                            <ArticleSkeletonGrid
                                :contain="false"
                                :number="4" />
                        </template>

                        <UserGrid
                            v-if="['all', 'users'].includes(type) && count.games > 0"
                            :pagination="type === 'users'"
                            :pagination-options="{
                                preserveScroll: true,
                                only: ['users']
                            }"
                            :users="users">
                            <template #empty>
                                no results
                            </template>
                        </UserGrid>
                    </Deferred>
                </div>

                <div
                    v-if="['all', 'games'].includes(type)"
                    class="flex flex-col">
                    <div class="flex items-center justify-between">
                        <SectionTitle>
                            Games
                        </SectionTitle>
                        <Button
                            v-if="count.games > games?.data.length && type === 'all'"
                            :href="options.find(option => option.value === 'games').link"
                            variant="text"
                            prefetch
                            :icon="ArrowRightIcon">
                            See more
                        </Button>
                    </div>
                    <Deferred
                        data="games">
                        <template #fallback>
                            <GameSkeletonGrid
                                :number="type === 'games' ? 15 : 5"
                                game-width="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5"
                            />
                        </template>

                        <GameGrid
                            :pagination="type === 'games'"
                            game-width="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5"
                            :games="games" />
                    </Deferred>
                </div>
            </div>
        </div>
    </div>
</template>
