<script setup>
import Button from '@/Components/UI/Button.vue'
import AuthLayout from '@/Layouts/AuthLayout.vue'
import InputText from '@/Components/Input/InputText.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import MinimalAuthLayout from '@/Layouts/MinimalAuthLayout.vue'
import { nextTick, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'

const recovery = ref(false)

const form = useForm({
    code: '',
    recovery_code: ''
})

const recoveryCodeInput = ref(null)
const codeInput = ref(null)

const toggleRecovery = async () => {
    recovery.value ^= true

    await nextTick()

    if (recovery.value) {
        // recoveryCodeInput.value.focus()
        form.code = ''
    } else {
        // codeInput.value.focus()
        form.recovery_code = ''
    }
}

const submit = () => {
    form.post(useRoute('two-factor.login'))
}

defineOptions({ layout: AuthLayout })
</script>

<template>
    <div>
        <SectionTitle font-size="md:text-3xl">
            2FA Confirmation
        </SectionTitle>

        <div class="my-4 text-sm text-gray-600">
            <template v-if="! recovery">
                Please confirm access to your account by entering the authentication code provided by your
                authenticator
                application.
            </template>

            <template v-else>
                Please confirm access to your account by entering one of your emergency recovery codes.
            </template>
        </div>

        <form @submit.prevent="submit">
            <div v-if="! recovery">
                <InputText
                    ref="codeInput"
                    v-model="form.code"
                    :error="form.errors.code"
                    autocomplete="one-time-code"
                    autofocus
                    inputmode="numeric"
                    label="Code"
                />
            </div>

            <div v-else>
                <InputText
                    id="recovery_code"
                    ref="recoveryCodeInput"
                    v-model="form.recovery_code"
                    :error="form.errors.recovery_code"
                    autocomplete="one-time-code"
                    label="Recovery code"
                />
            </div>

            <div class="mt-4 flex items-center justify-between">
                <Button
                    variant="text"
                    @click.prevent="toggleRecovery">
                    <template v-if="! recovery">
                        Use a recovery code
                    </template>

                    <template v-else>
                        Use an authentication code
                    </template>
                </Button>

                <Button
                    type="submit"
                    :disabled="form.processing">
                    Log in
                </Button>
            </div>
        </form>
    </div>
</template>
