<script setup lang="ts">
import Header from '@/Components/UI/Header.vue'
import Button from '@/Components/UI/Button.vue'
import TagCard from '@/Components/Tag/TagCard.vue'
import GamePad from '@/Components/Icon/GamePad.vue'
import UserCard from '@/Components/User/UserCard.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import GamePadFilled from '@/Components/Icon/GamePadFilled.vue'
import CompassFilled from '@/Components/Icon/CompassFilled.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import type { Tag } from '@/Types/Models/Tag'
import type { User } from '@/Types/Models/User'
import type { Game } from '@/Types/Models/Game'
import { Link, Deferred } from '@inertiajs/vue3'
import { TagIcon } from '@heroicons/vue/20/solid'
import type { PageHeader } from '@/Types/PageHeader'
import { useRoute } from '@/Composables/useRoute.ts'
import { computed, type PropType, ref, watch } from 'vue'
import { useIsBreakpoint } from '@/Composables/useIsBreakpoint.ts'
import { useFormatUsername } from '@/Composables/useFormatUsername.js'
import { AdjustmentsHorizontalIcon, ChevronDownIcon, ChevronUpIcon, UserGroupIcon, TagIcon as TagIconOutline } from '@heroicons/vue/24/outline'

type filterType = 'user' | 'tag' | 'game'

const props = defineProps({
    articles: [Array, Object] as PropType<any>,
    header: Object as PropType<PageHeader>,
    filterType: String as PropType<filterType>,
    filterQuery: String,
    users: Array as PropType<User[]>,
    tags: Array as PropType<Tag[]>,
    games: Array as PropType<Game[]>
})

const activeFilterType = ref<filterType>(props.filterType)
const activeFilterQuery = ref<string>(props.filterQuery)

const currentGame = computed(() => {
    return props.games.find(game => game.id === parseInt(activeFilterQuery.value)) || null
})

const currentUser = computed(() => {
    return props.users.find(user => user.username === activeFilterQuery.value) || null
})

const currentTag = computed(() => {
    return props.tags.find(tags => tags.slug === activeFilterQuery.value) || null
})

const updateData = ['articles', 'filterType', 'filterQuery']

function isActive (type: filterType | 'all', filter?: User | Tag | Game) {
    if (type === 'all') {
        return activeFilterType.value === null && activeFilterQuery.value === null
    }

    if (type !== activeFilterType.value) {
        return false
    }

    if (type === 'user') {
        return activeFilterQuery.value === filter.username
    }

    if (type === 'tag') {
        return activeFilterQuery.value === filter.slug
    }

    if (type === 'game') {
        return activeFilterQuery.value === filter.id.toString()
    }

    return false
}

const emptyStateIcon = computed(() => {
    if (activeFilterType.value === 'user') {
        return UserGroupIcon
    }

    if (activeFilterType.value === 'tag') {
        return TagIconOutline
    }

    if (activeFilterType.value === 'game') {
        return GamePad
    }
})

const isMobile = useIsBreakpoint('md')
const open = ref(true)

function clearFilter () {
    activeFilterType.value = null
    activeFilterQuery.value = null
}

const classes = computed(() => {
    return {
        base: 'rounded-lg flex px-4 py-2.5 w-full items-center gap-2 text-sm select-none',
        active: 'bg-primary/10 text-primary',
        default: 'hover:bg-zinc-200',
        icon: 'w-6 shrink-0 aspect-square overflow-hidden rounded-md flex items-center bg-primary text-white justify-center'
    }
})

function filter (type: filterType, filter: string | number) {
    activeFilterType.value = type
    activeFilterQuery.value = filter.toString()
}

const isFilterable = computed(() => {
    return props.users.length > 0 || props.tags.length > 0 || props.games.length > 0
})

watch(isMobile, () => {
    open.value = !isMobile.value
})
</script>

<template>
    <div class="pb-12">
        <Header :header="header">
            <template #button>
                <Button
                    variant="white"
                    :icon="AdjustmentsHorizontalIcon"
                    icon-first
                    :href="useRoute('dashboard.following')">
                    Manage following
                </Button>
            </template>
        </Header>

        <div class="container mx-auto flex w-full flex-col md:flex-row md:gap-12">
            <div
                v-if="isFilterable"
                class="flex flex-col md:-mx-4 md:w-1/5">
                <div class="mb-4 flex w-full md:hidden">
                    <Button
                        variant="white"
                        class="w-full"
                        :icon="open ? ChevronUpIcon : ChevronDownIcon"
                        @click="open = !open">
                        Filter
                    </Button>
                </div>
                <AccordionTransition>
                    <div v-show="open">
                        <ul class="mb-8">
                            <li>
                                <Link
                                    :only="updateData"
                                    :class="[
                                        classes.base,
                                        isActive('all') ? classes.active : classes.default
                                    ]"
                                    :href="useRoute('articles.following')"
                                    @click="clearFilter">
                                    <div :class="classes.icon">
                                        <CompassFilled class="w-4" />
                                    </div>
                                    All
                                </Link>
                            </li>
                        </ul>

                        <div class="flex flex-col gap-8 overflow-auto max-h-128">
                            <ul v-if="users.length">
                                <li class="px-4 text-sm font-semibold">
                                    users
                                </li>
                                <li
                                    v-for="user in users"
                                    :key="user.id">
                                    <Link
                                        :only="updateData"
                                        :class="[
                                            classes.base,
                                            isActive('user', user) ? classes.active : classes.default
                                        ]"
                                        :href="useRoute('articles.following', {
                                            type: 'user',
                                            query: user.username
                                        })"
                                        @click="filter('user', user.username)">
                                        <div class="flex items-center gap-2">
                                            <UserAvatar
                                                :user
                                                class="w-6 rounded-full ring-1 ring-zinc-200" />
                                            <div class="flex items-center gap-2 truncat">
                                                <div>
                                                    {{ user.name }}
                                                </div>
                                                <div class="text-xs">
                                                    {{ useFormatUsername(user) }}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>

                            <ul v-if="tags.length">
                                <li class="px-4 text-sm font-semibold">
                                    tags
                                </li>
                                <li
                                    v-for="tag in tags"
                                    :key="tag.id">
                                    <Link
                                        :only="updateData"
                                        :class="[
                                            classes.base,
                                            isActive('tag', tag) ? classes.active : classes.default
                                        ]"
                                        :href="useRoute('articles.following', {
                                            type: 'tag',
                                            query: tag.slug
                                        })"
                                        @click="filter('tag', tag.slug)">
                                        <div
                                            :class="classes.icon"
                                            :style="{backgroundColor: tag.metadata.colour}">
                                            <img
                                                v-if="tag.icon"
                                                :alt="`Icon image for ${tag.name}`"
                                                :src="tag.icon"
                                                class="h-full w-full object-cover">
                                            <TagIcon
                                                v-else
                                                class="w-4" />
                                        </div>
                                        <div class="truncate">
                                            {{ tag.name }}
                                        </div>
                                    </Link>
                                </li>
                            </ul>

                            <ul v-if="games.length">
                                <li class="px-4 text-sm font-semibold">
                                    games
                                </li>
                                <li
                                    v-for="game in games"
                                    :key="game.id">
                                    <Link
                                        :only="updateData"
                                        :class="[
                                            classes.base,
                                            isActive('game', game) ? classes.active : classes.default
                                        ]"
                                        :href="useRoute('articles.following', {
                                            type: 'game',
                                            query: game.id
                                        })"
                                        @click="filter('game', game.id)">
                                        <div
                                            :class="classes.icon"
                                            :style="{backgroundColor: game.primary_color }">
                                            <GamePadFilled class="w-4" />
                                        </div>
                                        <div class="truncate">
                                            {{ game.name }}
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </AccordionTransition>
            </div>
            <div
                class="w-full"
                :class="isFilterable ? 'md:w-4/5' : ''">
                <div class="-mt-2">
                    <div
                        v-if="activeFilterType !== null"
                        class="mt-2 mb-4 border-b border-zinc-200 pb-5">
                        <GameCardHorizontal
                            v-if="activeFilterType === 'game' && currentGame"
                            inline
                            :game="currentGame" />

                        <UserCard
                            v-if="activeFilterType === 'user' && currentUser"
                            inline
                            :user="currentUser" />

                        <TagCard
                            v-if="activeFilterType === 'tag' && currentTag"
                            inline
                            :tag="currentTag" />
                    </div>
                    <Deferred
                        data="articles">
                        <template #fallback>
                            <ArticleSkeletonGrid
                                :article-width="isFilterable ? 'sm:w-1/2 lg:w-1/3' : 'md:w-1/2 lg:w-1/3 xl:w-1/4'"
                                :number="8" />
                        </template>

                        <ArticleGrid
                            :skeleton-count="8"
                            :article-width="isFilterable ? 'sm:w-1/2 lg:w-1/3' : 'md:w-1/2 lg:w-1/3 xl:w-1/4'"
                            :articles="articles">
                            <template #empty>
                                <EmptyState
                                    class="md:px-6 md:py-12"
                                    :icon="emptyStateIcon"
                                    title="No articles found"
                                    :description="`Discover more and follow your favourite writers, tags, and games.`">
                                    <template #button>
                                        <div class="flex">
                                            <Button
                                                :icon="CompassFilled"
                                                icon-first
                                                variant="primary"
                                                :href="useRoute('articles.index')">
                                                Explore articles
                                            </Button>
                                        </div>
                                    </template>
                                </EmptyState>
                            </template>
                        </ArticleGrid>
                    </Deferred>
                </div>
            </div>
        </div>
    </div>
</template>
