import { onMounted, type Ref, ref } from 'vue'
import type { User } from '@/Types/Models/User'
import { useMakeRequest } from '@/Composables/useMakeRequest'
import { useRoute } from '@/Composables/useRoute.ts'

interface ResponseData {
    count: number;
}

export function useCountArticles (user: User, initialCount: number | null = null): Ref<number | null> {
    const articleCount = ref<number | null>(initialCount)

    onMounted(async () => {
        try {
            const response: ResponseData = await useMakeRequest(
                useRoute('api.users.articles.count', { user: user.username }),
                'GET'
            )
            articleCount.value = response.count
        } catch (error) {
            articleCount.value = 0
        }
    })

    return articleCount
}
