<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import MentionableTextarea from '@/Components/UI/MentionableTextarea.vue'
import InputImageSelector from '@/Components/Input/InputImageSelector.vue'
import { ref } from 'vue'
import { Mentionable } from 'vue-mention'
import { Link, useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest'
import { useCurrentUser } from '@/Composables/useCurrentUser'

const imageSelectorKey = ref<number>(0)

interface FormData {
    content: string;
    images: { file: File, url: string | ArrayBuffer | null }[];
}

const form = useForm<FormData>({
    content: '',
    images: []
})

const emit = defineEmits(['reload'])

const submit = () => {
    form.post(useRoute('posts.store'), {
        preserveScroll: true,
        onSuccess: () => {
            reset()
            emit('reload')
        }
    })
}

function reset () {
    form.reset()
    form.clearErrors()
    imageSelectorKey.value++
}

const limit = 6

const handleDrop = (event: DragEvent) => {
    const files = event.dataTransfer?.files
    if (files && files.length > 0) {
        processFiles(files)
    }
}

const imageSelector = ref<InstanceType<typeof InputImageSelector> | null>(null)

const processFiles = (files: FileList) => {
    const currentImageCount = form.images.length
    const allowedFilesCount = Math.min(files.length, limit - currentImageCount)

    for (let i = 0; i < allowedFilesCount; i++) {
        const file = files[i]
        if (!file.type.startsWith('image/') || file.type === 'image/svg+xml') {
            continue
        }

        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            form.images.push({ file, url: e.target?.result })
        }
        reader.readAsDataURL(file)
    }
}

function handleEnter () {
    if (!form.processing && (form.content)) {
        submit()
    }
}
</script>

<template>
    <Card>
        <form
            @submit.prevent="submit()"
            @dragover.prevent
            @drop.prevent="handleDrop">
            <div class="flex items-start gap-4">
                <component
                    :is="Link"
                    :href="useRoute('user.show', useCurrentUser())"
                    class="relative flex w-8 shrink-0 items-center avatar-container md:w-12">
                    <UserAvatar
                        :user="useCurrentUser()"
                        class="w-full rounded-full ring-1 ring-slate-900/10"
                        size="small" />
                </component>
                <MentionableTextarea
                    v-model="form.content"
                    class="w-full"
                    :error="form.errors?.content"
                    textarea-class="border-0 !shadow-none p-0 md:py-2 md:px-0"
                    placeholder="What's happening"
                    @enter="handleEnter"
                />
            </div>

            <div class="ml-12 flex pt-2 md:ml-16">
                <InputImageSelector
                    ref="imageSelector"
                    :key="imageSelectorKey"
                    v-model="form.images"
                    :limit="limit" />
            </div>
            <div
                class="ml-auto flex w-full items-center justify-end gap-2"
                :class="[form.images.length > 0 ? '' : '-mt-9']">
                <Button
                    v-if="form.isDirty"
                    variant="white"
                    type="button"
                    :loading="form.processing"
                    @click="reset">
                    Cancel
                </Button>
                <Button
                    type="button"
                    :disabled="form.images.length === 0 && !form.content"
                    :loading="form.processing"
                    @click="submit">
                    Post
                </Button>
            </div>
        </form>
    </Card>
</template>
