import { onMounted, type Ref, ref } from 'vue'
import type { User } from '@/Types/Models/User'
import { useMakeRequest } from '@/Composables/useMakeRequest'
import { useRoute } from '@/Composables/useRoute.ts'

export function useArticleViewsCount (user: User, initialCount: number | null = null): Ref<number | null> {
    const articleViewsCount = ref<number | null>(initialCount)

    onMounted(() => {
        return useMakeRequest(useRoute('api.users.articles.views.count', { user: user.username }), 'GET').then(response => {
            articleViewsCount.value = response.count
        })
    })

    return articleViewsCount
}
