<script setup>
import Button from '@/Components/UI/Button.vue'
import Spinner from '@/Components/UI/Spinner.vue'
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputAdvancedImageSelector from '@/Components/Input/InputAdvancedImageSelector.vue'
import { ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { PhotoIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useHasFileInput } from '@/Composables/useHasFileInput.js'

defineProps({
    defaultThumbnail: String,
    error: String
})

const form = useForm({
    image: null
})

const emit = defineEmits(['selected', 'removed'])

const fileSelector = ref(null)
const selectedImage = ref(null)
const preview = ref(null)
const loading = ref(false)
const {
    isDragover,
    drop,
    dragover,
    dragleave,
    removed
} = useHasFileInput(fileSelector, form, selectedImage)

function imageUploaded (file) {
    form.image = file[0]
    loading.value = false
    emit('selected', file[0])
}

function previews (files) {
    preview.value = files[0]
}

function remove () {
    preview.value = null
    emit('removed')
}

function uploading () {
    loading.value = true
}

function handleRemove () {
    removed()
    remove()
}
</script>
<template>
    <div class="w-full">
        <div
            :class="error ? 'border-red-500' : 'border-transparent'"
            class="relative w-full"
            @dragleave="dragleave"
            @dragover="dragover"
            @drop="drop">
            <div
                v-if="loading"
                class="mb-2 flex gap-4">
                <span class="text-sm text-slate-950/75">Uploading</span>
                <Spinner class="w-4 text-transparent" />
            </div>
            <div
                v-if="preview || defaultThumbnail"
                :class="[isDragover ? 'opacity-75 blur-md' : 'opacity-100', loading ? 'opacity-50' : 'opacity-100']"
                :style="`background-image:url('${preview ? preview : defaultThumbnail}')`"
                class="relative w-full rounded-xl border bg-gray-100 bg-cover bg-center bg-no-repeat aspect-[16/9]" />

            <div
                :class="isDragover ? 'z-20 opacity-100' : 'scale-100 opacity-0'"
                class="pointer-events-none absolute top-0 left-0 flex h-full w-full items-center justify-center rounded border-2 border-dashed transition-all border-primary bg-primary/10">
                <span class="text-primary">Drop image here</span>
            </div>

            <button
                v-if="defaultThumbnail"
                class="absolute top-4 right-4 flex aspect-square w-8 items-center justify-center rounded-full bg-black/50 text-white transition-colors hover:bg-black"
                type="button"
                @click="remove">
                <XMarkIcon class="h-4 w-4 text-white" />
            </button>

            <InputAdvancedImageSelector
                :key="selectedImage"
                :displayed-image="preview ? preview : defaultThumbnail"
                :selected-file="selectedImage"
                :allow-cropping="false"
                @selected="previews"
                @uploaded="imageUploaded"
                @uploading="uploading">
                <template #trigger>
                    <div
                        v-if="!preview && !defaultThumbnail"
                        class="w-full"
                        :class="!isDragover ? 'z-20 opacity-100' : 'scale-100 opacity-0'">
                        <div class="flex w-full flex-col items-center justify-center gap-2 rounded-lg border-2 border-dashed border-zinc-200 py-8 text-sm text-zinc-500 transition-colors group hover:border-primary hover:text-primary">
                            <PhotoIcon class="w-12" />
                            <div class="opacity-80 transition-opacity group-hover:opacity-100">
                                Drop an image here or click to upload
                            </div>
                        </div>
                    </div>
                </template>
            </InputAdvancedImageSelector>
        </div>
        <ErrorText
            v-if="error"
            :error="error" />
    </div>
</template>
