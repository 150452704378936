<script setup lang="ts">
import GameActions from '@/Components/Game/GameActions.vue'
import GameThumbnail from '@/Components/Game/GameThumbnail.vue'
import GameFollowButton from '@/Components/Game/GameFollowButton.vue'
import GamePlatformIcons from '@/Components/Game/GamePlatformIcons.vue'
import { cn } from '@/utils.ts'
import { Link } from '@inertiajs/vue3'
import { type PropType, useAttrs } from 'vue'
import type { Game } from '@/Types/Models/Game'

defineProps({
    game: Object as PropType<Game>,
    newTab: {
        type: Boolean,
        default: false
    },
    actions: {
        type: Boolean,
        default: true
    },
    displayReleaseDate: {
        type: Boolean,
        default: true
    },
    small: {
        type: Boolean,
        default: false
    },
    useSrcSet: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        default: 'medium'
    },
    gameTitleSize: {
        type: String,
        default: 'text-base'
    }
})

const attrs = useAttrs()
</script>

<template>
    <div
        :class="cn(
            'relative flex h-full flex-col overflow-hidden rounded-xl border-black/10 group dark:border-white/10',
            small ? 'border-0 px-3' : 'bg-white border p-1 pb-4',
            attrs.class
        )"
        class="relative flex h-full flex-col overflow-hidden rounded-xl border-black/10 group dark:border-white/10">
        <div
            v-if="!small && game.primary_color"
            :style="{
                backgroundColor: game.primary_color,
            }"
            class="absolute inset-0 z-0 opacity-25" />

        <div
            v-if="!small"
            class="absolute top-0 right-0 z-10 flex w-full justify-end p-2 transition-opacity group-hover:opacity-100"
            :class="game.isFollowing ? 'opacity-100' : 'opacity-0'">
            <GameFollowButton
                v-if="actions"
                class="z-20"
                :small="small"
                :game="game" />
        </div>
        <component
            :is="newTab ? 'a' : Link"
            :target="newTab ? '_blank' : null"
            :href="game.permalink">
            <GameThumbnail
                :game
                :size
                :use-src-set="useSrcSet" />
        </component>
        <div
            class="isolate mt-0"
            :class="small ? '' : 'px-2'">
            <h2
                :class="small ? 'line-clamp-2 text-sm leading-4' : 'line-clamp-1 text-lg'"
                class="mt-2 font-semibold text-zinc-900 text-pretty dark:text-white">
                <component
                    :is="newTab ? 'a' : Link"
                    :target="newTab ? '_blank' : null"
                    :href="game.permalink"
                    itemprop="headline"
                    :class="small ? '' : 'font-heading mr-2'"
                    class="text-pretty focus:outline-none">
                    {{ game.name }}
                </component>
            </h2>
            <div
                :class="small ? 'flex-col mt-1' : 'flex-row mt-1'"
                class="isolate z-10 flex flex-wrap justify-between gap-2">
                <p
                    class="flex shrink-0 text-xs">
                    <span class="text-zinc-900/75 dark:text-white/75">{{ game.release_date_nice }}</span>
                </p>
                <GamePlatformIcons
                    v-if="game.platforms && !small"
                    :class="small ? 'mt-1' : ''"
                    :game="game" />
            </div>
        </div>
    </div>
</template>
