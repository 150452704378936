<script setup lang="ts">
import Pagination from '@/Components/UI/Pagination.vue'
import ProfileLayout from '@/Layouts/ProfileLayout.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import { type PropType, ref } from 'vue'
import { Deferred } from '@inertiajs/vue3'
import type { Article } from '@/Types/Models/Article.js'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { useHasPagination } from '@/Composables/useHasPagination.js'

defineProps({
    user: Object,
    articleLimit: Number,
    articles: Object as PropType<PaginatedItems<Article>>
})

const articlesContainer = ref(null)

defineOptions({ layout: ProfileLayout })
</script>

<template>
    <div>
        <Deferred data="articles">
            <template #fallback>
                <div class="skeleton-animation">
                    <div class="h-6 w-48 rounded bg-primary/10" />
                </div>
                <ArticleSkeletonGrid
                    :number="3"
                    article-width="md:w-1/2 lg:w-1/3" />
            </template>

            <SectionTitle font-size="text-xl">
                Articles
            </SectionTitle>
            <ArticleGrid
                article-width="md:w-1/2 lg:w-1/3"
                :skeleton-count="articleLimit"
                :articles="articles.data"
                title="All gaming articles">
                <template #empty>
                    <SectionDescription>
                        This user has not published any articles yet.
                    </SectionDescription>
                </template>
            </ArticleGrid>
            <Pagination
                v-if="useHasPagination<Article>(articles)"
                :show-count="true"
                preserve-scroll
                :data="articles" />
        </Deferred>
    </div>
</template>
