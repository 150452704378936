<script setup lang="ts">
import Modal from '@/Components/UI/Modal.vue'
import Button from '@/Components/UI/Button.vue'
import FsLightbox from '@/Plugins/fslightbox/v3'
import useEmitter from '@/Composables/useEmitter'
import Spinner from '@/Components/UI/Spinner.vue'
import TagList from '@/Components/Tag/TagList.vue'
import GameCard from '@/Components/Game/GameCard.vue'
import ModelMapReverse from '@/Configs/ModelMapReverse.ts'
import Screenshots from '@/Components/Game/Screenshots.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import GameActions from '@/Components/Game/GameActions.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import RelatedGames from '@/Components/Game/RelatedGames.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import CommentList from '@/Components/Comment/CommentList.vue'
import GameDataTable from '@/Components/Game/GameDataTable.vue'
import GameThumbnail from '@/Components/Game/GameThumbnail.vue'
import GameWebsiteLinks from '@/Components/Game/GameWebsiteLinks.vue'
import GameSkeletonGrid from '@/Components/Game/GameSkeletonGrid.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import type { Tag } from '@/Types/Models/Tag'
import type { Game } from '@/Types/Models/Game'
import type { Genre } from '@/Types/Models/Genre'
import { RssIcon } from '@heroicons/vue/24/solid'
import type { ImageUrls } from '@/Types/ImageUrls'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import type { Platform } from '@/Types/Models/Platform'
import { router, Link, Deferred } from '@inertiajs/vue3'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { useMakeRequest } from '@/Composables/useMakeRequest'
import { nextTick, onMounted, type PropType, ref } from 'vue'
import { useIsBreakpoint } from '@/Composables/useIsBreakpoint.ts'

const props = defineProps({
    game: Object as PropType<Game>,
    parent: Object as PropType<Game>,
    primaryGenre: [Object as PropType<Genre>, null],
    comments: [Array as PropType<Comment[]>, null],
    hasRelatedGames: Boolean,
    hasChildren: Boolean,
    alternativeNames: Array as PropType<string[]>,
    screenshots: Array as PropType<ImageUrls[]>,
    relatedGames: Array as PropType<Game[]>,
    children: Array as PropType<Game[]>,
    genres: Array as PropType<Genre[]>,
    tags: Array as PropType<Tag[]>,
    platforms: Array as PropType<Platform[]>,
    articles: Array as PropType<Article[]>
})

const moreInfoModalOpen = ref<boolean>(false)
const showCoverLightbox = ref<boolean>(false)
const showScreenshotLightbox = ref<boolean>(false)
const screenshotLightboxSelected = ref<number>(0)

function openLightboxOnSlide (number: number) {
    number = number + 1
    screenshotLightboxSelected.value = number
    showScreenshotLightbox.value = !showScreenshotLightbox.value
}

const descriptionExpanded = ref<boolean>(false)

function expandDescription () {
    descriptionExpanded.value = true
}

function lazyload () {
    router.reload({
        only: ['platforms', 'tags', 'genres', 'comments'],
        onSuccess: () => {
            const hash = window.location.hash
            const commentId = hash.substring(1)
            const element = document.getElementById(commentId)

            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                element.classList.add('highlight')
            }
        }
    })
}

function loadComments () {
    router.reload({
        only: ['comments']
    })
}

onMounted(() => {
    nextTick(() => {
        lazyload()
    })

    useEmitter.on('comment:created', () => {
        loadComments()
    })

    useEmitter.on('comment:deleted', () => {
        loadComments()
    })
})

const isMobile = useIsBreakpoint('lg')
</script>

<template>
    <div>
        <div
            v-lazy:background-image="game.featured_image"
            :style="{
                backgroundColor: game.primary_color,
            }"
            class="relative flex h-auto bg-cover bg-center px-4 py-8 md:mx-4 md:mt-4 md:flex md:rounded-xl md:px-0 xl:h-128">
            <div class="absolute top-8 right-8 z-10 md:top-4 md:right-4">
                <GameActions
                    :key="`desktop-${isMobile}`"
                    share
                    :small="isMobile"
                    :game="game" />
            </div>

            <div class="absolute top-0 left-0 h-full w-full bg-slate-900/30 backdrop-blur-2xl md:rounded-xl" />
            <div class="relative flex h-full flex-col justify-end text-white md:container xl:h-auto">
                <div class="mx-auto flex w-full flex-col xl:max-w-7xl xl:flex-row xl:pt-20">
                    <div class="flex w-full flex-col items-start sm:flex-row sm:gap-4 md:gap-8 lg:items-end lg:gap-12">
                        <div class="relative flex w-1/3 xl:shrink-0">
                            <GameThumbnail
                                :game
                                size="large"
                                class="left-0 shadow-xl md:rounded-xl xl:absolute xl:-bottom-40"
                            />
                        </div>
                        <div class="w-full pt-4 md:w-2/3 lg:pt-1">
                            <div class="flex flex-col-reverse justify-between gap-2 md:gap-4 lg:flex-row lg:items-center lg:gap-0">
                                <div class="mb-1 flex flex-wrap items-center gap-1 text-sm">
                                    <Link
                                        class="hover:underline"
                                        :href="useRoute('games.index')">
                                        Games
                                    </Link>
                                    <ChevronRightIcon class="h-4 w-4" />
                                    <Link
                                        v-if="primaryGenre"
                                        class="hover:underline"
                                        :href="useRoute('genres.show', primaryGenre.slug)">
                                        {{ primaryGenre.name }}
                                    </Link>
                                    <Link
                                        v-else
                                        class="hover:underline"
                                        :href="game.permalink">
                                        {{ game.name }}
                                    </Link>
                                </div>
                            </div>
                            <h1 class="text-2xl font-heading text-pretty md:text-4xl lg:text-5xl">
                                {{ game.name }}
                            </h1>
                            <Screenshots
                                class="md:mt-4"
                                :screenshots="screenshots"
                                @screenshot-click="openLightboxOnSlide" />
                            <GameWebsiteLinks
                                :game="game"
                                class="mt-4" />
                        </div>
                    </div>

                    <!--                    <Screenshots-->
                    <!--                        class="-mx-4 mt-4 block lg:hidden"-->
                    <!--                        :screenshots="screenshots"-->
                    <!--                        @screenshot-click="openLightboxOnSlide" />-->
                </div>
            </div>
        </div>

        <div class="mb-8 lg:container">
            <div class="mx-auto mt-8 flex max-w-7xl flex-col-reverse items-start gap-12 lg:mt-0 lg:flex-row">
                <div class="flex w-full flex-col gap-2 overflow-x-hidden px-4 lg:w-1/3 lg:px-0 xl:pt-36">
                    <GameDataTable
                        class="hidden pt-4 md:flex"
                        :platforms
                        :genres
                        :game
                        :tags
                        @open-modal="moreInfoModalOpen = true" />

                    <div
                        v-if="parent"
                        class="mt-4">
                        <SectionTitle>Parent game</SectionTitle>
                        <div class="-mx-2 mt-2 flex items-start">
                            <GameCard
                                small
                                :game="parent"
                                class="w-1/3"
                            />
                        </div>
                    </div>

                    <div
                        v-if="hasRelatedGames"
                        class="mt-4">
                        <Deferred data="relatedGames">
                            <template #fallback>
                                <GameSkeletonGrid
                                    :number="3" />
                            </template>

                            <RelatedGames
                                title="Related games"
                                :games="relatedGames" />
                        </Deferred>
                    </div>

                    <div
                        v-if="hasChildren"
                        class="mt-4">
                        <Deferred data="children">
                            <template #fallback>
                                <GameSkeletonGrid
                                    :number="3" />
                            </template>

                            <RelatedGames
                                title="DLCs and Expansions"
                                :games="children" />
                        </Deferred>
                    </div>
                </div>
                <div class="w-full px-4 lg:w-2/3 lg:pt-8">
                    <h3 class="text-sm font-bold">
                        About {{ game.name }}
                    </h3>
                    <SectionDescription class="mt-1">
                        {{ descriptionExpanded ? game.description :game.description.substring(0, 300) }}
                        <span v-if="!descriptionExpanded">
                            &hellip;
                            <Button
                                variant="text"
                                @click="expandDescription">
                                Read more
                            </Button>
                        </span>
                    </SectionDescription>

                    <GameDataTable
                        class="flex w-full pt-4 md:hidden"
                        :platforms
                        :genres
                        :game
                        :tags
                        :has-tags
                        @open-modal="moreInfoModalOpen = true" />

                    <div class="mt-8 flex items-center justify-between border-t pt-8">
                        <SectionTitle font-size="text-xl">
                            Articles
                        </SectionTitle>
                        <Button
                            target="_blank"
                            variant="text"
                            :icon="RssIcon"
                            icon-first
                            small
                            :href="useRoute('rss.game', game.slug)">
                            RSS Feed
                        </Button>
                    </div>

                    <Deferred data="articles">
                        <template #fallback>
                            <ArticleSkeletonGrid
                                :horizontal="true"
                                :number="2" />
                        </template>

                        <ArticleGrid
                            :articles="articles"
                            horizontal
                            :skeleton-count="2">
                            <template #empty>
                                <EmptyState
                                    class="md:px-6 md:py-12"
                                    title="There's nothing here"
                                    :description="`Try writing your own`">
                                    <template #button>
                                        <ArticleCreateButton
                                            :append="{game: game.identifier}"
                                            class="justify-center" />
                                    </template>
                                </EmptyState>
                            </template>
                        </ArticleGrid>
                    </Deferred>

                    <div class="mt-12">
                        <CommentList
                            id="comments"
                            :key="`comments-${commentsCount}`"
                            :form-data="{
                                commentable_id: game.identifier,
                                commentable_type: ModelMapReverse['Game']
                            }"
                            :commentable="game"
                            :comments="comments"
                            :count="commentsCount" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <FsLightbox
        v-if="screenshots && screenshots.length"
        :show-thumbs-on-mount="true"
        :slide="screenshotLightboxSelected"
        :sources="screenshots.map(screenshot => screenshot.full_webp)"
        :thumbs="screenshots.map(screenshot => screenshot.small_webp)"
        :toggler="showScreenshotLightbox" />

    <FsLightbox
        v-if="game.cover"
        :show-thumbs-on-mount="true"
        :sources="[game.cover.full_webp]"
        :thumbs="[game.cover.medium_webp]"
        :toggler="showCoverLightbox" />

    <Modal
        :show="moreInfoModalOpen"
        @close="moreInfoModalOpen = false">
        <template #title>
            {{ game.name }}
        </template>
        <template #content>
            <div class="max-w-none text-sm prose">
                <p
                    class="mb-6"
                    v-html="game.description" />

                <div v-if="game.storyline">
                    <h3>
                        Storyline
                    </h3>
                    <p
                        class="mb-6"
                        v-html="game.storyline" />
                </div>

                <div v-if="alternativeNames && alternativeNames.length">
                    <h3>
                        Alternative names
                    </h3>
                    <ul>
                        <li
                            v-for="name in alternativeNames"
                            :key="name">
                            {{ name }}
                        </li>
                    </ul>
                </div>

                <div v-if="game.companies && game.companies.length">
                    <h3>
                        All involved companies
                    </h3>
                    <ul>
                        <li
                            v-for="company in game.companies"
                            :key="company.name">
                            <component
                                :is="company.url ? 'a' : 'span'"
                                :href="company.url"
                                target="_blank"
                                rel="nofollow"
                                :class="company.url ? 'hover:underline' : ''">
                                {{ company.name }}
                            </component>
                        </li>
                    </ul>
                </div>

                <div v-if="platforms && platforms.length">
                    <h3>
                        Platforms
                    </h3>
                    <ul>
                        <li
                            v-for="platform in platforms"
                            :key="platform.name">
                            <Link
                                :href="useRoute('platforms.show', platform.slug)"
                                class="hover:underline">
                                {{ platform.name }}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="flex w-full items-center justify-end">
                <Button
                    type="button"
                    variant="primary"
                    @click="moreInfoModalOpen = false">
                    Okay
                </Button>
            </div>
        </template>
    </Modal>
</template>
