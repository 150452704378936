<script setup lang="ts">
import patternLogo from '~/app/patterns/logo.svg'
import { Link } from '@inertiajs/vue3'
import { useDateFormat } from '@vueuse/core'
import { onMounted, type PropType, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Platform } from '@/Types/Models/Platform'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest'

const props = defineProps({
    platform: {
        type: [Object as PropType<Platform>, Number, null],
        default: null
    },
    small: {
        type: Boolean,
        default: false
    },
    load: {
        type: Boolean,
        default: false
    },
    linkify: {
        type: Boolean,
        default: true
    }
})

const displayPlatform = ref<Platform>(null)

function formatDate (date: string) {
    return useDateFormat(date, 'D MMM YYYY', { locales: usePageProps().app.locale })
}

onMounted(() => {
    if (props.load && props.platform) {
        useMakeRequest(useRoute('api.game.platforms.single', props.platform), 'GET')
            .then(response => {
                displayPlatform.value = response
            })
    } else {
        displayPlatform.value = props.platform
    }
})
</script>

<template>
    <div>
        <div
            v-if="load && displayPlatform === null"
            class="flex w-full items-start gap-2 group">
            <div
                :class="[small ? 'w-16' : 'w-20']"
                class="relative aspect-square shrink-0 overflow-hidden rounded-lg border p-2 bg-secondary border-primary/10">
                <div
                    v-lazy:background="patternLogo"
                    class="absolute top-0 left-0 h-full w-full bg-secondary bg-[length:40px]" />
            </div>
            <div class="flex w-full flex-col justify-center gap-1 py-2 text-left skeleton-animation">
                <div class="h-4 w-48 rounded-lg bg-primary/10 dark:bg-slate-800" />
                <div class="h-2 w-24 rounded-lg bg-primary/10 dark:bg-slate-800" />
                <div class="h-2 w-12 rounded-lg bg-primary/10 dark:bg-slate-800" />
            </div>
        </div>
        <component
            :is="linkify ? Link : 'div'"
            v-if="displayPlatform !== null"
            :href="linkify ? displayPlatform.permalink : null"
            class="flex w-full items-center gap-2 group">
            <div
                :class="[small ? 'w-16' : 'w-18', displayPlatform.logo ? 'bg-white' : 'bg-secondary']"
                class="relative aspect-square shrink-0 overflow-hidden rounded-lg border p-2 border-primary/10">
                <img
                    v-if="displayPlatform.logo"
                    :src="displayPlatform.logo"
                    class="h-full w-full object-contain"
                    alt="Cover">
                <div
                    v-else
                    v-lazy:background="patternLogo"
                    class="absolute top-0 left-0 h-full w-full bg-secondary bg-[length:40px]" />
            </div>
            <div class="flex w-full flex-col justify-center py-2 text-left">
                <div
                    :class="linkify ? 'group-hover:text-primary ' : ''"
                    class="text-base transition-colors font-heading">
                    {{ displayPlatform.name }}
                </div>
                <p
                    v-if="displayPlatform.release_date"
                    class="text-xs text-zinc-900/50">
                    <span class="font-semibold">
                        {{ formatDate(displayPlatform.release_date) }}
                    </span>
                </p>
            </div>
        </component>
    </div>
</template>
