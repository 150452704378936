<script setup lang="ts">
import Card from '@/Components/UI/Card.vue'
import ModelMap from '@/Configs/ModelMap.ts'
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import Spinner from '@/Components/UI/Spinner.vue'
import TabLinks from '@/Components/UI/TabLinks.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import InputText from '@/Components/Input/InputText.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import InputToggle from '@/Components/Input/InputToggle.vue'
import InputSelect from '@/Components/Input/InputSelect.vue'
import GamePadFilled from '@/Components/Icon/GamePadFilled.vue'
import CommentSingle from '@/Components/Comment/CommentSingle.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import InputBasicSelect from '@/Components/Input/InputBasicSelect.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { type PropType, ref, watch } from 'vue'
import { FireIcon } from '@heroicons/vue/24/solid'
import { router, Deferred } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Comment } from '@/Types/Models/Comment'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useHasPagination } from '@/Composables/useHasPagination.ts'
import { MagnifyingGlassIcon, DocumentTextIcon, UserIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
    counts: Object as PropType<{ all: number, articles: number, games: number, posts: number }>,
    initialType: String,
    initialSearch: String,
    initialIncludeReplies: Boolean,
    comments: Object as PropType<PaginatedItems<Comment>>
})

const search = ref(props.initialSearch)
const includeReplies = ref(props.initialIncludeReplies)
const tabs = ref([
    {
        label: 'All',
        value: 'all',
        link: useRoute('dashboard.comments', {
            s: search.value,
            replies: includeReplies.value
        }),
        count: props.counts.all
    },
    {
        label: 'Articles',
        value: 'articles',
        link: useRoute('dashboard.comments', {
            type: 'articles',
            s: search.value,
            replies: includeReplies.value
        }),
        count: props.counts.articles
    },
    {
        label: 'Games',
        value: 'games',
        link: useRoute('dashboard.comments', {
            type: 'games',
            s: search.value,
            replies: includeReplies.value
        }),
        count: props.counts.games
    },
    {
        label: 'Posts',
        value: 'posts',
        link: useRoute('dashboard.comments', {
            type: 'posts',
            s: search.value,
            replies: includeReplies.value
        }),
        count: props.counts.posts
    }
])
const selectedType = ref(tabs.value.find(tab => tab.value === props.initialType) || tabs.value[0])

watch([search, includeReplies, selectedType], () => {
    console.log(selectedType.value)
    router.reload({
        data: {
            s: search.value,
            type: selectedType.value.value,
            replies: includeReplies.value
        },
        only: ['comments', 'counts'],
        onSuccess: (data) => {
            tabs.value = tabs.value.map(tab => {
                tab.count = props.counts[tab.value]
                return tab
            })
        }
    })
}, { deep: true })

const iconMap = {
    Article: DocumentTextIcon,
    Game: GamePadFilled,
    Post: UserIcon
}

function getIcon (comment: Comment) {
    return iconMap[ModelMap[comment.commentable_type]]
}

function commentTypeIs (type: string, comment: Comment) {
    return ModelMap[comment.commentable_type] === type
}

function getCommentableTitle (comment: Comment) {
    if (commentTypeIs('Article', comment)) {
        return comment.commentable?.title
    }
    if (commentTypeIs('Game', comment)) {
        return comment.commentable?.name
    }
    if (commentTypeIs('Post', comment)) {
        console.log(comment.commentable)
        return comment.commentable?.user.name
    }
}

defineOptions({
    layout: DashboardLayout,
    inheritAttrs: false
})
</script>

<template>
    <div>
        <div class="relative flex w-full flex-col md:flex-row">
            <DashboardTitle
                class="w-full"
                title="Comments"
                :description="usePageProps().meta.description" />
        </div>

        <div
            class="flex flex-col justify-between gap-4 lg:flex-row lg:gap-0 xl:items-center">
            <div class="flex w-full flex-col gap-2 lg:w-8/12 lg:flex-col-reverse xl:flex-row xl:items-center">
                <div class="w-full xl:w-1/4">
                    <InputText
                        v-model="search"
                        input-type="search"
                        :clearable="true"
                        icon-classes="w-4 text-slate-900/75 dark:text-white/75 group-focus-within:text-slate-900 dark:group-focus-within:text-white"
                        placeholder="Search...">
                        <template #icon>
                            <MagnifyingGlassIcon />
                        </template>
                    </InputText>
                </div>
                <TabLinks
                    :key="selectedType.value"
                    prefetch
                    class="hidden md:flex"
                    :tabs="tabs"
                    :current="selectedType.value" />

                <div class="flex w-full md:hidden">
                    <InputBasicSelect
                        v-model="selectedType.value"
                        class="w-full"
                        :clearable="false"
                        :options="tabs" />
                </div>
            </div>
            <div class="w-40">
                <InputToggle
                    v-model="includeReplies"
                    label="Include replies" />
            </div>
        </div>

        <Deferred data="comments">
            <template #fallback>
                <div
                    class="mt-8 flex w-full items-center justify-center rounded-lg px-4 py-16 text-center text-sm text-slate-900 bg-primary/5 dark:bg-primary/10 dark:text-white">
                    <Spinner class="mr-4 w-4 text-transparent" />
                    Loading comments...
                </div>
            </template>

            <div class="mt-8 flex w-full flex-col gap-6 pb-8">
                <div
                    v-if="comments.meta.total < 1"
                    class="mt-16 flex h-full w-full flex-col items-center justify-center rounded-2xl bg-transparent p-8 text-center lg:p-0">
                    <SectionTitle>
                        You haven't made any comments yet.
                    </SectionTitle>
                    <SectionDescription>
                        Discover articles and engage in discussions.
                    </SectionDescription>
                    <div class="mt-6">
                        <Button
                            variant="secondary"
                            class="flex"
                            :href="useRoute('articles.index')">
                            <div class="flex">
                                <FireIcon class="mr-2 w-4" />
                                Discover more
                            </div>
                        </Button>
                    </div>
                </div>

                <Card class="pb-0 md:pb-0">
                    <div class="-mx-4 flex flex-col md:-mx-5">
                        <div
                            v-for="(comment, i) in comments.data"
                            :key="comment.id"
                            :class="{
                                'border-b border-zinc-100 pb-4': i < comments.data.length - 1
                            }"
                            class="mb-4 px-4 md:px-5">
                            <div class="flex w-full flex-col justify-between gap-4 md:flex-row md:gap-0">
                                <div class="flex flex-col">
                                    <CommentSingle
                                        :enable-replies="false"
                                        :comment="comment" />
                                </div>
                                <div class="flex w-full md:w-auto">
                                    <Button
                                        class="w-full"
                                        :href="comment.link ? comment.link : '#'"
                                        icon-first
                                        :icon="getIcon(comment)"
                                        variant="white">
                                        {{ getCommentableTitle(comment) }}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>

                <Pagination
                    v-if="useHasPagination(comments)"
                    :data="comments" />
            </div>
        </Deferred>
    </div>
</template>
