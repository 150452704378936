<script setup>
import Logo from '@/Components/Logo'
import Button from '@/Components/UI/Button.vue'
import BaseLayout from '@/Layouts/BaseLayout.vue'
import Novus from '@/Components/Global/Novus.vue'
import { ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useCurrentUrl } from '../Composables/useCurrentUrl.js'

const nav = ref([
    {
        url: useRoute('login'),
        title: 'Login'
    },
    {
        url: useRoute('register'),
        title: 'Sign up'
    }
])
</script>

<template>
    <BaseLayout
        :navbar="false"
        :footer="false">
        <div class="flex grow flex-col justify-stretch">
            <div class="flex h-full grow justify-start md:flex-row md:justify-center md:p-4">
                <div
                    class="relative mt-8 hidden w-full overflow-hidden rounded-xl bg-gradient-to-r bg-cover bg-center p-8 bg-primary from-primary to-pink dark:from-slate-700 dark:to-slate-900 md:mt-0 md:flex md:w-6/12">
                    <div class="w-full">
                        <nav class="flex w-full items-center justify-between">
                            <Link :href="useRoute('home')">
                                <span class="sr-only">Link to home</span>
                                <Logo.FullWhite
                                    class="flex w-44 sm:w-40 lg:w-56"
                                />
                            </Link>
                        </nav>
                    </div>
                    <Logo.MarkWhite
                        class="absolute right-0 -bottom-56 translate-x-1/3 opacity-50 h-128 dark:opacity-5 md:h-[40rem]" />

                    <div class="absolute -bottom-20 -left-20 rotate-45">
                        <Novus
                            data-aos="fade-up"
                            emotion="wink"
                            class="w-96" />
                    </div>
                </div>
                <div class="flex w-full flex-col items-center md:w-6/12">
                    <nav class="mb-8 flex h-14 w-full items-center justify-between bg-white px-5 shadow-sm shadow-black/10 md:mb-0 md:h-auto md:bg-transparent md:px-0 md:shadow-none lg:mt-2">
                        <Link
                            :href="useRoute('home')"
                            class="flex items-center pt-1">
                            <span class="sr-only">Link to home</span>
                            <Logo.MarkColour
                                class="flex w-10 md:hidden"
                            />
                        </Link>
                        <div class="flex items-center gap-3">
                            <Button
                                v-for="(item, i) in nav"
                                :key="i + useCurrentUrl()"
                                prefetch
                                :variant="useCurrentUrl() === item.url ? 'primary' : 'white'"
                                :href="item.url">
                                {{ item.title }}
                            </Button>
                        </div>
                    </nav>
                    <div class="w-full md:flex-grow md:items-center md:justify-center">
                        <div class="relative mx-auto flex h-full w-full max-w-lg items-center px-4 py-4">
                            <slot />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>
