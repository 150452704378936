<script setup lang="ts">
import Header from '@/Components/UI/Header.vue'
import CategoryIconMap from '@/Configs/CategoryIconMap.ts'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import ArticleFilter from '@/Components/Article/ArticleFilter.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import type { Tag } from '@/Types/Models/Tag'
import type { User } from '@/Types/Models/User'
import type { Game } from '@/Types/Models/Game'
import type { PageHeader } from '@/Types/PageHeader'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Category } from '@/Types/Models/Category'
import { Link, Deferred, router } from '@inertiajs/vue3'
import { UserGroupIcon } from '@heroicons/vue/24/outline'
import { computed, type PropType, ref, watch } from 'vue'
import { useSingular } from '@/Composables/useSingular.js'

const props = defineProps({
    articles: [Array, Object] as PropType<any>,
    userFilter: Object,
    tagFilter: Object,
    gameFilter: Object,
    header: Object as PropType<PageHeader>,
    orderable: Boolean,
    orderData: Object,
    orderRoute: String,
    users: Array as PropType<User[]>,
    tags: Array as PropType<Tag[]>,
    games: Array as PropType<Game[]>
})

const displaySortingOptions = computed(() => {
    return props.orderable && props.orderData && props.orderRoute &&
        (props.articles?.length > 0 || props.articles?.data.length > 0)
})

type filterType = 'user' | 'tag' | 'game'

const filterForm = ref({
    user: props.userFilter || [],
    tag: props.tagFilter || [],
    game: props.gameFilter || []
})

watch(filterForm, (value) => {
    router.get(useRoute('articles.following', {
        users: value.user,
        tags: value.tag,
        games: value.game
    }))
}, { deep: true })

function getFilterInfo (type: filterType, filter: User | Tag | Game) {
    if (type === 'user') {
        return props.users.find(user => user.username === filter)
    }

    if (type === 'tag') {
        return props.tags.find(tag => tag.slug === filter)
    }

    if (type === 'game') {
        return props.games.find(game => game.id === parseInt(filter))
    }
}

function remove (type: filterType, filter: User | Tag | Game) {
    filterForm.value[type] = filterForm.value[type].filter(item => item !== filter)
}
</script>

<template>
    <div class="pb-12">
        <Header :header="header" />

        <div class="flex w-full">
            <div class="flex w-1/4 flex-col">
                <div>
                    <ul>
                        <li
                            v-for="user in users"
                            :key="user.id">
                            <label>
                                <input
                                    v-model="filterForm.user"
                                    name="user"
                                    type="checkbox"
                                    :value="user.username">
                                {{ user.name }}
                            </label>
                        </li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li
                            v-for="tag in tags"
                            :key="tag.id">
                            <label>
                                <input
                                    v-model="filterForm.tag"
                                    name="tag"
                                    type="checkbox"
                                    :value="tag.slug">
                                {{ tag.name }}
                            </label>
                        </li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li
                            v-for="game in games"
                            :key="game.id"
                            @click="filter('game', game)">
                            <label>
                                <input
                                    v-model="filterForm.game"
                                    name="tag"
                                    type="checkbox"
                                    :value="game.id">
                                {{ game.name }}
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="w-3/4">
                <ul class="flex items-center">
                    <li
                        v-for="user in filterForm.user"
                        :key="user.username">
                        {{ getFilterInfo('user', user).name }}
                        <button
                            type="button"
                            @click="remove('user', user)">
                            x
                        </button>
                    </li>
                </ul>

                <ul class="flex items-center">
                    <li
                        v-for="tag in filterForm.tag"
                        :key="tag.slug">
                        {{ getFilterInfo('tag', tag).name }}
                        <button
                            type="button"
                            @click="remove('tag', tag)">
                            x
                        </button>
                    </li>
                </ul>

                <ul class="flex items-center">
                    <li
                        v-for="game in filterForm.game"
                        :key="game.id">
                        {{ getFilterInfo('game', game).name }}
                        <button
                            type="button"
                            @click="remove('game', game)">
                            x
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <!--        <div-->
        <!--            v-if="displaySortingOptions"-->
        <!--            class="container mx-auto">-->
        <!--            <div class="flex items-center justify-between">-->
        <!--                <SectionTitle-->
        <!--                    v-if="displaySortingOptions"-->
        <!--                    class="mr-8 shrink-0">-->
        <!--                    Articles-->
        <!--                </SectionTitle>-->

        <!--                <ArticleFilter-->
        <!--                    v-if="displaySortingOptions"-->
        <!--                    :data="orderData"-->
        <!--                    :route-name="orderRoute" />-->
        <!--            </div>-->
        <!--        </div>-->

        <!--        <Deferred data="articles">-->
        <!--            <template #fallback>-->
        <!--                <ArticleSkeletonGrid-->
        <!--                    contain-->
        <!--                    :number="8" />-->
        <!--            </template>-->

        <!--            <ArticleGrid-->
        <!--                contain-->
        <!--                :skeleton-count="8"-->
        <!--                :articles="articles">-->
        <!--                <template #empty>-->
        <!--                    <EmptyState-->
        <!--                        :icon="UserGroupIcon"-->
        <!--                        :description="`There are no published articles here. <br>Try following more writers!`" />-->
        <!--                </template>-->
        <!--            </ArticleGrid>-->
        <!--        </Deferred>-->
    </div>
</template>
