<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import Header from '@/Components/UI/Header.vue'
import TabLinks from '@/Components/UI/TabLinks.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import ArticleFilter from '@/Components/Article/ArticleFilter.vue'
import { onMounted, type PropType } from 'vue'
import { router, usePoll } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import type { PageHeader } from '@/Types/PageHeader'
import type { Article } from '@/Types/Models/Article'

const props = defineProps({
    articles: [Array as PropType<Article[]>, null],
    header: Object as PropType<PageHeader>,
    status: [String, null],
    order: [String, null],
    totalCount: Number,
    unreadCount: Number,
    direction: [String, null]
})

const tabs = [
    {
        label: 'All',
        value: null,
        link: useRoute('articles.bookmarked'),
        count: props.totalCount
    },
    {
        label: 'Unread',
        value: 'unread',
        link: useRoute('articles.bookmarked', { status: 'unread' }),
        count: props.unreadCount
    }
]

const sortOptions = [
    {
        label: 'Title',
        value: 'title'
    },
    {
        label: 'Age',
        value: 'age'
    },
    {
        label: 'Bookmarked at',
        value: 'bookmarked'
    },
    {
        label: 'Likes',
        value: 'likes'
    },
    {
        label: 'Views',
        value: 'views'
    }
]

usePoll(5000)
</script>

<template>
    <div>
        <Header
            :has-related-tags="false"
            :header="header" />

        <div class="container mt-4">
            <div class="flex flex-col justify-between gap-4 md:flex-row">
                <TabLinks
                    :current="status"
                    :tabs="tabs" />
                <ArticleFilter
                    v-if="articles && articles.length"
                    :data="{status: status}"
                    :options="sortOptions"
                    route-name="articles.bookmarked" />
            </div>
        </div>

        <ArticleGrid
            v-if="articles && articles.length"
            contain
            :articles="articles"
            class="mt-2 mb-24" />
        <div
            v-else
            class="my-44">
            <div class="container flex flex-col text-center">
                There's nothing here.
                <div class="mx-auto mt-8">
                    <Button
                        :href="useRoute('articles.index')"
                        class="flex">
                        Discover more
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>
