<script setup>
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import InputText from '@/Components/Input/InputText.vue'
import Breadcrumbs from '@/Components/UI/Breadcrumbs.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import SocialLinks from '@/Components/Global/SocialLinks.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import InputBasicSelect from '@/Components/Input/InputBasicSelect.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import SocialLinksMinimal from '@/Components/Global/SocialLinksMinimal.vue'
import { useForm } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'
import { ChatBubbleOvalLeftIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    email: [String, null],
    name: [String, null],
    username: [String, null],
    subjects: Array
})

const form = useForm({
    email: props.email,
    name: props.name,
    username: props.username,
    attachments: {},
    subject: null,
    message: ''
})

function submit () {
    form.submit('post', useRoute('api.contact-us.submit'), {
        preserveScroll: true,
        onSuccess: () => {
            useToast('Message sent')
            form.reset()
        }
    })
}
</script>

<template>
    <div
        itemscope
        itemtype="http://schema.org/Organization">
        <div class="container p-5 md:max-w-[unset] md:p-4">
            <div
                class="relative mb-2 rounded-xl py-8 text-white shadow-sm bg-primary md:py-18">
                <div class="container relative mx-auto">
                    <div class="flex flex-col items-start gap-2 xs:flex-row xs:items-center xs:justify-between xs:gap-0">
                        <div class="w-full">
                            <Breadcrumbs
                                class="mb-4"
                                :breadcrumbs="[
                                    { title: 'Home', url: useRoute('home') },
                                    { title: 'Contact us' }
                                ]" />
                            <SectionTitle
                                font-size="text-2xl xs:text-2xl md:text-4xl"
                                class="mx-auto flex items-center tracking-tight">
                                <div
                                    class="mr-4 flex aspect-square w-9 items-center justify-center rounded-lg bg-white p-1 shadow-sm text-primary">
                                    <ChatBubbleOvalLeftIcon
                                        class="w-6" />
                                </div>
                                <div class="break-words hyphens-auto">
                                    Contact us
                                </div>
                            </SectionTitle>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mx-auto max-w-7xl px-4">
            <div class="container mt-2 mb-20 flex w-full flex-col gap-16 md:mt-12 md:max-w-none md:flex-row">
                <div class="w-full md:w-1/2">
                    <div>
                        <form
                            class="mx-auto flex w-full max-w-2xl flex-col space-y-4"
                            @submit.prevent="submit">
                            <div class="flex space-x-4">
                                <div class="w-1/2">
                                    <InputText
                                        v-model="form.name"
                                        input-id="firstname"
                                        :error="form.errors.name"
                                        label="Name" />
                                </div>
                                <div class="w-1/2">
                                    <InputText
                                        v-model="form.username"
                                        input-id="username"
                                        :error="form.errors.username"
                                        label="Username" />
                                </div>
                            </div>
                            <div class="w-full">
                                <InputText
                                    v-model="form.email"
                                    :error="form.errors.email"
                                    label="Email"
                                    type="email" />
                            </div>
                            <div>
                                <InputBasicSelect
                                    v-model="form.subject"
                                    label="Subject"
                                    :options="subjects"
                                    :error="form.errors.subject"
                                    placeholder="Select one..."
                                />
                            </div>

                            <InputTextarea
                                v-model="form.message"
                                :autoheight="true"
                                :error="form.errors.message"
                                input-class="h-40"
                                label="Message" />
                            <div class="flex justify-end">
                                <Button
                                    type="submit"
                                    :loading="form.processing"
                                    class="px-4"
                                    variant="primary">
                                    Send message
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="w-full md:w-1/2">
                    <SectionTitle>
                        Chat with us
                    </SectionTitle>
                    <SectionDescription>
                        You can also get in touch or chat with us directly through social media.
                    </SectionDescription>

                    <SocialLinksMinimal class="mt-4" />
                </div>
            </div>
        </div>
    </div>
</template>
