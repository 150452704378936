<template>
    <div>
        <Listbox
            v-model="selectedProxy"
            as="div">
            <ListboxLabel>
                <InputLabel
                    v-if="label">
                    {{ label }}
                </InputLabel>
            </ListboxLabel>
            <div class="relative">
                <ListboxButton
                    class="relative h-10 w-full cursor-pointer rounded-lg border border-zinc-200 bg-white pr-10 pl-3 text-left text-sm font-medium leading-6 text-slate-900 shadow-sm ring-0 focus:outline-0 focus:ring-0 focus-visible:border-primary">
                    <span class="block truncate">{{ selectedProxy ? selectedProxy.label : (placeholder ? placeholder : '') }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            class="h-5 w-5 text-gray-400"
                            aria-hidden="true" />
                    </span>
                </ListboxButton>

                <ListboxOptions class="absolute z-10 mt-1 max-h-64 w-full overflow-scroll rounded-lg bg-white py-1 text-sm shadow-sm ring-1 ring-offset-0 ring-secondary/10 focus:outline-none">
                    <ListboxOption
                        v-for="option in optionsProxy"
                        :key="option.value"
                        v-slot="{ active, selected }"
                        :disabled="option.disabled"
                        as="template"
                        :value="option">
                        <li
                            :class="[
                                {
                                    'pr-3 pl-9': displayCheck,
                                    'px-1': !displayCheck,
                                    'opacity-50': option.disabled
                                },
                                'relative cursor-default select-none'
                            ]">
                            <div
                                class="rounded px-2 py-2"
                                :class="{
                                    'bg-zinc-100': active && !selected,
                                    'bg-primary text-white': selected,
                                    'text-slate-900': !active,
                                }">
                                <span
                                    :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">
                                    {{ option.label }}
                                </span>

                                <span
                                    v-if="selected && displayCheck"
                                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                                    <CheckIcon
                                        class="h-5 w-5"
                                        aria-hidden="true" />
                                </span>
                            </div>
                        </li>
                    </ListboxOption>
                </ListboxOptions>
            </div>
        </Listbox>
        <ErrorText
            v-if="error"
            class="mt-1"
            :error="error" />
    </div>
</template>

<script setup lang="ts">
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputLabel from '@/Components/Input/InputLabel.vue'
import { onMounted, type PropType, ref, watch } from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'

type Option = {
    label: string
    value: string | number | null
    disabled?: boolean
}

const props = defineProps({
    options: Array as PropType<Option[]>,
    label: String,
    displayCheck: {
        type: Boolean,
        default: false
    },
    error: {
        type: String,
        default: null
    },
    anchor: {
        type: String,
        default: 'left'
    },
    placeholder: {
        type: String,
        default: null
    },
    modelValue: [String, Number, null]
})

const emit = defineEmits(['update:modelValue'])

const optionsProxy = ref(props.options)
const selectedProxy = ref(optionsProxy.value.find(option => option.value === props.modelValue))

onMounted(() => {
    if (props.placeholder) {
        optionsProxy.value = [{ label: props.placeholder, value: null, disabled: true }, ...optionsProxy.value]
    }
})

watch(selectedProxy, (newValue) => {
    if (newValue.value === props.modelValue) {
        return
    }

    emit('update:modelValue', newValue.value)
})
</script>
