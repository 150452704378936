<script setup lang="ts">
import Button from '@/Components/UI/Button.vue'
import PostList from '@/Components/Post/PostList.vue'
import PostForm from '@/Components/Post/PostForm.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import PostListSkeleton from '@/Components/Post/PostListSkeleton.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { type PropType, ref } from 'vue'
import type { Post } from '@/Types/Models/Post'
import type { User } from '@/Types/Models/User'
import { useRoute } from '@/Composables/useRoute.ts'
import { Deferred, Link, router } from '@inertiajs/vue3'
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

defineProps({
    posts: Object as PropType<Post[]>,
    user: Object as PropType<User>,
    title: {
        type: String,
        default: 'Latest posts'
    },
    description: {
        type: [String, null],
        default: null
    }
})

const postsKey = ref(0)

function reload () {
    router.reload({
        only: ['posts'],
        onSuccess: () => {
            postsKey.value++
        }
    })
}

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div>
        <div class="flex items-center">
            <div class="flex w-full flex-col mb-2.5">
                <SectionTitle
                    v-if="title"
                    font-size="text-xl">
                    <div class="flex items-center">
                        {{ title }}
                    </div>
                </SectionTitle>
                <div
                    v-if="description"
                    class="mt-0 w-full max-w-2xl">
                    <SectionDescription>
                        {{ description }}
                    </SectionDescription>
                </div>
            </div>
            <div
                class="flex shrink-0 items-center justify-end mb-2.5">
                <Button
                    :href="useRoute('user.show.posts', user)"
                    :icon="ArrowRightIcon"
                    variant="text">
                    See more <span class="sr-only">posts</span>
                </Button>
            </div>
        </div>
        <PostForm
            @reload="reload"
        />

        <Deferred data="posts">
            <template #fallback>
                <PostListSkeleton
                    class="mt-4"
                    :number="3" />
            </template>

            <div class="mt-4">
                <PostList
                    v-if="posts"
                    :key="postsKey"
                    :user="user"
                    :include-form="false"
                    :paginated="false"
                    :posts="posts"
                    @reload="reload" />
            </div>
        </Deferred>
    </div>
</template>
