<script setup lang="ts">
import type { PropType } from 'vue'
import { Link } from '@inertiajs/vue3'
import type { SidebarLink } from '@/Types/SidebarLink'

defineProps({
    link: Object as PropType<SidebarLink>
})

const emit = defineEmits(['click'])

function handleClick (link: SidebarLink, event) {
    if (event.metaKey || event.ctrlKey) return
    emit('click', link)
}
</script>

<template>
    <Link
        :class="link.active ? 'text-white' : 'text-white/75 hover:text-white'"
        class="relative flex items-center gap-2 px-6 py-4 text-sm group lg:my-[2px] lg:py-2.5"
        :href="link.url"
        @click="handleClick(link, $event)">
        <div
            :class="link.active ? 'opacity-100' : 'opacity-0'"
            class="w-[calc(100%-1rem)] group-hover:opacity-100 h-full rounded-lg bg-white/5 absolute top-0 left-2 right-2" />
        <div
            :class="link.active ? 'h-6 opacity-100' : 'h-0 opacity-0'"
            class="absolute top-1/2 left-0 w-1 -translate-y-1/2 rounded-r-full bg-white transition-all" />
        <div
            v-if="link.icon"
            class="relative transition-all lg:min-w-6 lg:w-auto">
            <img
                v-if="typeof link.icon === 'string'"
                :alt="`Icon for ${link.title}`"
                :src="link.icon"
                width="30"
                height="30"
                class="w-8 shrink-0 select-none rounded-full bg-pink-light -ml-0.5">
            <component
                :is="link.icon"
                v-else
                :class="link.active ? 'text-white' : 'text-zinc-500 group-hover:text-white'"
                class="aspect-square w-5 text-inherit" />
        </div>
        <span
            v-if="link.badge"
            class="absolute top-1/2 right-4 -translate-y-1/2 items-center justify-center rounded bg-white px-1 text-xs font-semibold text-black">
            {{ link.badge }}
        </span>
        <div class="flex flex-col w-full max-w-[calc(100%-2rem)]">
            <div class="w-full truncate font-medium">
                {{ link.title }}
            </div>
            <div
                v-if="link.subtitle"
                :class="link.active ? 'opacity-100' : 'opacity-50'"
                class="text-xs transition-all -mt-0.5 group-hover:opacity-100">
                {{ link.subtitle }}
            </div>
        </div>
    </Link>
</template>
