<script setup>
import draggable from 'vuedraggable'
import InputText from '@/Components/Input/InputText.vue'
import InputToggle from '@/Components/Input/InputToggle.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import AccordionTransition from '@/Components/Transition/AccordionTransition.vue'
import { ref, watch } from 'vue'
import { Bars2Icon } from '@heroicons/vue/24/outline'
import { ChevronUpIcon } from '@heroicons/vue/24/solid'

const props = defineProps({
    availableProfileSections: Array,
    modelValue: Array,
    limit: Number
})

const profileSections = ref(props.modelValue)

props.availableProfileSections.forEach(availableProfileSection => {
    if (!profileSections.value.find(profileSection => profileSection.name === availableProfileSection.name)) {
        profileSections.value.push({
            name: availableProfileSection.name,
            enabled: false
        })
    }
})

const openedSections = ref([])

function isOpen (key) {
    return openedSections.value.includes(key)
}

function getOriginalDescription (key) {
    return props.availableProfileSections.find(availableProfileSection => availableProfileSection.key === key)?.description
}

function getOriginalTitle (key) {
    return props.availableProfileSections.find(availableProfileSection => availableProfileSection.key === key)?.title
}

const emit = defineEmits(['update:modelValue'])

watch(profileSections, (newValue) => {
    emit('update:modelValue', newValue)
})
</script>

<template>
    <div>
        <draggable
            v-model="profileSections"
            group="tags"
            class="divide-y divide-slate-900/10"
            ghost-class="opacity-50"
            handle=".handle"
            item-key="id">
            <template #item="{element}">
                <li
                    class="flex flex-col py-2.5">
                    <div class="flex items-center justify-between">
                        <div
                            class="flex w-full gap-2"
                            :class="getOriginalDescription(element.key) ? 'items-start' : 'items-center'">
                            <div
                                :class="getOriginalDescription(element.key) ? 'pb-2 pt-[2px]' : 'py-[2px]' "
                                class="h-full pr-2 handle">
                                <Bars2Icon class="w-4 cursor-grab" />
                            </div>
                            <div class="flex w-full flex-col gap-0">
                                <div class="text-sm">
                                    {{ getOriginalTitle(element.key) }}
                                </div>
                                <p
                                    v-if="getOriginalDescription(element.key)"
                                    class="text-xs text-slate-900/50 dark:text-white/50">
                                    {{ getOriginalDescription(element.key) }}
                                </p>
                            </div>
                        </div>
                        <div class="flex items-center gap-4">
                            <button
                                type="button"
                                :class="profileSections.find(profileSection => element.key === profileSection.key).enabled ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'"
                                class="flex items-center gap-1 rounded-full bg-slate-900/5 px-3 py-1 text-xs text-slate-950/75"
                                @click="isOpen(element.key) ? openedSections = openedSections.filter(section => section !== element.key) : openedSections.push(element.key)">
                                <ChevronUpIcon
                                    class="w-3 transition-all"
                                    :class="isOpen(element.key) ? 'rotate-0' : 'rotate-180'" />
                                <span class="text-sm">details</span>
                            </button>
                            <div v-tooltip="`Toggle Visibility`">
                                <InputToggle
                                    v-model="profileSections.find(profileSection => element.key === profileSection.key).enabled" />
                            </div>
                        </div>
                    </div>
                    <AccordionTransition>
                        <div
                            v-show="openedSections.includes(element.key)"
                            class="flex w-full shrink-0 flex-col gap-4 pr-16 pl-8">
                            <div class="mt-4">
                                <InputText
                                    v-model="profileSections.find(profileSection => element.key === profileSection.key).title"
                                    label="Section title" />
                            </div>
                            <div class="mb-4">
                                <InputTextarea
                                    v-model="profileSections.find(profileSection => element.key === profileSection.key).description"
                                    label="Section description" />
                            </div>
                        </div>
                    </AccordionTransition>
                </li>
            </template>
        </draggable>
    </div>
</template>
