<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import TagList from '@/Components/Tag/TagList.vue'
import UserCard from '@/Components/User/UserCard.vue'
import Breadcrumbs from '@/Components/UI/Breadcrumbs.vue'
import ModelMapReverse from '@/Configs/ModelMapReverse.ts'
import AdContainer from '@/Components/Global/AdContainer.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import CommentList from '@/Components/Comment/CommentList.vue'
import ArticleInfo from '@/Components/Article/ArticleInfo.vue'
import ArticleActions from '@/Components/Article/ArticleActions.vue'
import ArticleRatings from '@/Components/Article/ArticleRatings.vue'
import GameSkeletonGrid from '@/Components/Game/GameSkeletonGrid.vue'
import ArticleThumbnail from '@/Components/Article/ArticleThumbnail.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import ArticleSpoilerWarning from '@/Components/Article/ArticleSpoilerWarning.vue'
import type { Tag } from '@/Types/Models/Tag'
import type { Game } from '@/Types/Models/Game'
import { onMounted, type PropType, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { vElementVisibility } from '@vueuse/components'
import { Deferred, router, Link } from '@inertiajs/vue3'
import { ArticleAccess } from '@/Enums/ArticleAccess.ts'
import { ArchiveBoxIcon } from '@heroicons/vue/24/outline'
import type { ArticleRating } from '@/Types/ArticleRating'
import { useBroadcast } from '@/Composables/useBroadcast.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest'
import { usePermissions } from '@/Composables/usePermissions'
import { UNPUBLISH_ANY_ARTICLE } from '@/Enums/UserPermission'
import { useArticleAccessInfo } from '@/Composables/useArticleAccessInfo.ts'
import { useParseRatingsContent } from '@/Composables/useParseContentRatings.ts'
import { ArrowPathIcon, ArchiveBoxIcon as ArchiveBoxIconSolid } from '@heroicons/vue/24/solid'

const props = defineProps({
    article: Object as PropType<Article>,
    breadcrumbs: Array as PropType<{ name: string, url: string }[]>,
    ratings: Array as PropType<ArticleRating<any>[]>,
    initialComments: Array as PropType<Comment[]>,
    accessType: String as PropType<ArticleAccess>,
    isArchive: Boolean,
    hasSpoiler: Boolean,
    hasRelatedArticles: Boolean,
    nextArticle: Object as PropType<Article>,
    hasTags: Boolean,
    tags: Array as PropType<Tag[]>,
    game: Object as PropType<Game>,
    commentsEnabled: {
        type: Boolean,
        default: true
    },
    actionsEnabled: {
        type: Boolean,
        default: true
    },
    relatedEnabled: {
        type: Boolean,
        default: true
    },
    related: Array as PropType<Article[]>,
    initialCommentsCount: Number
})

const commentsLoaded = ref(false)
const comments = ref(props.initialComments)
const commentsCount = ref(props.initialCommentsCount)

function commentsInView (state: boolean) {
    if (state === true && !commentsLoaded.value && props.commentsEnabled) {
        loadComments()
    }
}

function loadComments () {
    if (!props.commentsEnabled) {
        return
    }

    useMakeRequest(useRoute('api.article.comments', props.article.uuid), 'POST').then(response => {
        commentsLoaded.value = true
        comments.value = response.comments
        commentsCount.value = response.commentsCount

        setTimeout(() => {
            const hash = window.location.hash
            const commentId = hash.substring(1)
            const element = document.getElementById(commentId)

            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
                element.classList.add('highlight')
            }
        }, 1000)
    })
}

const hasMoreComments = ref(false)

function refreshComments () {
    loadComments()
    hasMoreComments.value = false
}

const archiveMessage = ref('This article has been imported from the old site and is no longer maintained. It may contain outdated information.')

const loadingKey = ref(props.article.uuid + '-' + new Date().getTime())

onMounted(() => {
    loadingKey.value = props.article.uuid + '-' + new Date().getTime()

    setTimeout(() => {
        router.reload({
            only: ['related', 'tags']
        })
    }, 500)

    const hash = window.location.hash
    const commentId = hash.substring(1)
    if (commentId && props.commentsEnabled) {
        loadComments()
    }

    useBroadcast(`article.${props.article.uuid}`, 'article.comment.created', (event) => {
        hasMoreComments.value = true
    })
})
</script>

<template>
    <div class="relative">
        <ArticleSpoilerWarning
            v-if="hasSpoiler"
            :article="article"
            :game="game" />
        <div class="mx-auto mb-12 max-w-7xl px-5 pt-8 md:pt-16">
            <div class="w-full md:w-8/12 md:pr-6">
                <div class="flex items-center gap-4">
                    <Breadcrumbs :breadcrumbs />
                    <div
                        v-if="isArchive"
                        v-tooltip="archiveMessage"
                        class="flex select-none items-center rounded-full bg-black py-1 text-xs text-white transition-colors px-2.5 space-x-1">
                        <ArchiveBoxIcon class="w-4" />
                        <div>Archive</div>
                    </div>
                </div>
                <h1
                    itemprop="headline"
                    class="mt-2 text-2xl font-heading text-pretty text-secondary dark:text-white sm:text-4xl lg:leading-[1.2]">
                    <component
                        :is="useArticleAccessInfo(accessType).icon"
                        v-if="useArticleAccessInfo(accessType).type !== ArticleAccess.ALL"
                        v-tooltip="useArticleAccessInfo(accessType).tooltip"
                        class="-mt-1 inline-block w-6 text-primary md:w-8" />
                    {{ article.title }}
                </h1>
                <div class="flex gap-2">
                    <Button
                        v-if="usePermissions(UserPermission.EDIT_ANY_ARTICLE)"
                        target="_blank"
                        :href="`/admin/articles/${article.uuid}/edit`">
                        Edit
                    </Button>
                    <Link
                        v-if="usePermissions(UserPermission.UNPUBLISH_ANY_ARTICLE)"
                        method="patch"
                        class="button button-danger rounded-lg after:rounded-[0.45rem] px-3"
                        :href="useRoute('articles.unpublish', { article })">
                        Unpublish
                    </Link>
                </div>
                <div class="flex flex-col justify-between gap-2 md:mt-4 md:flex-row md:gap-0">
                    <ArticleInfo
                        avatar
                        :article="article"
                        class="mt-2 flex items-center gap-1 text-xs" />
                    <ArticleActions
                        v-if="actionsEnabled"
                        :comments-enabled="commentsEnabled"
                        :initial-comments-count="commentsCount"
                        :article="article" />
                </div>
            </div>
            <div class="flex flex-col items-stretch gap-8 md:flex-row">
                <div class="w-full md:w-8/12">
                    <article
                        itemscope
                        itemtype="http://schema.org/Article">
                        <ArticleThumbnail
                            :actions="false"
                            :article="article"
                            :show-category="false"
                            :use-src-set="true"
                            class="mt-4"
                            size="full" />

                        <AdContainer
                            ad-format="fluid"
                            ad-layout="in-article"
                            ad-slot="articleBelowThumbnail"
                            class="mt-8" />

                        <h2
                            class="mt-8 text-xl font-semibold font-heading md:text-2xl"
                            itemprop="description">
                            {{ article.subtitle }}
                        </h2>

                        <div
                            class="mt-8 max-w-none article-content dark:prose-white"
                            itemprop="articleBody">
                            <template
                                v-for="(item, index) in useParseRatingsContent(article.content)"
                                :key="index">
                                <div
                                    v-if="typeof item === 'string'"
                                    v-html="item" />
                                <component
                                    :is="item"
                                    v-else />
                            </template>
                        </div>

                        <div
                            v-if="isArchive"
                            class="my-4 flex max-w-none items-start gap-4 rounded-2xl border-2 p-8 text-lg bg-primary/10 border-primary text-primary">
                            <ArchiveBoxIconSolid class="-mt-1 w-20 opacity-50" />
                            <p v-html="archiveMessage" />
                        </div>

                        <TagList
                            v-if="hasTags"
                            :key="loadingKey"
                            class="mt-6"
                            :tags="tags"
                            :center="false" />

                        <ArticleRatings
                            :article="article"
                            class="mt-4"
                            :ratings="ratings" />

                        <div
                            class="mt-12 flex flex-col justify-between gap-2 border-y py-4 border-secondary/5 dark:border-white/5 md:mb-6 min-[500px]:flex-row">
                            <ArticleInfo
                                avatar
                                :article="article"
                                class="flex items-center gap-1 text-xs" />
                            <ArticleActions
                                v-if="actionsEnabled"
                                :comments-enabled="commentsEnabled"
                                :initial-comments-count="commentsCount"
                                :article="article" />
                        </div>
                    </article>
                </div>
                <div class="relative w-full md:w-4/12">
                    <div class="relative flex flex-col gap-4 pt-4 pb-8">
                        <UserCard

                            :user="article.user" />

                        <Deferred data="game">
                            <template #fallback>
                                <GameSkeletonGrid
                                    horizontal
                                    :number="1" />
                            </template>
                            <GameCardHorizontal
                                v-if="game"
                                :small="true"
                                :game="game" />
                        </Deferred>

                        <Card
                            v-if="relatedEnabled && hasRelatedArticles"
                            :key="loadingKey"
                            class="hidden md:block">
                            <ArticleGrid
                                :skeleton-count="4"
                                :articles="related"
                                :horizontal="true"
                                title="Related articles" />
                        </Card>

                        <AdContainer ad-slot="articleSidebar" />

                        <Card
                            v-if="nextArticle"
                            v-once
                            class="hidden md:block">
                            <ArticleGrid
                                :skeleton-count="1"
                                :articles="[nextArticle]"
                                :horizontal="true"
                                title="Up next" />
                        </Card>
                    </div>
                </div>
            </div>
            <div class="mt-4 w-full md:w-8/12 md:pr-6">
                <CommentList
                    v-if="commentsEnabled"
                    id="comments"
                    v-element-visibility="commentsInView"
                    :form-data="{
                        commentable_id: article.uuid,
                        commentable_type: ModelMapReverse['Article']
                    }"
                    :commentable="article"
                    :comments="comments"
                    :count="commentsCount"
                    @created="loadComments"
                    @deleted="loadComments">
                    <template #actions>
                        <Button
                            v-if="hasMoreComments"
                            inner-class="text-xs"
                            @click="refreshComments">
                            <ArrowPathIcon class="mr-1 w-5 xs:mr-2 xs:w-4" />
                            Refresh comments
                        </Button>
                    </template>
                </CommentList>

                <div
                    v-else
                    class="flex w-full items-center justify-center rounded-lg px-4 py-8 text-center text-sm text-slate-900 bg-slate-950/5 dark:bg-primary/10 dark:text-white">
                    Comments disabled
                </div>

                <div class="mt-8 flex flex-col gap-4 md:hidden">
                    <Card
                        v-if="relatedEnabled && hasRelatedArticles"
                        v-once
                        :key="loadingKey">
                        <ArticleGrid
                            :skeleton-count="4"
                            :articles="related"
                            :horizontal="true"
                            title="Related articles" />
                    </Card>

                    <AdContainer ad-slot="articleBelowComments" />

                    <Card
                        v-if="nextArticle"
                        v-once>
                        <ArticleGrid
                            :skeleton-count="1"
                            :articles="[nextArticle]"
                            :horizontal="true"
                            title="Up next" />
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>
