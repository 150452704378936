<script setup lang="ts">
import Gog from '@/Components/Icon/Gog.vue'
import Steam from '@/Components/Icon/Steam.vue'
import ItchIo from '@/Components/Icon/ItchIo.vue'
import AppStore from '@/Components/Icon/AppStore.vue'
import EpicGames from '@/Components/Icon/EpicGames.vue'
import GooglePlay from '@/Components/Icon/GooglePlay.vue'
import type { PropType } from 'vue'
import type { Game } from '@/Types/Models/Game'
import { useGetFaviconFromUrl } from '@/Composables/useGetFaviconFromUrl.ts'

defineProps({
    title: {
        type: String,
        default: null
    },
    game: Object as PropType<Game>
})

const iconMap = {
    Steam,
    GOG: Gog,
    'Epic Games': EpicGames,
    'iOS App Store (iPhone)': AppStore,
    'iOS App Store (iPad)': AppStore,
    'Google Play Store': GooglePlay,
    'Itch.io': ItchIo
}
</script>

<template>
    <div v-if="game.websites && game.websites.length > 0">
        <div
            v-if="title"
            class="text-sm">
            {{ title }}
        </div>
        <ul class="mt-1 ml-1 flex items-center gap-4">
            <li
                v-for="website in game.websites"
                :key="website.name">
                <a
                    v-tooltip="`${website.name}`"
                    :href="website.url"
                    target="_blank"
                    rel="nofollow"
                    class="hover:underline">
                    <component
                        :is="iconMap[website.name]"
                        v-if="typeof iconMap[website.name] !== 'undefined'"
                        class="size-6" />
                    <img
                        v-else
                        v-lazy="useGetFaviconFromUrl(website.url)"
                        :alt="`${website.name} link for ${game.name}`"
                        class="rounded-full outline outline-white size-6">
                </a>
            </li>
        </ul>
    </div>
</template>
