<template>
    <svg
        viewBox="0 0 645 644"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M138.409 2.13338C74.2759 13.6 22.676 61.0667 6.40928 123.467C0.542615 146 0.142615 159.2 0.809282 334C1.20928 494.533 1.34262 498.267 4.00928 510.667C18.8093 577.467 66.276 625.067 133.743 640.8C142.543 642.8 153.743 643.2 225.876 643.6C300.543 644.133 308.276 644 310.276 642C312.276 640 312.409 614.267 312.409 322.533C312.409 106.267 312.009 4.40007 311.076 2.5334C309.743 0.133402 307.476 4.4196e-05 229.343 0.133378C165.876 0.266711 146.809 0.666711 138.409 2.13338ZM259.076 322.133V592.267L204.809 591.6C154.809 591.067 149.476 590.8 139.476 588.267C96.5426 577.2 64.676 544.133 55.7426 501.067C52.8093 487.6 52.8093 155.867 55.6093 142.667C63.6093 105.2 89.6093 74.1334 124.676 60.1334C142.276 53.0667 150.409 52.2667 207.476 52.1334L259.076 52.0001V322.133Z"
            fill="currentColor" />
        <path
            d="M149.876 133.467C141.476 135.067 128.676 141.467 121.876 147.467C107.876 159.6 100.943 176.8 102.009 197.067C102.543 207.6 103.209 210.4 107.476 218.933C113.743 231.867 123.209 241.333 136.143 247.733C145.076 252.133 147.343 252.667 158.943 253.067C169.476 253.467 173.209 253.067 180.276 250.667C209.209 240.933 226.676 212.8 221.743 184C216.009 149.733 183.476 126.8 149.876 133.467Z"
            fill="currentColor" />
        <path
            d="M377.343 0.93332C376.809 1.33332 376.409 145.867 376.409 322.133C376.409 612.933 376.543 642.4 378.543 643.2C382.143 644.533 485.609 644 498.409 642.667C552.543 636.533 600.276 603.6 626.276 554.667C629.609 548.4 634.009 537.867 636.276 531.333C644.676 506.267 644.409 512.933 644.409 321.333C644.409 168.4 644.143 145.6 642.276 135.867C629.076 66.4 576.143 14 506.543 1.99999C497.209 0.399987 482.809 -1.32407e-05 436.409 -1.32407e-05C404.409 -1.32407e-05 377.743 0.399987 377.343 0.93332ZM519.743 291.333C540.543 296.8 557.609 312.667 564.543 332.933C568.943 345.467 568.809 363.867 564.409 375.333C556.276 396.4 540.276 411.2 519.743 416.667C486.409 425.333 450.943 405.2 441.209 372.133C438.276 362 438.409 344.933 441.743 334.533C451.743 301.867 486.409 282.667 519.743 291.333Z"
            fill="currentColor" />
    </svg>
</template>
