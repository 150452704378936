<script setup lang="ts">
import UserCard from '@/Components/User/UserCard.vue'
import Pagination from '@/Components/UI/Pagination.vue'
import UserSkeletonGrid from '@/Components/User/UserSkeletonGrid.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import { computed, type PropType } from 'vue'
import type { User } from '@/Types/Models/User'
import type { PaginatedItems } from '@/Types/PaginatedItems'
import { useHasPagination } from '@/Composables/useHasPagination.ts'

const props = defineProps({
    users: [Object as PropType<User[]>, Object as PropType<PaginatedItems<User>>],
    pagination: {
        type: Boolean,
        default: true
    },
    paginationOptions: {
        type: Object,
        default: () => ({
            preserveScroll: false,
            only: null
        })
    },
    contain: {
        type: Boolean,
        default: false
    },
    skeletonCount: {
        type: Number,
        default: 4
    },
    horizontal: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    userWidth: {
        type: String,
        default: 'w-full md:w-1/2 lg:w-1/3'
    }
})

const hasPagination = computed(() => props.users && Object.hasOwn(props.users, 'links'))
const allUsers = computed(() => {
    const data = hasPagination.value ? props.users.data : props.users
    return data ?? []
})

const containerClasses = computed(() => {
    return {
        'flex-col -mx-2.5': props.horizontal,
        'flex-wrap -mx-2.5': true
    }
})

const userClasses = computed(() => {
    return {
        'p-2.5': true,
        'mb-6': !props.horizontal,
        [props.userWidth]: !props.horizontal
    }
})
</script>

<template>
    <section>
        <UserSkeletonGrid
            v-if="!users && skeletonCount > 0"
            :horizontal="horizontal"
            :contain="contain"
            :number="skeletonCount" />
        <div v-else>
            <div
                :class="[contain ? 'container' : '']">
                <div
                    v-if="allUsers.length"
                    :class="[containerClasses]"
                    class="mt-0 flex items-stretch">
                    <div
                        v-for="user in allUsers"
                        :key="user.username"
                        :class="userClasses">
                        <UserCard
                            :small="small"
                            :user
                        />
                    </div>
                </div>
                <slot
                    v-if="allUsers.length === 0"
                    name="empty" />

                <Pagination
                    v-if="pagination && useHasPagination(users)"
                    :preserve-scroll="paginationOptions.preserveScroll"
                    :only="paginationOptions.only"
                    :data="users" />
            </div>
        </div>
    </section>
</template>
