<template>
    <svg
        viewBox="0 0 89 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M41.4 0C43.2 0 44.6 1.3 44.6 3C44.6 4.7 43.2 6 41.4 6C39.7 6 38.3 4.6 38.3 3C38.3 1.4 39.7 0 41.4 0ZM38.8 9.1V26.4H44.1V9.1H38.8ZM49.2 3C49.2 4.7 50.6 6 52.3 6C54.1 6 55.5 4.7 55.5 3C55.5 1.3 54.1 0 52.3 0C50.6 0 49.2 1.4 49.2 3ZM49.7 9.1V26.4H55V9.1H49.7ZM30.3 1.5L25.6 19.9C25.6 19.9 22 6.1 21.4 4.1C20.8 2.1 19.6 1.3 17.9 1.3C16.2 1.3 15 2.2 14.4 4.1C13.8 6.1 10.2 19.9 10.2 19.9L5.6 1.5H0C0 1.5 5.4 21.1 6.2 23.4C6.8 25.2 8.1 26.6 10.1 26.6C12.4 26.6 13.5 24.9 14 23.4C14.5 21.9 18 8.9 18 8.9C18 8.9 21.5 21.9 22 23.4C22.5 24.9 23.6 26.6 25.9 26.6C27.9 26.6 29.3 25.1 29.8 23.4C30.6 21.1 36 1.5 36 1.5H30.3Z"
            fill="currentColor" />
        <path
            d="M75 13.2C77 13.2 78.5 11.6 78.5 9.2V0H71.5V9.2C71.5 11.7 73 13.2 75 13.2ZM84.8 0H81.8V9.3C81.8 13.8 79.2 16.2 75 16.2C70.8 16.2 68.2 13.8 68.2 9.3V0H65.2C63 0 61.7 1.3 61.7 3.5V14.9C61.7 18.2 63.4 19.9 66.7 19.9H83.2C86.5 19.9 88.2 18.2 88.2 14.9V3.5C88.3 1.3 87 0 84.8 0Z"
            fill="currentColor" />
    </svg>
</template>
