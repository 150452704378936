<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/20/solid'

defineProps({
    removable: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['remove'])

function remove () {
    emit('remove')
}
</script>

<template>
    <div
        :class="removable ? 'pr-3' : 'pr-5'"
        class="flex select-none items-center gap-2 rounded-lg border border-zinc-200 bg-white py-2 pl-5 text-sm font-semibold text-slate-900/80 transition-all dark:bg-slate-700">
        <slot />
        <button
            v-if="removable"
            class="opacity-50 hover:opacity-100"
            @click="remove">
            <XMarkIcon class="w-4" />
        </button>
    </div>
</template>
